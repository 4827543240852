import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorService {
  public globalFatalErrorHappened$ = new EventEmitter<boolean>();
  public globalFatalErrorHappened = false;
}
