import { Injectable } from '@angular/core';
import { JoyrideService, JoyrideStepService } from 'ngx-joyride';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { JoyrideStepInfo } from 'ngx-joyride/lib/models/joyride-step-info.class';
import { environment } from '@environments/environment';
import { GuideStepService } from '@ui/shared/services/guide-step.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GuideTourService {
  public tourId!: string;
  public tourDone$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly joyrideService: JoyrideService,
    private readonly joyrideStepService: JoyrideStepService,
    private readonly guideStepService: GuideStepService
  ) {}

  public startTour(tourId: string, steps: string[]): Observable<JoyrideStepInfo | null> {
    if (!environment.GUIDE || localStorage.getItem(tourId)) {
      return of(null);
    }
    this.tourId = tourId;

    this.guideStepService.closeTour$
      .subscribe(() => {
      if (this.joyrideService.isTourInProgress()) {
        this.joyrideService.closeTour();
      }
    });

    const tour = this.joyrideService.startTour({
      steps,
      themeColor: '#3438e0',
      showPrevButton: false,
      showCounter: false,
      customTexts: {
        next: 'следующий',
        done: 'готово',
      },
    });

    return tour;
  }

  public onTourDone() {
    if (this.tourId) {
      localStorage.setItem(this.tourId, 'passed');
    }
    this.tourDone$.next(true);
  }

  public nextStep() {
    this.joyrideStepService.next();
  }

  public setupFrame(sourceElSelector: string, targetElSelector: string): void {
    const sourceElRef = document.querySelector(sourceElSelector);
    const bounds = sourceElRef.getBoundingClientRect();

    const frameElRef = document.querySelector(targetElSelector);
    if (sourceElRef && frameElRef) {
      frameElRef.setAttribute(
        'style',
        `
        position: absolute;
        width: ${bounds.width}px;
        height: ${bounds.height}px;
        top: ${bounds.top}px;
        left: ${bounds.left}px;
        `
      );
    }
  }
}
