import { Injectable } from '@angular/core';
import { StorageService } from '@core/shared/services/storage.service';
import { v4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UUIDService {
  private ANONYMOUS_UUID_LOCAL_STORAGE_KEY = 'user_uuid';

  constructor(private readonly storageService: StorageService) {
  }

  public createAnonymousUUID(): void {
    const uuid = this.generateUUID();
    this.storageService.setItem(this.ANONYMOUS_UUID_LOCAL_STORAGE_KEY, uuid);
  }

  public removeAnonymousUUID(): void {
    this.storageService.removeItem(this.ANONYMOUS_UUID_LOCAL_STORAGE_KEY);
  }

  public isAnonymousUUIDExists(): boolean {
    return !!this.storageService.getItem(this.ANONYMOUS_UUID_LOCAL_STORAGE_KEY);
  }

  public generateUUID(): string {
    return v4();
  }

  public getAnonymousUUID(): string {
    return this.storageService.getItem(this.ANONYMOUS_UUID_LOCAL_STORAGE_KEY);
  }
}
