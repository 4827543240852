import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { IShipmentPosition } from '@app/pages/shipments-view/shared/interfaces/shipment-position';
import { TableResponseDto } from '@core/shared/dto/table-response.dto';
import { ShipmentsQueryDto } from '@core/shared/dto/shipments-query.dto';
import { DefaultTableQueryDto } from '@core/shared/dto/default-table-query.dto';
import { WebApiService } from '@core/web-api/shared/services/web-api.service';
import {
  IOrderAction,
  IOrderHistory,
  IOrderMovementStatus,
  IOrderTableItem,
  ShipmentActionEnum,
} from '@core/shared/interfaces/order.interface';
import { TypeResponse } from '@core/web-api/shared/enum/response.type';
import { showSuccessMessage } from '@core/shared/rxjs-operators/show-success-message';
import { PopupService } from '@ui/shared/services/popup.service';
import { IActionsMenuListItem } from '@app/ui/modules/actions-menu/actions-menu-list-item.interface';
import { IShipment } from '@core/shared/interfaces/shipment.interface';
import { ICollectionWithPagination } from '@core/shared/interfaces/collection-with-pagination.interface';
import { IAttachment } from '@core/shared/interfaces/attachment.interface';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IApiError } from '@core/web-api/shared/interfaces/api.error.interface';

@Injectable({
  providedIn: 'root',
})
export class ShipmentsService {
  private readonly root = 'Shipments';

  constructor(private api: WebApiService, private snackBar: PopupService) {}

  getShipments(dto: ShipmentsQueryDto): Observable<TableResponseDto<IOrderTableItem>> {
    return this.api.get<TableResponseDto<IOrderTableItem>>('Shipments', dto);
  }

  public getShipmentItems(
    shipmentId: number,
    dto: DefaultTableQueryDto,
  ): Observable<TableResponseDto<IShipmentPosition>> {
    return this.api.get(`${this.root}/${shipmentId}/Items`, dto);
  }

  public rejectShipmentByCustomer(shipmentId: number, comment: string) {
    return this.api.post(`${this.root}/${shipmentId}/CustomerReject`, {
      comment,
    });
  }

  public rejectShipmentBySupplier(shipmentId: number, comment: string) {
    return this.api.post(`${this.root}/${shipmentId}/SupplierReject`, {
      comment,
    });
  }

  public downloadShipmentAsCSV(shipmentId: number): Observable<Blob> {
    return this.api.get(`${this.root}/${shipmentId}/DownloadShipmentToFile`, {}, TypeResponse.blob);
  }

  public downloadShipmentAsXlsx(shipmentId: number): Observable<Blob> {
    return this.api.get(`${this.root}/${shipmentId}/DownloadShipmentToFile?filetype=excel`, {}, TypeResponse.blob);
  }

  public acceptShipment(shipmentId: number) {
    return this.api.post(`${this.root}/${shipmentId}/Accept`);
  }

  public paymentReserve(shipmentId: number) {
    return this.api.post(`${this.root}/${shipmentId}/PaymentReserve`);
  }

  public acceptClaim(shipmentId: number, comment: string) {
    return this.api.post(`${this.root}/${shipmentId}/AcceptClaim`, {
      comment
    });
  }

  public declineClaim(shipmentId: number, comment: string) {
    return this.api
      .post(`${this.root}/${shipmentId}/DeclineClaim`, {
        comment
      });
  }

  downloadShipmentAsPDF(shipmentId: number): Observable<Blob> {
    return this.api.get(`Shipments/${shipmentId}/Print`, {}, TypeResponse.blob);
  }

  downloadShipmentAsSimplePDF(shipmentId: number): Observable<Blob> {
    return this.api.get(`Shipments/${shipmentId}/SimplePrint`, {}, TypeResponse.blob);
  }

  downloadShipmentDocumentsAsZip(shipmentId: number): Observable<Blob> {
    return this.api.get(`Shipments/${shipmentId}/PrintArchive`, {}, TypeResponse.blob);
  }

  downloadShipmentTorg12(shipmentId: number): Observable<Blob> {
    return this.api.get(`Shipments/${shipmentId}/PrintTorg`, {}, TypeResponse.blob);
  }

  downloadShipmentSf(shipmentId: number): Observable<Blob> {
    return this.api.get(`Shipments/${shipmentId}/PrintSf`, {}, TypeResponse.blob);
  }

  downloadShipmentTn(shipmentId: number): Observable<Blob> {
    return this.api.get(`Shipments/${shipmentId}/PrintTn`, {}, TypeResponse.blob);
  }

  public readyToShip(shipmentId: number) {
    return this.api.post(`${this.root}/${shipmentId}/ReadyToShip`);
  }

  public ship(shipmentId: number) {
    return this.api.post(`${this.root}/${shipmentId}/Ship`);
  }

  public changeItemQuantity(shipmentId: number, itemId: number, quantity: number) {
    return this.api.put(`${this.root}/${shipmentId}/Items/${itemId}`, {
      quantity,
    });
  }

  public removeItem(shipmentId: number, itemId: number) {
    return this.api.delete(`${this.root}/${shipmentId}/Items/${itemId}`);
  }

  public getShipment(shipmentId: number): Observable<IShipment> {
    return this.api.get(`${this.root}/${shipmentId}`);
  }

  public downloadShipmentAttachment(action: IActionsMenuListItem<ShipmentActionEnum>): Observable<any> {
    return this.api.get<string>(action.action, {}, TypeResponse.blob);
  }

  public deleteShipmentAttachment(action: IOrderAction<ShipmentActionEnum>): Observable<any> {
    return this.api.delete(action.action);
  }

  public shipmentCorrectionRequest(uri: string, reason: string): Observable<any> {
    const payload = {
      comment: reason,
    };

    return this.api.post(uri, payload);
  }

  public deliver(shipmentId: number) {
    return this.api.post(`${this.root}/${shipmentId}/Deliver`);
  }

  public getAttachmentInfo(shipmentId: number, dto?: DefaultTableQueryDto): Observable<ICollectionWithPagination<IAttachment>> {
    return this.api.get(`${this.root}/${shipmentId}/GetAttachmentInfo`, dto);
  }

  public getAttachment(shipmentId: number, attachmentIds: number[]): Observable<Blob> {
    return this.api.get(`${this.root}/${shipmentId}/Attachment?attachmentsId=${attachmentIds.join(',')}`, {}, TypeResponse.blob);
  }

  // TODO: Бэк нарушает REST
  public deleteAttachment(shipmentId: number, attachmentId: number): Observable<unknown> {
    return this.api.delete(`${this.root}/${shipmentId}/Attachment`, { attachmentId });
  }

  public makeRevert(shipmentId: number, revertAction: string, comment: string) {
    return this.api.post(`${this.root}/${shipmentId}/Revert${revertAction}`, {
      comment
    });
  }

  public getShipmentHistory(shipmentId: number, dto?: DefaultTableQueryDto): Observable<ICollectionWithPagination<IOrderHistory>> {
    return this.api.get(`${this.root}/${shipmentId}/History`, dto);
  }

  public rejectPayment(shipmentId: number, comment: string) {
    return this.api.post(`${this.root}/${shipmentId}/RejectPayment`, {
      comment
    });
  }

  public getUniqueStatuses(): Observable<IOrderMovementStatus[]> {
    return this.api.get(`${this.root}/UniqMovementStatus`);
  }

  public downloadShipmentsAsXLSX(dto: ShipmentsQueryDto): Observable<Blob> {
    return this.api.get(`${this.root}/ExcelShipments`, dto, TypeResponse.blob);
  }

}
