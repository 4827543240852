<mat-form-field
  [class.with-underline]="withUnderline"
>
  <input type="text"
         [placeholder]="placeholder"
         matInput
         [matAutocomplete]="auto"
         [value]="inputValue"
         (blur)="handleBlur($event)"
         (input)="handleInput($event)"
         (click)="handleInputClick()"
  >

  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="displayWith"
                    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option *ngFor="let option of options" [value]="option">
      <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{$implicit: option, option: option}"></ng-template>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
