import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UIButtonType } from '@ui/shared/interfaces/button.type';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() name = '';
  @Input() type: UIButtonType = 'stroked';
  @Input() disabled = false;
}
