export function ApiParamsUtil(filters: any): any {
  let params: any = {};
  const payload: any = { ...filters };

  params = Object.entries(payload)
    .filter(([_, value]) => !!value)
    .reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value,
      };
    }, params);

  return params;
}
