<div class="dialog__header">
  Загрузка файла
  <button (click)="close()" class="close-button" mat-button>
    <mat-icon class="close-button__icon" svgIcon="close"></mat-icon>
  </button>
</div>

<div class="dialog__wrapper">
  <div class="dialog__content" mat-dialog-content>
    <ng-content *ngTemplateOutlet="currentTemplate"></ng-content>
  </div>

  <ng-template #selectFile>
    <app-button type="flat" name="Загрузить файл" (click)="openFileChooseDialog()"></app-button>

    <input #uploadFileHiddenInput (change)="onFileSelected($event)" hidden type="file" />
  </ng-template>

  <ng-template #uploadingProgress>
    <div class="upload-progress">
      <mat-progress-spinner [diameter]="40" [value]="uploadProgress?.progress"></mat-progress-spinner>
      <span class="label">Файл загружается...</span>
    </div>
  </ng-template>

  <ng-template #uploadSuccess>
    <span class="upload-success">Загрузка завершена успешно</span>
  </ng-template>

  <ng-template #uploadError>
    <span class="upload-error"
      >Произошла ошибка при загрузке <br />
      {{ uploadErrorText }}</span
    >
  </ng-template>
</div>

<div class="actions" mat-dialog-actions>
  <app-button type="stroked-gray" name="Закрыть" (click)="close()"></app-button>
</div>
