<div class="dialog__header">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <mat-icon (click)="onClose()" class="dialog__icon" svgIcon="x-mark"></mat-icon>
</div>
<div class="confirm-dialog">
  <p class="confirm-dialog__text">
    {{ data.message }}
  </p>
</div>
<div class="confirm-dialog__footer">
  <app-button
    type="flat"
    name="Отмена"
    class="confirm-dialog__footer--button"
    type="stroked-gray"
    (click)="onClose()"
  ></app-button>

  <app-button
    type="flat"
    name="Да"
    (click)="onSuccess()"
  ></app-button>
</div>
