import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UIButtonType } from '@ui/shared/interfaces/button.type';
import { FILE_EXTENSIONS_MAP } from '@core/shared/enums/file-extension.enum';
import { IDropdownButtonOptions } from '@ui/components/dropdown-file-uploader/shared/dropdown-button.interface';
import { EDropdownButtonActions } from '@ui/components/dropdown-file-uploader/shared/dropdown-button-actions.enum';

@Component({
  selector: 'app-dropdown-file-uploader',
  templateUrl: './dropdown-file-uploader.component.html',
  styleUrls: ['./dropdown-file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownFileUploaderComponent),
    },
  ],
})
export class DropdownFileUploaderComponent implements ControlValueAccessor {
  @Input() name = 'Кнопка';
  @Input() type: UIButtonType = 'stroked';
  @Input() disabled = false;
  @Input() options: IDropdownButtonOptions[] = [];
  @Input() mainButtonOption!: IDropdownButtonOptions;
  @ViewChild('uploadFileEl', { static: true }) uploadFileEl!: ElementRef;
  fileType!: string;

  set inputAcceptFileExtension(extension: string) {
    (this.uploadFileEl.nativeElement as HTMLInputElement).accept = FILE_EXTENSIONS_MAP.get(extension);
  }

  registerOnChange(fn: () => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }

  optionSelect(option: string, action: string) {
    if (action === EDropdownButtonActions.Upload) {
      this.inputAcceptFileExtension = option;
      this.uploadFileEl.nativeElement.click();
    } else {
      this.writeValue({
        type: this.fileType,
      });
    }
    this.fileType = option;
    this.onTouched();
  }

  writeValue(file: any) {
    this.onChange(file);
  }

  uploadFile(input: Event) {
    const file: File = (input.target as HTMLFormElement).files[0];
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.writeValue({
      type: this.fileType,
      file: formData,
    });
    this.uploadFileEl.nativeElement.value = '';
  }

  onChange: any = () => {};

  onTouched: any = () => {};
}
