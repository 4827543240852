import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { OrdersService } from '@core/shared/services/orders.service';
import { IOrder, IOrderAction, OrderActionEnum, ShipmentActionEnum } from '@core/shared/interfaces/order.interface';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PopupService } from '@ui/shared/services/popup.service';
import { showSuccessMessage } from '@core/shared/rxjs-operators/show-success-message';
import { getFileExtensionUtil } from '../../utils/file-extension.util';

@Injectable()
export class OrderActionsService {
  constructor(
    protected ordersService: OrdersService,
    protected dialog: MatDialog,
    private snackBar: PopupService,
  ) {}

  public download(item: IOrder) {
    return this.ordersService.downloadOrderCSV(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}.${getFileExtensionUtil(file.type)}`);
      }),
    );
  }

  public reject(item: IOrder, comment: string): Observable<unknown> {
    return this.ordersService.reject(item.id, comment).pipe(showSuccessMessage(this.snackBar, 'Отказ произведен'));
  }

  public downloadAsPdf(item: IOrder): Observable<void> {
    return this.ordersService.downloadOrderAsPDF(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}.${file.type}`);
      }),
    );
  }

  public downloadAsXlsx(item: IOrder) {
    return this.ordersService.downloadOrderAsXlsx(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}.${getFileExtensionUtil(file.type)}`);
      }),
    );
  }

  public confirm(item: IOrder): Observable<unknown> {
    return this.ordersService.confirmOrder(item.id);
  }

  public clone(item: IOrder): Observable<IOrder> {
    return this.ordersService.cloneOrder(item.id, {
      departmentId: item.receiver.id,
    });
  }

  public delete(item: IOrder): Observable<void> {
    return this.ordersService.deleteOrder(item.id);
  }

  public send(item: IOrder): Observable<void> {
    return this.ordersService.sendOrder(item.id);
  }

  public revert(item: IOrder, action: IOrderAction<OrderActionEnum>, comment: string) {
    const match = action.code.match(/^Revert(.+)$/);

    return this.ordersService.makeRevert(item.id, match[1], comment)
      .pipe(showSuccessMessage(this.snackBar, 'Изменения произведены'));
  }
}
