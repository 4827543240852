import { APP_INITIALIZER } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { MatDialog } from '@angular/material/dialog';

export function errorRoutingFactory(ers: ErrorHandlerService): any {
  return (): any => ers.initErrorSubscription();
}

export const ErrorRoutingServiceInitializer = {
  provide: APP_INITIALIZER,
  useFactory: errorRoutingFactory,
  deps: [ErrorHandlerService],
  multi: true,
};
