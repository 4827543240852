import { ChangeDetectionStrategy, Component, ChangeDetectorRef, HostListener, NgZone, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/@core/modules/auth/auth.service';
import { RoleAccessEnum } from '@core/shared/enums/role-access.enum';
import { catchError,  switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, of } from 'rxjs';
import { UserRoleEnum } from '@core/shared/enums/user-role.enum';
import {WindowSizeService} from '@ui/shared/services/window-size.service';
import {adaptiveSizes} from '@core/shared/constants/adaptive-sizes';
import {DEVICES} from '@core/shared/enums/devices.enum';
// import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { JoyrideService } from 'ngx-joyride';
import { GuideStepService } from '@ui/shared/services/guide-step.service';
import { PopupService } from '@ui/shared/services/popup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public loading = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private readonly cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private windowSizeService: WindowSizeService,
    private zone: NgZone,
    // private googleTagManagerService: GoogleTagManagerService,
    private joyRideService: JoyrideService,
    private guideStepService: GuideStepService,
  ) {
    // this.googleTagManagerService.addGtmToDom();

    this.router.events.subscribe((event) => {
      if ('url' in event) {
        const forceLogin = event.url.indexOf('?login') !== -1;
        const hasGoogleMetricTag = event.url.indexOf('/?_ga') === 0;

        if (event instanceof NavigationEnd) {
          // if (event.url === '/' || forceLogin || hasGoogleMetricTag) {
          //   this.authService.userHasAbility(RoleAccessEnum.VIEW_SHOWCASE).subscribe((can) => {
          //     if (can) {
          //       this.router.navigate(['/', 'showcase']);
          //     }
          //   });
          // }

          if (event instanceof NavigationEnd && event.url === '/error/server-error') {
            this.removeFader();
          }
        }
      }
    });

    this.initLoginFlow();
    this.removeAppPreloader();
    this.watchWindowSize();
    this.onResize();
  }

  @HostListener('document:click', ['$event.target'])
  public handleKeyDown(target: HTMLElement) {
    if (target.classList.contains('joyride-backdrop')) {
      this.guideStepService.closeTour$.emit();
    }
  }


  private initLoginFlow() {
    const urlSearch = window.document.location.search;
    const forceLogin = urlSearch.indexOf('?login') !== -1;

    this.authService.runInitialLoginSequence(forceLogin).then(() => {
      this.authService
        .loadCurrentUser()
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.removeFader();

            return throwError(error);
          }),
          switchMap(() => {
            if (
              this.authService.userHasRole(UserRoleEnum.CUSTOMER_HIGH_LEVEL) ||
              this.authService.userHasRole(UserRoleEnum.SUPPLIER_HIGH_LEVEL)
            ) {
              return this.authService.checkUserCADepartments();
            }
            return of(null);
          }),
        )
        .subscribe((departmentsCount) => {
          if (departmentsCount && departmentsCount.count === 0) {
            this.router.navigate(['/settings/departments'], {
              queryParams: { fillForm: true },
              skipLocationChange: true,
            });
          }
          this.removeFader();
        });
    });
  }

  private removeFader(): void {
    this.loading = false;
    this.cd.detectChanges();
  }

  private removeAppPreloader(): void {
    const preloader = document.getElementById('app-preloader');

    if (preloader) {
      preloader.classList.add('app-preloader--hidden');
    }
  }

  private onResize(): void {
    this.zone.runOutsideAngular(() => {
      window.onresize = () => {
        this.watchWindowSize();
      };
    });
  }

  private watchWindowSize() {
    const windowSize = window.innerWidth;
    let device = DEVICES.DESKTOP;
    if (windowSize <= adaptiveSizes.middle && windowSize > adaptiveSizes.tablet) {
      device = DEVICES.MD;
    } else if (windowSize <= adaptiveSizes.tablet && windowSize > adaptiveSizes.mobile) {
      device = DEVICES.TABLET;
    } else if (windowSize <= adaptiveSizes.mobile && windowSize > adaptiveSizes.xs) {
      device = DEVICES.MOBILE;
    } else if (windowSize <= adaptiveSizes.xs) {
      device = DEVICES.XS;
    }
    this.windowSizeService.updateSize(device);
  }
}
