import { ChangeDetectionStrategy, Component, Self } from '@angular/core';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { AbstractTree } from '@ui/components/abstract-tree/abstract.tree';
import { ITreeData } from '@ui/components/abstract-tree/shared/tree.interface';
import { CategoryDynamicDatabase } from './shared/category-dynamic-database';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [NgOnDestroy],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeComponent<T> extends AbstractTree<ITreeData> {
  constructor(protected database: CategoryDynamicDatabase, @Self() protected componentDestroyed$: NgOnDestroy) {
    super(database, componentDestroyed$);
  }
}
