import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, Optional, ViewChild } from '@angular/core';
import {IPopup, PopupModuleConfiguration, PopupTypeEnum} from '../popup.interface';

@Component({
  selector: 'app-popup-item-body',
  templateUrl: './popup-item-body.component.html',
  styleUrls: ['./popup-item-body.component.scss']
})
export class PopupItemBodyComponent {

  @Input() notification: IPopup;

  @HostBinding('class') get class(): string {
    return this.notification.type;
  }

  constructor(@Optional() private options: PopupModuleConfiguration) { }
}
