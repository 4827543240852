import { EPages } from '@core/shared/enums/pages.enum';
import { IHeader } from '@ui/components/header/shared/interfaces/header.interface';
import { HEADER_ITEMS_CATALOG } from '@app/pages/catalog/shared/header.data';
import { HEADER_ITEMS_SYSTEM_MANAGEMENT } from '@app/pages/system-management/shared/header.data';
import { HEADER_ITEMS_SHOP } from '@app/pages/shop/shared/header.data';
import { HEADER_ITEMS_SETTINGS } from '@app/pages/settings/shared/header.data';
import { HEADER_ITEMS_USER } from '@app/pages/user/shared/header.data';
import { HEADER_ITEMS_ANALYTICS } from '@app/pages/analytics/shared/header.data';

export const HEADER_ITEMS_MAP: Map<string, IHeader[]> = new Map()
  .set(EPages.Showcase, HEADER_ITEMS_CATALOG)
  .set(EPages.SystemManagement, HEADER_ITEMS_SYSTEM_MANAGEMENT)
  .set(EPages.Shop, HEADER_ITEMS_SHOP)
  .set(EPages.Settings, HEADER_ITEMS_SETTINGS)
  .set(EPages.User, HEADER_ITEMS_USER)
  .set(EPages.Analytics, HEADER_ITEMS_ANALYTICS);
