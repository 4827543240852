<div class="dialog__header">
  <p *ngIf="data.action === goodsAction.Edit">Редактировать товар</p>
  <p *ngIf="data.action === goodsAction.Create">Добавить товар</p>
  <p *ngIf="data.action === goodsAction.View">Просмотр товара</p>
  <mat-icon (click)="closeModalWindow()" class="dialog__icon __white __s14" svgIcon="x-mark"></mat-icon>
</div>

<form [formGroup]="form" class="form">
  <div class="photo">
    <div class="photo__label">Фото товара</div>
    <div class="photo__actions">
      <div
        (click)="removeUploadedImage()"
        *ngIf="previewImageData"
        [class.__removable]="data.action !== goodsAction.View"
        class="photo__uploaded"
      >
        <img [src]="previewImageData" alt="Goods image" class="photo__image" />
      </div>
      <ng-container *ngIf="data.action !== goodsAction.View">
        <button (click)="openFileUploader($event)" class="photo__add">
          {{previewImageData ? 'Изменить фото' : 'Добавить фото'}}
        </button>
      </ng-container>
      <input #imgFileInput (change)="readInputFile($event)" hidden type="file" />
    </div>
  </div>
  <formly-form [fields]="fields" [form]="form" [model]="model"></formly-form>
  <div *ngIf="data.action !== goodsAction.View" class="form__actions">
    <button (click)="closeModalWindow()" class="regular-button __stroked __mr-12">Отмена</button>
    <button (click)="onFormSubmit()" [disabled]="!form.valid" class="regular-button __flat">Сохранить</button>
  </div>
  <div *ngIf="data.action === goodsAction.View" class="form__actions">
    <button (click)="closeModalWindow()" class="regular-button __stroked __mr-12">Закрыть</button>
  </div>
</form>
