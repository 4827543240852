<ng-container>
  <span [class.bold]="bold">
    {{ item.quantity }}

    <ng-container *ngIf="withPostfix"><ng-template *ngTemplateOutlet="postfix"></ng-template></ng-container>
    </span>
</ng-container>

<div class="shipment-quantity-delta"
     *ngIf="isQuantityNotEqualsLinkedMovementQuantity"
     [matTooltip]="tooltipText"
     matTooltipPosition="above"
     matTooltipClass="default-tooltip"
>
  <span [class.bold]="bold"
        [class.zero]="item.linkedMovementQuantity === 0"
  >
      ({{item.linkedMovementQuantity}})

      <ng-container *ngIf="withPostfix"><ng-template *ngTemplateOutlet="postfix"></ng-template></ng-container>
  </span>
</div>

<ng-template #postfix>
  шт.
</ng-template>
