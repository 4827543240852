import { Injectable } from '@angular/core';
import { WebApiService } from '@app/@core/web-api/shared/services/web-api.service';
import { IConfigurationItem } from '../interfaces/configuration-item.interface';
import { Observable } from 'rxjs';
import { ICollectionWithPagination } from '@app/@core/shared/interfaces/collection-with-pagination.interface';
import { ApiParamsUtil } from '@app/@core/shared/utils/api-params.util';
import { IFooterLink } from '@ui/components/footer/shared/footer-link.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationDataService {

  constructor(
    private readonly api: WebApiService,
  ) { }

  public getConfigurationList(filters: any): Observable<ICollectionWithPagination<IConfigurationItem>> {
    const params = ApiParamsUtil(filters);

    return this.api.get<ICollectionWithPagination<IConfigurationItem>>(
      `Configuration`, params,
    );
  }

  public create(payload: IConfigurationItem): Observable<IConfigurationItem> {
    const params = ApiParamsUtil(payload);
    delete params.id;

    return this.api.post('Configuration', params);
  }

  public update(payload: IConfigurationItem): Observable<IConfigurationItem> {
    const params = ApiParamsUtil(payload);
    delete params.id;

    return this.api.put(`Configuration/${payload.id}`, params);
  }

  public delete(payload: IConfigurationItem): Observable<IConfigurationItem> {

    return this.api.delete(`Configuration/${payload.id}`);
  }

  public getFooterLinks(): Observable<IFooterLink[]> {
    return this.api.get(`Configuration/FooterLinks`);
  }
}
