<div class="date-range-select-wrapper">
  <mat-form-field #select [class.merged]="merged" class="date-range-select">
    <mat-select
      (openedChange)="onOpenedChange($event)"
      (selectionChange)="onSelectSelectionChange($event)"
      [(ngModel)]="selectedDateRangePeriodType"
      panelClass="date-range-select__panel"
    >
      <mat-option (click)="openSingleDatePicker()" [value]="DateRangePeriodType.SPECIFIED_DAY">{{singleDayOptionLabel}}</mat-option>
      <mat-option [value]="DateRangePeriodType.TODAY">За сегодня</mat-option>
      <mat-option [value]="DateRangePeriodType.YESTERDAY">За вчера</mat-option>
      <mat-option [value]="DateRangePeriodType.WEEK">За неделю</mat-option>
      <mat-option [value]="DateRangePeriodType.TWO_WEEKS">За 2 недели</mat-option>
      <mat-option [value]="DateRangePeriodType.MONTH">За месяц</mat-option>
      <mat-option (click)="openRangeDatePicker()" [value]="DateRangePeriodType.SPECIFIED_PERIOD">{{rangeOptionLabel}}</mat-option>
      <mat-option [value]="DateRangePeriodType.ALL_TIME">За всё время</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<input (dateChange)="onSingleDateSelected($event)" [matDatepicker]="singleDatePicker" class="hidden-datepicker-input" />

<app-mat-daterange-picker
  #picker
  (selectedChange)="onDatePeriodSelected($event)"
  [dateFrom]="selectedDateRangePeriod.from"
  [dateTo]="selectedDateRangePeriod.to"
  [input]="select"
></app-mat-daterange-picker>
<mat-datepicker #singleDatePicker [calendarHeaderComponent]="header"></mat-datepicker>
