import {
  NotificationStatusEnum,
  NotificationsTypeEnum,
} from '@ui/modules/notifications-list-popup/notifications-list-content/notifications-list-item/shared/notifications-list-list-item.interface';
import { DefaultTableQueryDto } from '@core/shared/dto/default-table-query.dto';

export class NotificationsCountQueryDto extends DefaultTableQueryDto {
  notificationsStatus?: NotificationStatusEnum;
  notificationType?: NotificationsTypeEnum;
}
