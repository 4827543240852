import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDatepickerHeaderComponent } from '@ui/components/mat-form-components/mat-datepicker-header/mat-datepicker-header.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from '@angular/forms';
import { DATEPICKER_FORMAT } from '@ui/components/mat-form-components/mat-datepicker/shared/datepicker.format';

@Component({
  selector: 'app-mat-datepicker',
  templateUrl: './mat-datepicker.component.html',
  styleUrls: ['./mat-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT }],
})
export class MatDatepickerComponent extends FieldType {
  @Input() formControl!: UntypedFormControl;
  @ViewChild('datepicker') datepicker!: MatDatepicker<ElementRef>;
  header = MatDatepickerHeaderComponent;

  showDatepicker() {
    this.datepicker.open();
  }
}
