<mat-form-field class="mat-select-custom">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    (selectionChange)="handleOptionSelected($event.value)"
    [disabled]="isDisabled"
    [multiple]="multiple"
    [value]="value"
  >
    <mat-option
      *ngFor="let option of options"
      [disabled]="option.disabled"
      [value]="option.value"
      [attr.data-anchor]="option.title"
    >
      {{ option[keyTitle] }}
    </mat-option>
  </mat-select>
</mat-form-field>
