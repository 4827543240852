import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { IShipmentNote } from '@core/shared/interfaces/order.interface';

@Component({
  selector: 'app-order-view-comments',
  templateUrl: './order-view-comments.component.html',
  styleUrls: ['./order-view-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderViewCommentsComponent {

  @Input() comments: IShipmentNote[];
}
