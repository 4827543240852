import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Self,
  ViewChild,
} from '@angular/core';
import { IProductCard } from '@core/shared/interfaces/product-card.interface';
import { FormlyFormOptions } from '@ngx-formly/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ADD_GOOD_FORM_DATA } from '@ui/widgets/settings-widgets/add-good/shared/form.data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFieldsKinds } from '@app/pages/settings/nomenclature/shared/kinds.interface';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { debounceTime, filter, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { IFormlyField } from '@ui/widgets/settings-widgets/add-good/shared/form-fields.interface';
import { ICategories } from '@ui/widgets/settings-widgets/add-good/shared/categories.interface';
import { IBrands } from '@ui/widgets/settings-widgets/add-good/shared/brands.interface';
import { GoodsSettingsService } from '@app/pages/settings/goods-settings.service';
import { EMPTY, of } from 'rxjs';
import { IAddGoodDialog } from '@ui/widgets/settings-widgets/add-good/shared/dialog.interface';
import { EGoodActions } from '@ui/widgets/settings-widgets/add-good/shared/good-actions.enum';
import { MAX_EXPIRATION_DAYS } from '@ui/widgets/settings-widgets/add-good/shared/max-expiration-days';

@Component({
  selector: 'app-add-good',
  templateUrl: './add-good.component.html',
  styleUrls: ['./add-good.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroy],
})
export class AddGoodComponent implements OnInit, AfterViewInit {
  @ViewChild('imgFileInput', { static: true }) imgFileInput!: ElementRef;
  readonly inputDebounceTime = 300;
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: Record<string, unknown> = {};
  options: FormlyFormOptions = {};
  fields: IFormlyField[] = ADD_GOOD_FORM_DATA;
  previewImageData!: string | null;
  uploadedImageFormData!: FormData;
  categories!: ICategories[];
  goodsAction = EGoodActions;
  goodImageWasDeleted = false;
  private good: IProductCard;

  constructor(
    public dialogRef: MatDialogRef<AddGoodComponent>,
    private settingsService: GoodsSettingsService,
    @Self() private componentDestroyed$: NgOnDestroy,
    @Inject(MAT_DIALOG_DATA) public data: IAddGoodDialog,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.setKindsActions();
    if (this.data.action !== EGoodActions.Create) {
      this.setControlsValue();
    }


  }

  ngAfterViewInit(): void {
    this.setAllControlsStatus(this.data.action);
    this.setCategories();
    this.setBrands();

    const expirationDaysControl = this.form.controls.expirationDays;

    if (expirationDaysControl) {
      expirationDaysControl.valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
      ).subscribe(value => {
        if (value > MAX_EXPIRATION_DAYS) {
          expirationDaysControl.setValue(MAX_EXPIRATION_DAYS);
          this.cdRef.detectChanges();
        }
      });
    }
  }

  setBrands(): void {
    const brandControl: UntypedFormControl = this.form.get('brandId') as UntypedFormControl;
    brandControl.valueChanges
      .pipe(
        debounceTime(this.inputDebounceTime),
        mergeMap((value: number) => this.settingsService.getChildBrandsByParentId(value)),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((brands: IBrands[]) => {
        const subBrandField = this.fields.find((item) => item.key === 'subBrandId') as IFormlyField;
        subBrandField.templateOptions!.options = brands;
      });
  }

  setCategories(): void {
    const categoryCodeControl: UntypedFormControl = this.form.get('categoryName') as UntypedFormControl;
    const categoryNameControl: UntypedFormControl = this.form.get('categoryId') as UntypedFormControl;

    categoryCodeControl.valueChanges
      .pipe(
        debounceTime(this.inputDebounceTime),
        mergeMap((res: string) => this.settingsService.getFindCategoryByCode(res)),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((categories: ICategories[]) => {
        this.categories = categories;
        this.setCategoriesValue(categories);
      });

    categoryNameControl.valueChanges
      .pipe(
        filter((value) => value),
        debounceTime(this.inputDebounceTime),
        mergeMap((res: number) => this.settingsService.getCategoryById(res)),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((categoryName: ICategories) => {
        categoryCodeControl.setValue(categoryName.code, { onlySelf: true, emitEvent: false });
        categoryCodeControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      });
  }

  closeModalWindow(): void {
    this.form.reset();
    this.dialogRef.close();
  }

  readInputFile(input: Event): void {
    const file: File = (input.target as HTMLFormElement).files[0];
    const previewReader: FileReader = new FileReader();
    previewReader.onload = () => {
      this.previewImageData = previewReader.result as string;
      this.cdRef.markForCheck();
    };
    previewReader.readAsDataURL(file);
    this.uploadedImageFormData = new FormData();
    this.uploadedImageFormData.append('file', file, file.name);
    this.goodImageWasDeleted = false;
  }

  removeUploadedImage(): void {
    if (this.data.action !== EGoodActions.View) {
      this.previewImageData = null;
      (this.imgFileInput.nativeElement as HTMLInputElement).value = '';

      if (!this.uploadedImageFormData) {
        this.goodImageWasDeleted = true;
      } else {
        this.setPreviewByFirstPhoto();
      }

      this.uploadedImageFormData = null;
    }
  }

  onFormSubmit(): void {
    this.buildRequest()
      .pipe(
        switchMap((result: IProductCard) => {
            if (this.goodImageWasDeleted && result.photos.length) {
              console.log(1);
              return this.settingsService.removeGoodImage(result.id, result.photos[0].id);
            } else if (this.uploadedImageFormData) {
              console.log(2);
              return this.settingsService.uploadGoodImage(this.uploadedImageFormData, result.id);
            }

            console.log(3);
            return of(null);
          },
        ),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe({
        next: (_) => {
          this.form.reset();
          this.closeModalWindow();
        },
        error: (err) => {
          this.cdRef.detectChanges();
        },
      });
  }

  openFileUploader(event: MouseEvent) {
    this.imgFileInput.nativeElement.click();
    event.preventDefault();
  }

  private setAllControlsStatus(action: EGoodActions): void {
    switch (action) {
      case EGoodActions.View:
        this.form.disable();
        break;
      default:
        this.form.enable();
    }
    this.cdRef.detectChanges();
  }

  private setCategoriesValue(categories: ICategories[]): void {
    const categoryCodeControl: UntypedFormControl = this.form.get('categoryName') as UntypedFormControl;
    const categoryNameControl: UntypedFormControl = this.form.get('categoryId') as UntypedFormControl;
    this.fields.forEach((field: IFormlyField) => {
      if (field.fieldGroup) {
        const categoriesFields = field.fieldGroup.find((item: IFormlyField) => item.key === 'categoryId') as IFormlyField;
        if (categoriesFields) {
          categoriesFields.templateOptions!.options = categories;
        }
        const result = categories.find((item) => item.code === categoryCodeControl!.value);
        result ? this.setSelectedOptionToField(categoryNameControl, result.id) : categoryNameControl.reset();
      }
    });
  }

  private setSelectedOptionToField(control: UntypedFormControl, value: number | string): void {
    if (control) {
      control.setValue(value, { onlySelf: true, emitEvent: false });
    } else {
      throw new Error(`Отсутствует контрол: ${control}`);
    }
  }

  private setKindsActions(): void {
    this.fields.forEach((field: IFormlyField) => {
      if (field.action) {
        this.setDataByAction(field.action, field);
      }

      if (field.fieldGroup) {
        field.fieldGroup.forEach((item: IFormlyField) => {
          if (item.action) {
            this.setDataByAction(item.action, item);
          }
        });
      }
    });
  }

  private setDataByAction(action: string, target: IFormlyField): void {
    this.settingsService
      .getFormFieldsData(action)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res: IFieldsKinds[]) => {
        target.templateOptions!.options = res;
        if (target.key === 'unitKindId') {
          const options = target.templateOptions!.options;
          options.some((item) => item.name === 'ШТ' && options.unshift(item));
        }
      });
  }

  private getModifiedFormValuesStringIntoNumber(formValues: Record<string, string | number>) {
    return Object.keys(formValues).reduce(
      (object, field) => ({
        ...object,
        [field]:
          typeof formValues[field] === 'boolean'
            ? formValues[field]
            : Number(formValues[field])
            ? Number(formValues[field])
            : formValues[field],
      }),
      {},
    );
  }

  private setControlsValue(): void {
    if (this.data.id) {
      this.settingsService
        .getGoodsById(this.data.id)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((good: IProductCard) => {
          this.form.patchValue(good);
          this.good = good;

          const barcodeControl = this.form.get('barcode');
          const additionalBarcodeControl = this.form.get('additionalBarcode');

          if (good.mainBarcode && barcodeControl) {
            barcodeControl.patchValue(good.mainBarcode.code);
          }

          if (good.barcodes.length && additionalBarcodeControl) {
            additionalBarcodeControl.patchValue(good.barcodes[0].code);
          }

          const vatControl = this.form.get('vat');

          if (vatControl) {
            vatControl.patchValue(good.vatId);
          }

          this.setPreviewByFirstPhoto();

          this.cdRef.detectChanges();
        });
    }
  }

  private setPreviewByFirstPhoto() {
    if (this.good.photos.length && this.good.photos[0].id !== 0) {
      this.previewImageData = this.good.photos[0].previewUrl;
    }
  }

  private buildRequest() {
    const params: any = this.getModifiedFormValuesStringIntoNumber({
      ...this.form.value,
      customDeclarationNumber: 1,
      minDeliveryLot: 1,
    });

    params.vendorCode = this.form.value.vendorCode;

    return this.data.action === EGoodActions.Create
      ? this.settingsService.getCreateGood(params)
      : this.settingsService.getUpdateGood(this.data.id, params);
  }
}
