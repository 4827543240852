import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITableColumns } from '@ui/widgets/system-management-widgets/contracts-table/shared/contracts-table.interface';

@Component({
  selector: 'app-edit-table-columns',
  templateUrl: './edit-table-columns.component.html',
  styleUrls: ['./edit-table-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTableColumnsComponent {
  form!: UntypedFormGroup;
  private readonly excludedColumnName = 'settings';

  constructor(
    private dialogRef: MatDialogRef<EditTableColumnsComponent, ITableColumns>,
    @Inject(MAT_DIALOG_DATA) public readonly columnsData: ITableColumns,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.initForm();
  }

  closeDialogWithoutData(): void {
    this.dialogRef.close();
  }

  closeDialogWithUpdatedColumns(): void {
    const columns = JSON.parse(JSON.stringify(this.columnsData)) as ITableColumns;
    Object.keys(columns)
      .filter((key) => key !== this.excludedColumnName)
      .forEach((column: string) => {
        columns[column].isActive = !!this.form.value[column];
      });
    this.dialogRef.close(columns);
  }

  preserveOriginalOrder = (): number => 0;

  private initForm() {
    const formSchema: Record<string, boolean> = {};
    Object.entries(this.columnsData)
      .filter(([key]) => key !== this.excludedColumnName)
      .forEach(([key, item]) => (formSchema[key] = item.isActive));
    this.form = this.formBuilder.group(formSchema);
  }
}
