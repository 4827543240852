<div mat-dialog-title class="dialog__header">
  Удалить {{ data.typeName }}
  <button (click)="cancel()" class="close-button" mat-button>
    <mat-icon class="close-button__icon" svgIcon="close"></mat-icon>
  </button>
</div>
<div class="dialog__content" mat-dialog-content>
  <p>Вы действительно хотите удалить {{ data.typeName }} "{{ data.itemName }}"?</p>
</div>
<div class="dialog__actions" mat-dialog-actions>
  <app-button (click)="delete()" class="__mr-10" name="Да" type="stroked"></app-button>
  <app-button (click)="cancel()" name="Нет" type="flat"></app-button>
</div>
