import { IUser } from '@core/shared/interfaces/user.interface';
import { IDeliveryType } from './delivery-type.interface';
import { IShipment } from '@core/shared/interfaces/shipment.interface';
import { IKind } from '@app/pages/settings/departments/shared/interface/kind.interface';

export enum OrderPaymentStatus {
  NOT_PAID = 5,
  PAID = 6,
}

export enum OrderIncidentStatus {
  IN_PROGRESS = -3,
  CONFIRMED = -4,
  REJECTED = -5,
}

export type OrderStatusArrayItem = -5 | -4 | -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6;
// tuple of 5
export type OrderStatusesArray = [
  OrderStatusArrayItem,
  OrderStatusArrayItem,
  OrderStatusArrayItem,
  OrderStatusArrayItem,
  OrderStatusArrayItem,
];

export interface IOrder {
  id: number;
  creationDateTime: string;
  allowedDeliveryTypes?: IDeliveryType[];
  deliveryType?: IDeliveryType;
  childrenId: number;
  movementType: number;
  customer: IOrderCustomer;
  supplier: IOrderCustomer;
  receiver: IOrderReceiver;
  sender: IOrderReceiver;
  prepaimentPercent: number;
  prepaimentSum: number;
  supplierTransferDate: string;
  movementStatus: IOrderMovementStatus;
  itemsCount: number;
  documentNumber?: string;
  sum: number;
  sumWithoutVat: number;
  statuses: OrderStatusesArray;
  actions: IOrderAction[];
  notes?: IShipmentNote[];
  attachment: string;
  minOrderSum: number;
  inQueueTransferDate: string;
  wasEdited: boolean;
}

export interface IOrderCustomer {
  id: number;
  shortName: string;
  inn: string;
  kindId: number;
}

export interface IOrderReceiver {
  id: number;
  name: string;
  description: string;
  address: string;
}

export interface IOrderMovementStatus {
  id: number;
  name: string;
  code: string;
}

export interface IOrderAction<T = OrderActionEnum> {
  caption: string;
  action: string;
  type: string;
  code: T;
  defaultAction: boolean;
}

export enum OrderActionEnum {
  CSV = 'Csv',
  REJECT = 'Reject',
  CONFIRM = 'Confirm',
  CLONE = 'Clone',
  EDIT = 'Edit',
  DELETE = 'Delete',
  SEND = 'Send',
  SIMPLE_ORDER_PDF = 'SimpleOrderPdf',
  XLSX = 'Xlsx',
  REVERT_ORDER = 'RevertOrder'
}

export enum ShipmentActionEnum {
  ACCEPT = 'Accept',
  REJECT_BY_CUSTOMER = 'RejectByCustomer',
  REJECT_BY_SUPPLIER = 'RejectBySupplier',
  ACCEPT_CLAIM = 'AcceptClaim',
  DECLINE_CLAIM = 'DeclineClaim',
  PAYMENT = 'Payment',
  CSV = 'Csv',
  EDIT = 'Edit',
  PAYMENT_RESERVE = 'PaymentReserve',
  PDF = 'Pdf',
  SIMPLE_SHIPMENT_PDF = 'SimpleShipmentPdf',
  READY_TO_SHIP = 'ReadyToShip',
  SHIP = 'Ship',
  GET_ON_BASE = 'GetOnBase',
  SET_ATTACHMENT = 'SetAttachment',
  GET_ATTACHMENT = 'GetAttachment',
  DELETE_ATTACHMENT = 'DeleteAttachment',
  XLSX = 'Xlsx',
  DELIVER = 'Deliver',
  ZIP = 'Zip',
  PRINT_TORG = 'PrintTorg',
  PRINT_SF = 'PrintSf',
  PRINT_TN = 'PrintTn',
  NAVIGATE_TO_ATTACHMENTS_PAGE = 'NavigateToAttachmentsPage',
  REVERT_PAYMENT = 'RevertPayment',
  REVERT_READY_TO_SHIP = 'RevertReadyToShip',
  REVERT_DELIVERY = 'RevertDelivery',
  REVERT_SHIP = 'RevertShip',
  REVERT_CLAIM = 'RevertClaim',
  REJECT_PAYMENT = 'RejectPayment',
  REVERT_SHIPMENT = 'RevertShipment'
}

export interface IOrderItem {
  id: number;
  good: IOrderGood;
  price: number;
  priceWithoutVat: number;
  quantity: number;
  linkedMovementQuantity: number;
  oldPrice: number | null;
  labelColor: string;
  discount: number;
}

export interface IOrderGood {
  id: number;
  name: string;
  vendorCode: string;
  unitKindName: string;
  vatKindValue: string;
  pickingQuantum: number;
  minQuantity: number;
}

export interface IOrdersRequest {
  id?: number;
  customerId?: number;
  receiverId?: number;
  supplierId?: number;
  fromDate?: string;
  toDate?: string;
  stateId?: number;
  statusId?: number;
  documentNumber?: string;
  page?: number;
  limit?: number;
  filter?: string;
  sortByNameDesc?: boolean;
}

export interface IShipmentNote {
  id: number;
  body: string;
  creationDateTime: string;
  user: INoteUser;
}

export interface INoteUser {
  id: number;
  fullName: string;
}

export interface IOrderTableItem extends IOrder {
  notes: undefined;
}

export interface IShipmentTableItem extends IShipment {
  notes: undefined;
}

export interface IOrderHistory {
  kind: IKind;
  modifyDateTime: string;
  movementStatusId: {
    id: number;
    name: string;
  };
  prepaimentSum: number;
  user: {
    user: number;
    name: string;
  }
}
