import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter, ChangeDetectorRef,
} from '@angular/core';
import { IOrder, IOrderAction, OrderActionEnum, ShipmentActionEnum } from '@core/shared/interfaces/order.interface';
import { IShipment } from '@core/shared/interfaces/shipment.interface';
import { BaseActionsService } from '@core/shared/services/order-actions-services/base-actions.service';
import { OrderActionsService } from '@core/shared/services/order-actions-services/order-actions.service';
import { ShipmentActionsService } from '@core/shared/services/order-actions-services/shipment-actions.service';
import { ShipmentActionsMapperService } from '@core/shared/services/order-actions-services/shipment-actions-mapper.service';
import { ShipmentActionsHandlerService } from '@core/shared/services/order-actions-services/shipment-actions-handler.service';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { OrderActionsMapperService } from '@core/shared/services/order-actions-services/order-actions-mapper.service';
import { OrderActionsHandlerService } from '@core/shared/services/order-actions-services/order-actions-handler.service';

@Component({
  selector: 'app-order-default-action',
  templateUrl: './order-default-action.component.html',
  styleUrls: ['./order-default-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    NgOnDestroy,
    BaseActionsService,
    OrderActionsService,
    ShipmentActionsService,
    ShipmentActionsMapperService,
    ShipmentActionsHandlerService,
    OrderActionsMapperService,
    OrderActionsHandlerService
  ]
})
export class OrderDefaultActionComponent<T> implements OnChanges {
  @Input() order: IOrder | IShipment;
  @Input() type: 'order' | 'shipment';
  @Output() action = new EventEmitter<IOrderAction<T>>();

  public isMinimalSumToOrderError = false;
  public defaultAction: IOrderAction<T>;

  constructor(
    private shipmentActionsMapperService: ShipmentActionsMapperService,
    private orderActionsMapperService: OrderActionsMapperService
  ) {
    this.shipmentActionsMapperService.onActionDone = this.action as EventEmitter<any>;
    this.shipmentActionsMapperService.shipmentActionsHandlerService.onActionDone = this.action as EventEmitter<any>;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('order' in changes) {
      this.setOrderDefaultAction();
      this.setIsMinimalSumToOrderError();
    }
  }

  public onActionBtnClick(): void {
    if (this.type === 'shipment') {
      this.shipmentActionsMapperService.mapAction(this.defaultAction as unknown as IOrderAction<ShipmentActionEnum>, this.order as IShipment);
    } else {
      this.orderActionsMapperService.mapAction(this.defaultAction as unknown as IOrderAction<OrderActionEnum>, this.order as IOrder);
    }
  }

  private setIsMinimalSumToOrderError() {
    if (!this.order) {
      return false;
    }

    this.isMinimalSumToOrderError = this.order.minOrderSum > this.order.sum;
  }

  private setOrderDefaultAction(): void {
    this.defaultAction = null;
    this.defaultAction = (this.order.actions as IOrderAction<any>[]).find(action => action.defaultAction);
  }
}
