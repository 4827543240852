import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-colored-text-inclusion',
  templateUrl: './colored-text-inclusion.component.html',
  styleUrls: ['./colored-text-inclusion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColoredTextInclusionComponent implements OnChanges {
  @Input() text!: string;
  @Input() key!: string | null;
  prefix = '';
  main = '';
  sufix = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.text || changes.key) {
      const key = (this.key || '').toLowerCase();
      const keyStartIndex = this.text.toLowerCase().indexOf(key);

      this.prefix = keyStartIndex > -1 ? this.text.slice(0, keyStartIndex) : this.text;
      this.main = keyStartIndex > -1 ? this.text.slice(keyStartIndex, keyStartIndex + key.length) : '';
      this.sufix = keyStartIndex > -1 ? this.text.slice(keyStartIndex + key.length) : '';
    }
  }
}
