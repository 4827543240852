import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import {IPopup, PopupModuleConfiguration} from '../popup.interface';
import Timer = NodeJS.Timer;

@Component({
  selector: 'app-popup-item',
  templateUrl: './popup-item.component.html',
  styleUrls: ['./popup-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupItemComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() notification: IPopup;

  @Output() destroy = new EventEmitter<IPopup>();

  private timer: Timer;

  constructor(@Optional() public options: PopupModuleConfiguration) { }

  ngOnInit() {
    if (this.notification.timeout <= 0 || setTimeout === undefined) {
      return;
    }

    this.startTimeout();
  }

  ngOnDestroy() {
    if (this.notification.timeout <= 0 || clearTimeout === undefined) {
      return;
    }

    clearTimeout(this.timer);
  }

  ngAfterViewInit(): void {

  }

  private startTimeout() {
    this.timer = setTimeout(this.onDestroy.bind(this), this.notification.timeout);
  }

  private onDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.destroy.next(this.notification);
    }
  }

}
