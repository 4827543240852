import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISimpleTextAreaDialogData } from '@ui/components/simple-textarea-dialog/shared/simple-text-area-dialog.data';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ISimpleTextAreaDialogOutputData } from '@ui/components/simple-textarea-dialog/shared/simple-text-area-dialog-output.data';

@Component({
  selector: 'app-simple-textarea-dialog',
  templateUrl: './simple-textarea-dialog.component.html',
  styleUrls: ['./simple-textarea-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleTextareaDialogComponent implements OnInit {
  public form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<SimpleTextareaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISimpleTextAreaDialogData,
    private fb: UntypedFormBuilder,
  ) {
    this.form = fb.group({
      text: ['', Validators.required],
    });
  }

  ngOnInit() {}

  public done() {
    const data: ISimpleTextAreaDialogOutputData = {
      result: true,
      text: this.form.get('text')!.value,
    };

    this.dialogRef.close(data);
  }

  public close() {
    const data: ISimpleTextAreaDialogOutputData = {
      result: false,
      text: '',
    };

    this.dialogRef.close(data);
  }
}
