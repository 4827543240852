import { Injectable } from '@angular/core';
import { WebApiService } from '@core/web-api/shared/services/web-api.service';
import { UploadFileDto } from '@core/web-api/shared/dto/upload-file.dto';
import { Observable, of, throwError } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { IUploadFileRes } from '@app/pages/shared/commodity-nomenclature/shared/upload-file-res.interface';
import { TypeResponse } from '@core/web-api/shared/enum/response.type';
import { IFileDownloadResult, IFileUploadProgress } from '@core/web-api/shared/interfaces/file-job-progress.interface';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadUploadService {
  private oneHundredPercent = 100;

  constructor(private api: WebApiService) {}

  public download(url: string, params?: any, typeResponse: TypeResponse = TypeResponse.blob): Observable<IFileDownloadResult> {
    return this.api.get(url, params, typeResponse, true).pipe(
      filter(
        (event: any) =>
          event.type === HttpEventType.DownloadProgress ||
          event.type === HttpEventType.Response ||
          event.type === HttpEventType.Sent,
      ),
      switchMap((event: any) => {
        if ([HttpEventType.DownloadProgress, HttpEventType.Sent].indexOf(event.type) !== -1) {
          const progress: IFileDownloadResult = {
            progress: this.getProgress(event),
            done: false,
          };

          return of(progress);
        } else {
          const progress: IFileDownloadResult = {
            progress: this.oneHundredPercent,
            done: true,
            file: event.body,
          };

          return of(progress);
        }
      }),
    );
  }

  public upload(dto: UploadFileDto): Observable<IFileUploadProgress<undefined | IUploadFileRes>> {
    return this.api.post(dto.url, dto.file, true, true).pipe(
      filter(
        (event: any) =>
          event.type === HttpEventType.UploadProgress ||
          event.type === HttpEventType.DownloadProgress ||
          event.type === HttpEventType.Response ||
          event.type === HttpEventType.Sent,
      ),
      switchMap((event: any) => {
        if (
          [HttpEventType.UploadProgress, HttpEventType.DownloadProgress, HttpEventType.Sent].indexOf(event.type) !== -1
        ) {
          const progress: IFileUploadProgress = {
            progress: this.getProgress(event),
            done: false,
          };

          return of(progress);
        } else {
          if (!event.body.succeed) {
            return throwError(event.body);
          }

          const progress: IFileUploadProgress<IUploadFileRes> = {
            progress: this.oneHundredPercent,
            done: true,
            response: event.body,
          };

          return of(progress);
        }
      }),
    );
  }

  private getProgress(event: any) {
    return event.total ? Math.floor((this.oneHundredPercent * event.loaded) / event.total) : 0;
  }
}
