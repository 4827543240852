import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { INotificationsListItem } from '@ui/modules/notifications-list-popup/notifications-list-content/notifications-list-item/shared/notifications-list-list-item.interface';
import { TableResponseDto } from '@core/shared/dto/table-response.dto';
import { WebsocketService } from '@app/@core/shared/services/websocket/websocket.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsListState {
  private unreadNotificationsCount$ = new BehaviorSubject<number>(0);
  private lastNotifications$ = new BehaviorSubject<INotificationsListItem[]>([]);
  private notifications$ = new BehaviorSubject<TableResponseDto<INotificationsListItem>>(
    new TableResponseDto<INotificationsListItem>(),
  );

  constructor(private readonly ws: WebsocketService) {
    this.initWebSocketSubscriptions();
  }

  private initWebSocketSubscriptions(): void {
    this.ws.notificationReceived$
      .pipe(filter((item: INotificationsListItem) => item !== null))
      .subscribe((item: INotificationsListItem) => {
        // Обновим счетчик непрочитанных сообщений
        const unread = this.unreadNotificationsCount$.value;
        this.unreadNotificationsCount$.next(unread + 1);

        // Обновим список последних сообщений
        const lastNotifications = this.lastNotifications$.value;
        lastNotifications.splice(-1, 1);
        lastNotifications.unshift(item);
        this.lastNotifications$.next(lastNotifications);
      });
  }

  public getLastNotifications(): Observable<INotificationsListItem[]> {
    return this.lastNotifications$.asObservable();
  }

  public getUnreadNotificationsCount(): Observable<number> {
    return this.unreadNotificationsCount$.asObservable();
  }

  public getNotifications() {
    return this.notifications$.asObservable();
  }

  public setUnreadNotificationsCount(count: number) {
    this.unreadNotificationsCount$.next(count);
  }

  public setLastNotifications(notifications: INotificationsListItem[]) {
    this.lastNotifications$.next(notifications);
  }

  public setNotifications(notifications: TableResponseDto<INotificationsListItem>) {
    this.notifications$.next(notifications);
  }
}
