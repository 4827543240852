import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HEADER_ITEMS_MAP } from '@ui/components/header/shared/header.map';
import { IHeader } from '@ui/components/header/shared/interfaces/header.interface';

@Injectable({ providedIn: 'root' })
export class PagesService {
  getHeaderItems(page: string): Observable<IHeader[]> {
    const parentRoute = page.split('/')[1];
    return of(HEADER_ITEMS_MAP.get(parentRoute)) as Observable<IHeader[]>;
  }
}
