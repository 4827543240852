import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-global-progress-bar',
  templateUrl: './global-progress-bar.component.html',
  styleUrls: ['./global-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalProgressBarComponent {
  @Input() progress!: number;

  constructor() {}
}
