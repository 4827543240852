import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, Input, OnChanges } from '@angular/core';
import { BlockWrapHeaderDirective } from '@ui/components/block-wrap/directives/block-wrap-header.directive';

@Component({
  selector: 'app-block-wrap',
  templateUrl: './block-wrap.component.html',
  styleUrls: ['./block-wrap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockWrapComponent implements OnChanges {
  @ContentChild(BlockWrapHeaderDirective) header: BlockWrapHeaderDirective;

  @Input() className!: string;

  constructor(private elRef: ElementRef<HTMLElement>) {}

  ngOnChanges(): void {
    const themeClass = this.className ? this.className : '';
    this.elRef.nativeElement.className = `${this.elRef.nativeElement.className} ${themeClass}`;
  }
}
