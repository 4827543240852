<div mat-dialog-title>
  Настроить таблицу
  <button (click)="closeDialogWithoutData()" class="close-button" mat-button>
    <mat-icon class="close-button__icon" svgIcon="close"></mat-icon>
  </button>
</div>
<div class="delete__content" mat-dialog-content>
  <form [formGroup]="form">
    <div *ngFor="let column of columnsData | keyvalue: preserveOriginalOrder" class="checkbox-wrapper">
      <label *ngIf="column.key !== 'settings'" class="checkbox-widget__label">
        <input
          [formControlName]="column.key"
          [value]="column.value.isActive"
          class="visually-hidden checkbox-widget__input"
          type="checkbox"
        />
        <span class="checkbox-widget__pseudo"></span>
        <span class="checkbox-widget__text">{{ column.value.label }}</span>
      </label>
    </div>
  </form>
</div>
<div class="delete__buttons" mat-dialog-actions>
  <app-button (click)="closeDialogWithoutData()" name="Отменить" type="stroked"></app-button>
  <app-button (click)="closeDialogWithUpdatedColumns()" name="Сохранить" type="flat"></app-button>
</div>
