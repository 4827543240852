<div class="header">
  <div class="header__left">
    <!-- <img alt="Logo" class="logo" src="assets/img/logo.svg"> -->
    <h3 class="logo">UNDER CONSTRUCT</h3>
  </div>

  <ng-container *ngIf="headerItems$ | async as headerItems">

    <div class="navigations">
      <button (click)="setScrollToLeft()"
              *ngIf="headerItems && headerItems.length > 4"
              class="left-control">
        <mat-icon class="__s18 __white" svgIcon="arrow-left-header"></mat-icon>
      </button>

      <ul #navigationEl class="navigations__items hide-scrollbar">
        <ng-container *ngFor="let item of headerItems">
          <ng-container *appUserCan="item.action">
            <li
              *ngIf="item.step && item.text; else usual"
              [class.disabled]="item.isDisabled"
              class="navigations__item">
              <a
                (done)="onDone()"
                [joyrideStep]="item.step"
                [routerLinkActive]="'active'"
                [routerLink]="[item.path]"
                [title]="item.text"
                class="navigations__link">
                {{ item.label }}
              </a>
            </li>
            <ng-template #usual>
              <li [class.disabled]="item.isDisabled"
                  class="navigations__item">
                <a [routerLinkActive]="'active'" [routerLink]="[item.path]"
                   class="navigations__link">{{ item.label }}</a>
              </li>
            </ng-template>
          </ng-container>
        </ng-container>
      </ul>

      <button (click)="setScrollToRight()"
              *ngIf="headerItems && headerItems.length > 4"
              class="right-control">
        <mat-icon class="__s18 __white" svgIcon="arrow-right-header"></mat-icon>
      </button>
    </div>

    <div *ngIf="currentRouteItem" class="navigations-mobile">
      <button [disableRipple]="true"
              [matMenuTriggerFor]="menu"
              class="button navigations-mobile__trigger"
              mat-button>
        {{currentRouteItem?.label}}
        <mat-icon class="__white __s8 __ml10 default-icon" svgIcon="arrow-down-clean"></mat-icon>
        <mat-icon class="__primary-blue __s12 __ml10 mob-icon" svgIcon="arrow-down-clean"></mat-icon>
      </button>

      <mat-menu #menu="matMenu"
                class="navigations-mobile__menu">
        <div *ngFor="let item of headerItems">
          <button
            *appUserCan="item.action"
            [class.disabled]="item.isDisabled"
            [routerLinkActive]="'active'"
            [routerLink]="[item.path]"
            class="navigations-mobile__item"
            mat-menu-item>
            {{ item.label }}
          </button>
        </div>
      </mat-menu>
    </div>

  </ng-container>

  <div class="header__right">
    <app-button
      id="joyride-sidebar-profile-element"
      *ngIf="!(authService.isCurrentUserIsAnonymous$ | async)"
      type="flat"
      class="header__person"
      (click)="navigateToProfile()">
      <span>{{authService.currentUser?.firstName}}</span>
      <mat-icon svgIcon="person-header"></mat-icon>
    </app-button>

    <app-button (click)="navigateToRegistration()" *ngIf="authService.isCurrentUserIsAnonymous$ | async" type="flat"
    >
      <span class="button-text">Регистрация</span>
      <mat-icon class="header__action-icon __white __s20" svgIcon="register"></mat-icon>
    </app-button>

    <app-button (click)="logout()" *ngIf="globalErrorService.globalFatalErrorHappened" type="flat"
    >Выйти
    </app-button>

    <ng-container *ngIf="authService.isCurrentUserIsAnonymous$ | async">
      <button
        type="button"
        mat-flat-button
        color="primary"
        (click)="login()"
      >
        Войти
        <mat-icon svgIcon="login_white_24dp"></mat-icon>
      </button>
    </ng-container>

    <!-- <app-button (click)="startAuth()" *ngIf="authService.isCurrentUserIsAnonymous$ | async" type="flat"
    >
      <span class="button-text">Войти</span>
      <mat-icon class="header__action-icon __white __s20" svgIcon="sign-in"></mat-icon>
    </app-button> -->

    <a
      *appUserCan="RoleAccessEnum.VIEW_NOTIFICATIONS"
      [class.header__item--active]="isMenuItemActive$ | async"
      [routerLink]="null"
      class="header__notifications"
    >
      <app-notifications-header-button
        (openState)="isMenuItemActive$.next($event)"
        joyrideStep="catalogStep9"
        text="Проверяйте уведомления, там может быть важная информация"
      ></app-notifications-header-button>
    </a>
  </div>
</div>
