<footer>
  <div class="container">
    <div class="column">
      <div class="column-content">
        <a routerLink="/">
          <h3 class="logo">UNDER CONSTRUCT</h3>
        </a>
      </div>
    </div>
    <div class="column">
      <div class="column-content">
        <div class="links">
          <a [href]="getLinkByCode('about-service')">О сервисе</a>
          <a [href]="getLinkByCode('user-manual')">Инструкция для пользователя</a>
          <a [href]="getLinkByCode('questions')">Список вопросов</a>
          <a [href]="getLinkByCode('contacts')">Контакты</a>

          <a [href]="getLinkByCode('user-agreement')" class="last-link">Пользовательское соглашение</a>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="column-content">
        <div class="links">
          <span class="bold">Покупателям</span>
          <a [href]="getLinkByCode('payments')">Оплата и доставка</a>
          <a [href]="getLinkByCode('promo')">Промо</a>

          <a [href]="getLinkByCode('privacy-policy')" class="last-link"
            >Обработка персональных данных</a
          >
        </div>
      </div>
    </div>
    <div class="column">
      <div class="column-content">
        <!-- <div class="links">
          <span>Техническая поддержка</span>
          <a [href]="getLinkByCode('tech-phone-link')">{{getLinkByCode('tech-phone')}}</a>
          <a [href]="getLinkByCode('tech-email-link')">{{getLinkByCode('tech-email')}}</a>
        </div>

        <div class="social">
          <a [href]="getLinkByCode('facebook')" target="_blank"><mat-icon svgIcon="facebook"></mat-icon></a>
          <a [href]="getLinkByCode('instagram')" target="_blank"
            ><mat-icon svgIcon="instagram"></mat-icon
          ></a>
          <a [href]="getLinkByCode('vk')" target="_blank"><mat-icon svgIcon="vk"></mat-icon></a>
          <a [href]="getLinkByCode('ok')" target="_blank"
            ><mat-icon svgIcon="ok"></mat-icon
          ></a>
        </div> -->
      </div>
    </div>
  </div>
</footer>
