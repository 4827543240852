export function getFileExtensionUtil(type: string): string {
  switch (type) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/pdf':
      return 'pdf';
    case 'text/plain':
      return 'csv';
    case 'text/csv':
      return 'csv';
    case 'application/zip':
      return 'zip';

    default:
      return 'csv';
  }
}
