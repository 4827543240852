import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  DialogTitleDirective,
  DialogActionsDirective,
  DialogContentDirective,
} from './directives';
import { DialogBaseComponent } from './dialog-base/dialog-base.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    DialogTitleDirective,
    DialogActionsDirective,
    DialogContentDirective,
    DialogBaseComponent,
    SignInComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [
    DialogBaseComponent,
    SignInComponent,
    DialogTitleDirective,
    DialogActionsDirective,
    DialogContentDirective,
  ]
})
export class LiriDialogModule { }
