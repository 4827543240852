import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-radio',
  template: `
    <div class="radio">
      <div class="radio__label">
        {{ field.templateOptions?.label }}
        <span class="required-postfix" *ngIf="field?.templateOptions?.required">*</span>
      </div>
      <mat-radio-group [formControl]="formControl" [formlyAttributes]="field">
        <mat-radio-button
          color="primary"
          class="radio__control"
          *ngFor="let option of to.options"
          [value]="option.value"
        >
          {{ option.key }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent extends FieldType {}
