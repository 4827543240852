import { InjectionToken } from '@angular/core';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';


const primary_blue = '#3438e0';

export const TIMEPICKER_THEME = new InjectionToken('ngxTimepickerTheme');

export const timePickerTheme: NgxMaterialTimepickerTheme = {
  dial: {
    dialBackgroundColor: primary_blue
  },
  container: {
    buttonColor: primary_blue
  },
  clockFace: {
    clockHandColor: primary_blue
  }
}
