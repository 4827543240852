import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appBlockWrapHeader]'
})
export class BlockWrapHeaderDirective {
  @HostBinding('style.font-size')
  fontSize = '28px';

  @HostBinding('style.font-weight')
  fontWeight = '600';

  constructor() { }

}
