<div *ngIf="notifications$ | async as notifications"
     class="notifications-list"
     [@notificationListAnimation]="{value: notifications.length}"
>
  <div *ngFor="let notification of notifications; trackBy: trackById" class="notification-list-item">
    <app-popup-item [notification]="notification"
                    (destroy)="onNotificationDestroy($event)"
                    (click)="onNotificationDestroy(notification)"
    >
      <app-popup-item-body [notification]="notification"></app-popup-item-body>
    </app-popup-item>
  </div>
</div>
