import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-clicked-field',
  templateUrl: './clicked-field.component.html',
  styleUrls: ['./clicked-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClickedFieldComponent {
  @Input() value!: string | number;
  @Input() width = 50;
  @Input() disabled = false;
  @Input() type: 'number' | 'text' = 'number';

  @Output() changed: EventEmitter<number | string> = new EventEmitter();

  onChange(newValue: string) {
    if (this.value !== newValue) {
      if (this.type === 'number') {
        this.changed.emit(Number(newValue));
      } else {
        this.changed.emit(newValue);
      }
    }
  }
}
