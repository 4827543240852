import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export class NotificationsListOverlayRef {
  private _afterClosed = new Subject<void>();

  constructor(private overlayRef: OverlayRef) {}

  close(): void {
    this.overlayRef.dispose();
    this._afterClosed.next();
  }

  afterClosed() {
    return this._afterClosed;
  }
}
