import { Directive, ElementRef, Input, OnInit, Renderer2, Self} from '@angular/core';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { RoleAccessEnum } from '@core/shared/enums/role-access.enum';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@core/modules/auth/auth.service';

@Directive({
  selector: '[appUserCanInteract]',
  providers: [NgOnDestroy],
})
export class UserCanInteractDirective implements OnInit {
  @Input() appUserCanInteract!: RoleAccessEnum;

  constructor(
    @Self() private componentDestroyed$: NgOnDestroy,
    private elRef: ElementRef<HTMLElement>,
    private authService: AuthService,
    private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.authService
      .userHasAbility(this.appUserCanInteract)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((hasAbility) => {
        if (!hasAbility) {
          this.renderer.setAttribute(this.elRef.nativeElement, 'disabled', 'true');
        }
      });
  }

}
