import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDaterangePickerComponent } from '@ui/modules/mat-daterange-picker/components/mat-daterange-picker/mat-daterange-picker.component';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { MatDatepickerHeaderComponent } from '@ui/components/mat-form-components/mat-datepicker-header/mat-datepicker-header.component';
import { IDateRangeSelectedInterface } from '@ui/modules/mat-daterange-picker/components/mat-daterange-picker/shared/date-range-selected.interface';

@Component({
  selector: 'app-date-range-datepicker-content',
  templateUrl: './date-range-datepicker-content.component.html',
  styleUrls: ['./date-range-datepicker-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'date-range-picker-content',
  },
})
export class DateRangeDatepickerContentComponent implements OnInit {
  public datePicker!: MatDaterangePickerComponent;
  public header = MatDatepickerHeaderComponent;

  @ViewChild('pickerFrom') pickerFrom!: MatCalendar<Moment>;
  @ViewChild('pickerTo') pickerTo!: MatCalendar<Moment>;

  today = new Date();

  constructor() {}

  ngOnInit() {}

  public getDateClass(picker: 'from' | 'to'): (date: Moment) => MatCalendarCellCssClasses {
    return (date: Moment): MatCalendarCellCssClasses => {
      const classes = [];

      if (date.isBetween(this.datePicker.dateFrom, this.datePicker.dateTo)) {
        classes.push('mat-calendar-body-cell--is-between');
      }

      const day = date.weekday();
      const saturday = 5;
      const sunday = 6;

      if ((day === saturday || day === sunday) && (date > this.datePicker.dateFrom || picker === 'from')) {
        classes.push('mat-calendar-body-cell--holiday');
      }

      return classes;
    };
  }

  public onDateFromSelected(date: Moment) {
    this.datePicker.dateFrom = date;
    this.pickerFrom.updateTodaysDate();

    if (this.datePicker.dateTo < this.datePicker.dateFrom) {
      this.datePicker.dateTo = this.datePicker.dateFrom;
    }

    this.emitSelectedChange();
  }

  public onDateToSelected(date: Moment) {
    this.datePicker.dateTo = date;
    this.pickerTo.updateTodaysDate();
    this.pickerFrom.updateTodaysDate();
    this.emitSelectedChange();
  }

  public emitSelectedChange() {
    const eventData: IDateRangeSelectedInterface = {
      from: this.datePicker.dateFrom,
      to: this.datePicker.dateTo,
    };

    this.datePicker.select(eventData);
  }
}
