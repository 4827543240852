<mat-icon
  svgIcon="shipment-incident-inprogress"
  *ngIf="status === OrderIncidentStatus.IN_PROGRESS"
  matTooltip="Претензия | Разбор"
  matTooltipPosition="above"
  matTooltipClass="default-tooltip"
></mat-icon>

<mat-icon
  svgIcon="shipment-incident-confirmed"
  *ngIf="status === OrderIncidentStatus.CONFIRMED"
  matTooltip="Претензия | Подтверждено"
  matTooltipPosition="above"
  matTooltipClass="default-tooltip"
></mat-icon>

<mat-icon
  svgIcon="shipment-incident-rejected"
  *ngIf="status === OrderIncidentStatus.REJECTED"
  matTooltip="Претензия | Отказано"
  matTooltipPosition="above"
  matTooltipClass="default-tooltip"
>
</mat-icon>
