import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { NotificationsListOverlayRef } from '@ui/modules/notifications-list-popup/notifications-list-overlay/notifications-list-overlay.ref';
import { NotificationsListContentComponent } from '@ui/modules/notifications-list-popup/notifications-list-content/notifications-list-content.component';

@Injectable()
export class NotificationsListOverlayService {
  private lastCreatedDialogRef!: NotificationsListOverlayRef | null;

  constructor(private overlay: Overlay, private injector: Injector) {}

  private createInjector(dialogRef: NotificationsListOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(NotificationsListOverlayRef, dialogRef);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private createOverlay(element: ElementRef): OverlayRef {
    return this.overlay.create({
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition({ autoClose: true, scrollThrottle: 0 }),
      width: '443px',
      height: '424px',
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
      panelClass: 'notifications-list-popup',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(element)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ])
        .withDefaultOffsetX(0)
        .withDefaultOffsetY(0)
        .withViewportMargin(0)
        .withLockedPosition()
        .withFlexibleDimensions(false),
    });
  }

  private attachContainer(overlayRef: OverlayRef, dialogRef: NotificationsListOverlayRef) {
    const injector = this.createInjector(dialogRef);

    const containerPortal = new ComponentPortal(NotificationsListContentComponent, null, injector);
    const containerRef: ComponentRef<NotificationsListContentComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  public open(element: ElementRef): NotificationsListOverlayRef {
    if (this.lastCreatedDialogRef) {
      this.lastCreatedDialogRef.close();
      this.lastCreatedDialogRef = null;
    }

    const overlayRef = this.createOverlay(element);
    const dialogRef = new NotificationsListOverlayRef(overlayRef);

    this.attachContainer(overlayRef, dialogRef);

    this.lastCreatedDialogRef = dialogRef;

    overlayRef.backdropClick().subscribe(() => {
      if (this.lastCreatedDialogRef) {
        this.lastCreatedDialogRef.close();
      }
    });

    return dialogRef;
  }
}
