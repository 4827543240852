import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertMessageComponent implements OnInit {

  @Input() type: 'warning' = 'warning';

  constructor() { }

  ngOnInit() {
  }

  public getIcon(): string {
    switch (this.type) {
      case 'warning': {
        return 'alert-exclamation';
      }

      default: {
        return 'alert-exclamation';
      }
    }
  }
}
