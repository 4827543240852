import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NUMBER_PATTERN } from '@app/@core/shared/constants/number-pattern';
import { NgOnDestroy } from '@app/@core/shared/services/destroy.service';
import { INPUT_DEBOUNCE_TIME } from '@core/shared/constants/debounce-time';
import { DefaultControlValueAccessor, provideFormControl } from '@core/shared/models/default-control-value-accessor';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil, tap} from 'rxjs/operators';

enum INCREASE_DECREASE_KEYCODES {
  INCREASE = 107,
  DECREASE = 109,
}

@Component({
  selector: 'app-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroy, provideFormControl(QuantityComponent)],
})
export class QuantityComponent extends DefaultControlValueAccessor<number> implements OnInit {
  @Input() minStep = 1;
  @Input() theme: 'large' | 'small' = 'small';
  @Output() quantityChanged = new EventEmitter<number>();
  inputControl!: UntypedFormControl;

  public onQuantityChange$ = new EventEmitter<void>();

  constructor(@Self() private componentDestroyed$: NgOnDestroy, private cdRef: ChangeDetectorRef) {
    super();
    this.inputControl = new UntypedFormControl(0, [Validators.pattern(NUMBER_PATTERN)]);
  }

  ngOnInit(): void {
    this.onQuantityChange$
      .asObservable()
      .pipe(
        debounceTime(1300),
        filter(() => this.inputControl.valid),
        map(() => parseInt(this.inputControl.value, 10)),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((value) => {
        this.controlValue = value;
        this.quantityChanged.emit(value);
        this.onChange(value);
      });
  }

  writeValue(val: number): void {
    this.controlValue = val;
    this.inputControl.setValue(val, { emitEvent: false });
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable({
      emitEvent: false
    }) : this.inputControl.enable({
      emitEvent: false
    });
    super.setDisabledState(isDisabled);
    this.cdRef.detectChanges();
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    const { keyCode } = event;
    if (keyCode === INCREASE_DECREASE_KEYCODES.INCREASE) {
      this.onIncrease();
    } else if (keyCode === INCREASE_DECREASE_KEYCODES.DECREASE) {
      this.onDecrease();
    }
  }

  onIncrease() {
    this.inputControl.setValue(this.inputControl.value + this.minStep);
  }

  onDecrease() {
    this.inputControl.setValue(this.inputControl.value - this.minStep);
  }

  onQuantityBtnClick() {
    this.onQuantityChange$.emit();
  }

  onQuantityInputBlur() {
    this.onQuantityChange$.emit();
  }
}
