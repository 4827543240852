import { forwardRef, Type } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

export class DefaultControlValueAccessor<T = string> implements ControlValueAccessor {
  protected controlValue!: T;
  public disabled = false;

  get value(): T {
    return this.controlValue;
  }

  // tslint:disable-next-line:no-empty
  public onChange: (val: T | null) => void = (val: T | null) => {};

  // tslint:disable-next-line:no-empty
  public onTouched: () => void = () => {};

  public registerOnChange(fn: (val: T | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(val: T): void {
    this.controlValue = val;
  }
}

export function provideFormControl<T>(item: Type<T>) {
  return [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => item),
      multi: true,
    },
  ];
}

export function provideValidator<T>(item: Type<T>) {
  return [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => item),
      multi: true,
    },
  ];
}
