import { HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { TypeResponse } from '../enum/response.type';
import {
  IRequestOptions,
  IRequestParamsBuilder,
  IResultRequest,
  RequestMethod,
} from '../interfaces/request.params.builder.interface';

export const REQUEST_BUILDER = new InjectionToken('REQUEST_BUILDER');

export class BracketsHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
  encodeKey(k: string): string {
    return super.encodeKey(k)
      .replace(new RegExp('%5B', 'g'), '[')
      .replace(new RegExp('%5D', 'g'), ']');
  }
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class RequestBuilder implements IRequestParamsBuilder {
  private options!: IRequestOptions;
  private URL!: string;
  private method!: RequestMethod;

  constructor() {}

  public init(): RequestBuilder {
    this.options = {} as IRequestOptions;
    return this;
  }

  public setHeaders(headers: HttpHeaders, needFormData?: boolean | undefined): RequestBuilder {
    this.options.headers = headers;
    if (needFormData) {
      this.options.headers.set('Content-Type', 'multipart/form-data;');
    }
    return this;
  }

  public setObserve(value: any): RequestBuilder {
    this.options.observe = value;
    return this;
  }

  public setHttpParams(params: HttpParams): RequestBuilder {
    this.options.params = params;
    return this;
  }

  public createAndSetHttpParams(settings: Record<string, any> = {}): RequestBuilder {
    this.options.params = new HttpParams({
      encoder: new BracketsHttpUrlEncodingCodec(),
      fromObject: settings,
    });
    return this;
  }

  public setReportProgress(value: boolean): RequestBuilder {
    this.options.reportProgress = value;
    return this;
  }

  public setResponseType(type: TypeResponse = TypeResponse.json): RequestBuilder {
    this.options.responseType = type;
    return this;
  }

  public setWithCredentials(value: boolean): RequestBuilder {
    this.options.withCredentials = value;
    return this;
  }

  public build(): IResultRequest {
    return {
      url: this.URL,
      method: this.method,
      options: this.options,
    };
  }

  public setBody<T>(body: T): RequestBuilder {
    this.options.body = body;
    return this;
  }

  public setURL(url: string): RequestBuilder {
    this.URL = url;
    return this;
  }

  public setMethod(type: RequestMethod): RequestBuilder {
    this.method = type;
    return this;
  }
}
