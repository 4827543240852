import { Injectable } from '@angular/core';
import { ShipmentsService } from '@core/shared/services/shipments.service';
import { IOrderAction, ShipmentActionEnum } from '@core/shared/interfaces/order.interface';
import { map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { PopupService } from '@ui/shared/services/popup.service';
import { Observable } from 'rxjs';
import { showSuccessMessage } from '@core/shared/rxjs-operators/show-success-message';
import { Router } from '@angular/router';
import { FileUploadDialogComponent } from '@ui/modules/file-upload-dialog/file-upload-dialog.component';
import { IFileUploadDialogData } from '@ui/modules/file-upload-dialog/shared/file-upload-dialog-data';
import {IFileUploadDialogResult} from '@ui/modules/file-upload-dialog/shared/file-upload-dialog-result';
import { IActionsMenuListItem } from '@app/ui/modules/actions-menu/actions-menu-list-item.interface';
import { getFileExtensionUtil } from '../../utils/file-extension.util';
import { IShipment } from '@core/shared/interfaces/shipment.interface';

@Injectable()
export class ShipmentActionsService {
  constructor(
    private shipmentsService: ShipmentsService,
    private snackBar: PopupService,
    protected dialog: MatDialog,
    private router: Router,
  ) {}

  downloadAsCSV(item: IShipment) {
    return this.shipmentsService.downloadShipmentAsCSV(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}.${getFileExtensionUtil(file.type)}`);
      }),
    );
  }

  downloadAsXLSX(item: IShipment) {
    return this.shipmentsService.downloadShipmentAsXlsx(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}.${getFileExtensionUtil(file.type)}`);
      }),
    );
  }

  downloadAsPDF(item: IShipment): Observable<void> {
    return this.shipmentsService.downloadShipmentAsPDF(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}.${getFileExtensionUtil(file.type)}`);
      }),
    );
  }

  downloadAsSimplePDF(item: IShipment): Observable<void> {
    return this.shipmentsService.downloadShipmentAsSimplePDF(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}.${getFileExtensionUtil(file.type)}`);
      }),
    );
  }

  rejectByCustomer(item: IShipment, comment: string): Observable<unknown> {
    return this.shipmentsService
      .rejectShipmentByCustomer(item.id, comment)
      .pipe(showSuccessMessage(this.snackBar, 'Заказ отклонён'));
  }

  rejectBySuppler(item: IShipment, comment: string): Observable<unknown> {
    return this.shipmentsService
      .rejectShipmentBySupplier(item.id, comment)
      .pipe(showSuccessMessage(this.snackBar, 'Заказ отклонён'));
  }

  accept(item: IShipment): Observable<unknown> {
    return this.shipmentsService.acceptShipment(item.id).pipe(showSuccessMessage(this.snackBar, 'Заказ принят'));
  }

  paymentReserve(item: IShipment): Observable<unknown> {
    return this.shipmentsService
      .paymentReserve(item.id)
      .pipe(showSuccessMessage(this.snackBar, 'Заказ переведен в статус "Требует внесения предоплаты"'));
  }

  acceptClaim(item: IShipment, comment: string): Observable<unknown> {
    return this.shipmentsService.acceptClaim(item.id, comment).pipe(showSuccessMessage(this.snackBar, 'Претензия принята'));
  }

  declineClaim(item: IShipment, comment: string): Observable<unknown> {
    return this.shipmentsService.declineClaim(item.id, comment).pipe(showSuccessMessage(this.snackBar, 'Претензия отклонена'));
  }

  readyToShip(item: IShipment): Observable<unknown> {
    return this.shipmentsService.readyToShip(item.id).pipe(showSuccessMessage(this.snackBar, 'Успешно'));
  }

  ship(item: IShipment): Observable<unknown> {
    return this.shipmentsService.ship(item.id).pipe(showSuccessMessage(this.snackBar, 'Успешно'));
  }

  shipmentCorrectionRequest(uri: string, reason: string): Observable<unknown> {
    return this.shipmentsService.shipmentCorrectionRequest(uri, reason);
  }

  deleteAttachment(action: IOrderAction<ShipmentActionEnum>): Observable<any> {
    return this.shipmentsService.deleteShipmentAttachment(action).pipe(showSuccessMessage(this.snackBar, 'Успешно'));
  }

  getAttachment(action: IActionsMenuListItem<ShipmentActionEnum>, attachmentName: string): Observable<any> {
    return this.shipmentsService.downloadShipmentAttachment(action)
      .pipe(
        map((file: Blob) => {
          saveAs(file, attachmentName);
        }),
        showSuccessMessage(this.snackBar, 'Успешно'),
      );
  }

  navigateToParentOrder(orderId: number) {
    this.router.navigate(['/', 'orders', orderId]);
  }

  openAttachmentDialog(shipmentId: number): Observable<IFileUploadDialogResult> {
    const data: IFileUploadDialogData = {
      url: `Shipments/${shipmentId}/Attachment`,
    };

    return this.dialog
      .open(FileUploadDialogComponent, {
        width: '600px',
        panelClass: 'common-dialog',
        data,
      })
      .afterClosed();
  }

  deliver(item: IShipment) {
    return this.shipmentsService.deliver(item.id)
      .pipe(showSuccessMessage(this.snackBar, 'Отгрузка успешно изменена'));
  }

  downloadDocumentsAsZIP(item: IShipment): Observable<void> {
    return this.shipmentsService.downloadShipmentDocumentsAsZip(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}-documents.${getFileExtensionUtil(file.type)}`);
      }),
    );
  }

  downloadShipmentTorg12(item: IShipment): Observable<void> {
    return this.shipmentsService.downloadShipmentTorg12(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}-torg12.${getFileExtensionUtil(file.type)}`);
      }),
    );
  }

  downloadShipmentSf(item: IShipment): Observable<void> {
    return this.shipmentsService.downloadShipmentSf(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}-sf.${getFileExtensionUtil(file.type)}`);
      }),
    );
  }

  downloadShipmentTn(item: IShipment): Observable<void> {
    return this.shipmentsService.downloadShipmentTn(item.id).pipe(
      map((file: Blob) => {
        saveAs(file, `${item.documentNumber}-tn.${getFileExtensionUtil(file.type)}`);
      }),
    );
  }

  revert(item: IShipment, action: IOrderAction<ShipmentActionEnum>, comment: string) {
    const match = action.code.match(/^Revert(.+)$/);

    return this.shipmentsService.makeRevert(item.id, match[1], comment)
      .pipe(showSuccessMessage(this.snackBar, 'Изменения произведены'));
  }

  rejectPayment(item: IShipment, comment: string) {
    return this.shipmentsService.rejectPayment(item.id, comment)
      .pipe(showSuccessMessage(this.snackBar, 'Изменения произведены'));
  }
}
