import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PagesService } from '@app/pages/pages.service';
import { FormlyModule } from '@ngx-formly/core';
import { JoyrideModule } from 'ngx-joyride';

@NgModule({
  imports: [
    CommonModule, 
    ReactiveFormsModule, 
    FormlyModule, 
    MatIconModule, 
    JoyrideModule.forRoot(),
  ],
  exports: [
    CommonModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [PagesService],
    };
  }
}
