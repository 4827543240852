import { IMatIcon } from './interfaces/mat-icon.interface';

const src = '/assets/img/icons/';
const sidebar = '/assets/img/icons/sidebar/';

export const MATERIAL_ICONS_LIST: IMatIcon[] = [
  { name: 'showcase', src: sidebar },
  { name: 'shop', src: sidebar },
  { name: 'settings', src: sidebar },
  { name: 'settings-mini', src },
  { name: 'distributor', src: sidebar },
  { name: 'manufacturer', src: sidebar },
  { name: 'platform_control', src: sidebar },
  { name: 'operator', src: sidebar },
  { name: 'menu', src: sidebar },
  { name: 'analytics', src: sidebar },
  { name: 'equalizer', src: sidebar },
  { name: 'person-sidebar', src: sidebar },
  { name: 'shipments', src: sidebar },
  { name: 'orders', src: sidebar },
  { name: 'exit', src: sidebar },
  { name: 'wallet', src: sidebar },
  { name: 'logo', src },
  { name: 'search', src },
  { name: 'person', src },
  { name: 'search-mini', src },
  { name: 'cart', src },
  { name: 'cart-big', src },
  { name: 'cart-big-blue', src },
  { name: 'x-mark', src },
  { name: 'close', src },
  { name: 'close-square', src },
  { name: 'x-mark-circle', src },
  { name: 'hamburger', src },
  { name: 'sort', src },
  { name: 'dots-square', src },
  { name: 'list', src },
  { name: 'barcode', src },
  { name: 'sort', src },
  { name: 'sort-desc', src },
  { name: 'view-row', src },
  { name: 'view-list', src },
  { name: 'arrow-right', src },
  { name: 'arrow-left-header', src },
  { name: 'arrow-right-header', src },
  { name: 'plus', src },
  { name: 'minus', src },
  { name: 'dots-vertical', src },
  { name: 'basket', src },
  { name: 'plus-paper', src },
  { name: 'disabled-filter', src },
  { name: 'print', src },
  { name: 'download', src },
  { name: 'payment', src },
  { name: 'copy', src },
  { name: 'arrow-line-left', src },
  { name: 'arrow-line-right', src },
  { name: 'arrow-down', src },
  { name: 'lock', src },
  { name: 'trash', src },
  { name: 'cog', src },
  { name: 'edit-bordered', src },
  { name: 'bell-white', src },
  { name: 'bell-blue', src },
  { name: 'arrow-down-blue', src },
  { name: 'arrow-circle-right', src },
  { name: 'close-tall', src },
  { name: 'arrow-down-clean', src },
  { name: 'mark', src },
  { name: 'cancel-red', src },
  { name: 'payment-dialog-success', src },
  { name: 'credit-card', src },
  { name: 'payment-red', src },
  { name: 'payment-green', src },
  { name: 'comment', src },
  { name: 'payment-done', src },
  { name: 'payment-waiting', src },
  { name: 'shipment-incident-confirmed', src },
  { name: 'shipment-incident-inprogress', src },
  { name: 'shipment-incident-rejected', src },
  { name: 'accept-claim', src },
  { name: 'decline-claim', src },
  { name: 'document', src },
  { name: 'comment-dashed', src },
  { name: 'sign-in', src },
  { name: 'facebook', src },
  { name: 'instagram', src },
  { name: 'vk', src },
  { name: 'ok', src },
  { name: 'refresh', src },
  { name: 'filter', src },
  { name: 'check-double', src },
  { name: 'enabled-filter', src },
  { name: 'attachment', src },
  { name: 'info', src },
  { name: 'deliver', src },
  { name: 'attachment-download', src},
  { name: 'revert', src},
  { name: 'person-header', src},
  { name: 'toggles', src },
  { name: 'close-circle', src },
  { name: 'alert-exclamation', src}
];
