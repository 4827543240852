<div class="no-data__text" *ngIf="entities.length === 0">
  <ng-container *ngIf="loading; else noDataTmpl">Загрузка...</ng-container>
  <ng-template #noDataTmpl>
    <ng-container *ngIf="!!queryKey">
      По вашему запросу <span>«{{ queryKey }}»</span> ничего не найдено, попробуйте ввести другое значение
    </ng-container>

    <ng-container *ngIf="!queryKey">
      По вашему запросу ничего не найдено
    </ng-container>
  </ng-template>
</div>
