import { ChangeDetectionStrategy, Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UIButtonType } from '@ui/shared/interfaces/button.type';
import { ISelectOption } from '@ui/components/mat-form-components/shared/select-option.interface';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownButtonComponent),
    },
  ],
})
export class DropdownButtonComponent implements ControlValueAccessor {
  @Input() name = 'Кнопка';
  @Input() type: UIButtonType = 'stroked';
  @Input() disabled = false;
  @Input() options: ISelectOption[] = [];

  registerOnChange(fn: () => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  optionSelect(option: number | string) {
    this.writeValue(option);
    this.onTouched();
  }

  buttonClicked() {
    this.writeValue('main');
    this.onTouched();
  }

  writeValue(value: number | string) {
    this.onChange(value);
  }

  onChange: any = () => {};

  onTouched: any = () => {};
}
