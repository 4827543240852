import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateRangeDatepickerContentComponent } from '@ui/modules/mat-daterange-picker/components/date-range-datepicker-content/date-range-datepicker-content.component';
import { MatDaterangePickerComponent } from '@ui/modules/mat-daterange-picker/components/mat-daterange-picker/mat-daterange-picker.component';
import { DateRangeDatepickerOverlayService } from '@ui/modules/mat-daterange-picker/components/date-range-datepicker-overlay/date-range-datepicker-overlay.service';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
  declarations: [
    DateRangeDatepickerContentComponent,
    MatDaterangePickerComponent,
  ],
  imports: [CommonModule, MatDatepickerModule, MatMomentDateModule],
  providers: [DateRangeDatepickerOverlayService],
  exports: [MatDaterangePickerComponent],
})
export class MatDaterangePickerModule {}
