import { Injectable } from '@angular/core';
import { NotificationsListState } from '@ui/modules/notifications-list-popup/notifications-list.state';
import { NotificationsListService } from '@core/shared/services/notifications-list.service';
import { UserNotificationsListQueryDto } from '@core/shared/dto/user-notifications-list-query.dto';
import { map, tap } from 'rxjs/operators';
import { TableResponseDto } from '@core/shared/dto/table-response.dto';
import {
  INotificationsListItem,
  NotificationStatusEnum,
} from '@ui/modules/notifications-list-popup/notifications-list-content/notifications-list-item/shared/notifications-list-list-item.interface';
import { NotificationsCountQueryDto } from '@core/shared/dto/notifications-count-query.dto';
import { NotificationStatusUpdateDto } from '@core/shared/dto/notification-status-update.dto';
import { AuthService } from '@app/@core/modules/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsListFacade {
  constructor(
    private notificationsListService: NotificationsListService,
    private notificationsListState: NotificationsListState,
    private authService: AuthService,
  ) {}

  public loadLastNotifications() {
    const dto = new UserNotificationsListQueryDto();
    dto.limit = 20;
    dto.setSort({
      active: 'created_on',
      direction: 'desc',
    });

    this.notificationsListService
      .getUserNotifications(this.authService.currentUser.id, dto)
      .pipe(map((response: TableResponseDto<INotificationsListItem>) => response.data))
      .subscribe((res) => {
        this.notificationsListState.setLastNotifications(res);
      });
  }

  public loadUnreadNotificationsCount() {
    const dto = new NotificationsCountQueryDto();
    dto.notificationsStatus = NotificationStatusEnum.SENT;

    this.notificationsListService.getUserNotificationsCount(this.authService.currentUser.id, dto).subscribe((res) => {
      this.notificationsListState.setUnreadNotificationsCount(res.count);
    });
  }

  public getLastNotifications() {
    return this.notificationsListState.getLastNotifications();
  }

  public getUnreadNotificationsCount() {
    return this.notificationsListState.getUnreadNotificationsCount();
  }

  public getNotifications() {
    return this.notificationsListState.getNotifications();
  }

  public updateUserNotificationStatus(
    userId: number,
    notificationId: number,
    newStatus: NotificationStatusEnum,
  ): Promise<void> {
    const dto = new NotificationStatusUpdateDto();
    dto.notificationsStatus = newStatus;

    return new Promise((resolve) => {
      this.notificationsListService.updateUserNotificationStatus(userId, notificationId, dto).subscribe(() => {
        this.loadUnreadNotificationsCount();
        resolve();
      });
    });
  }

  public loadNotifications(userId: number, dto: UserNotificationsListQueryDto) {
    this.notificationsListService.getUserNotifications(userId, dto).subscribe((data) => {
      this.notificationsListState.setNotifications(data);
    });
  }

  public readAllNotifications(userId: number) {
    return this.notificationsListService.readAllNotifications(userId)
      .pipe(
        tap(() => this.notificationsListState.setUnreadNotificationsCount(0)),
      );
  }
}
