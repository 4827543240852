import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPopup, PopupModuleConfiguration, PopupTypeEnum } from './popup.interface';

@Injectable({
  providedIn: 'root'
})
export class PopupNotificationsService {

  private readonly timeout: number = 6000;

  private notifications$ = new BehaviorSubject<IPopup[]>([]);

  get notifications() {
    return this.notifications$;
  }

  constructor(@Optional() private options: PopupModuleConfiguration) {
    if (!options) {
      throw new Error('No ZNotificationOptions were received.');
    }

    this.timeout = options.timeout === undefined ? this.timeout : options.timeout;
  }

  public info(text: string, title?: string, timeout = this.timeout) {
    this.create({text, title, timeout, type: PopupTypeEnum.Info});
  }

  public error(text: string, title?: string, timeout = this.timeout) {
    this.create({text, title, timeout, type: PopupTypeEnum.Error});
  }

  public warning(text: string, title?: string, timeout = this.timeout) {
    this.create({text, title, timeout, type: PopupTypeEnum.Warning});
  }

  public success(text: string, title?: string, timeout = this.timeout) {
    this.create({text, title, timeout, type: PopupTypeEnum.Success});
  }

  private create(notification: IPopup) {
    const item = this.compose(notification);
    this.push(item);
  }

  public delete(notification: IPopup) {
    return this.pull(notification);
  }

  private push(notification: IPopup) {
    const list = this.notifications$.getValue();

    list.unshift(notification);

    this.notifications$.next(list);
  }

  private pull(notification: IPopup) {
    let list = this.notifications$.getValue();

    list = list.filter(item => item.id !== notification.id);

    this.notifications$.next(list);
  }

  private compose(notification: IPopup) {
    notification.id = Math.floor(Math.random() * 1000 * 1000 * 5000);
    notification.title = notification.title || '';
    notification.timeout = notification.timeout !== undefined ? notification.timeout : this.timeout;

    return notification;
  }
}
