import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WEB_API_CONFIG } from '../../web-api.config';
import { IResultRequest } from '../interfaces/request.params.builder.interface';
import { IWebApiConfigModule } from '../interfaces/web.api.config.interface';
import { REQUEST_BUILDER, RequestBuilder } from '../models/request-builder.service';
import { WebApiClient } from './web-api-client.service';
import { TypeResponse } from '@core/web-api/shared/enum/response.type';

@Injectable()
export class WebApiService {
  private headers: HttpHeaders;

  constructor(
    @Inject(REQUEST_BUILDER) private builder: RequestBuilder,
    @Inject(WEB_API_CONFIG) private config: IWebApiConfigModule,
    private apiClient: WebApiClient,
  ) {
    this.headers = new HttpHeaders(config.headers);
  }

  public get<Response>(
    url: string,
    params?: Record<string, any>,
    type?: TypeResponse,
    needProgress?: boolean,
    headers: HttpHeaders = this.headers,
  ): Observable<Response> {
    const requestBuilder = this.builder
      .init()
      .setHeaders(headers)
      .setMethod('get')
      .setWithCredentials(true)
      .setURL(this.preparedUrl(url))
      .createAndSetHttpParams(params)
      .setResponseType(type);
    if (needProgress && needProgress) {
      requestBuilder.setReportProgress(needProgress).setObserve('events');
    }
    const request = requestBuilder.build();
    return this.makeRequest(request);
  }

  public delete<Body, Response>(url: string, body?: Body): Observable<Response> {
    const request = this.builder
      .init()
      .setHeaders(this.headers)
      .setMethod('delete')
      .setURL(this.preparedUrl(url))
      .setBody(body)
      .build();
    return this.makeRequest(request);
  }

  public put<Body, Response>(url: string, body?: Body, params?: Record<string, any>): Observable<Response> {
    const request = this.builder
      .init()
      .setHeaders(this.headers)
      .setMethod('put')
      .setURL(this.preparedUrl(url))
      .setBody(body)
      .createAndSetHttpParams(params)
      .build();
    return this.makeRequest(request);
  }

  public post<Body, Response>(
    url: string,
    body?: Body,
    needFormData?: boolean,
    needProgress?: boolean,
  ): Observable<Response> {
    const requestBilder = this.builder
      .init()
      .setHeaders(this.headers, needFormData)
      .setMethod('post')
      .setWithCredentials(true)
      .setURL(this.preparedUrl(url))
      .setBody(body);
    if (needProgress && needProgress) {
      requestBilder.setReportProgress(needProgress).setObserve('events');
    }
    const request = requestBilder.build();
    return this.makeRequest(request);
  }

  public patch<Body, Response>(url: string, body?: Body, needFormData?: boolean): Observable<Response> {
    const request = this.builder
      .init()
      .setHeaders(this.headers, needFormData)
      .setMethod('patch')
      .setWithCredentials(true)
      .setURL(this.preparedUrl(url))
      .setBody(body)
      .build();
    return this.makeRequest(request);
  }

  public buildRequest(url: string, params?: Record<string, any>): IResultRequest {
    return this.builder
      .init()
      .setHeaders(this.headers)
      .setMethod('get')
      .setWithCredentials(true)
      .setURL(this.preparedUrl(url))
      .createAndSetHttpParams(params)
      .build();
  }

  public getParamsString(params?: Record<string, any>) {
    return new HttpParams({
      fromObject: params,
    }).toString();
  }

  private preparedUrl(url: string): string {
    return url.startsWith('http') ? url : this.config.baseUrl + url;
  }

  private makeRequest<Response>(request: IResultRequest) {
    return this.apiClient.request(request.method, request.url, request.options);
  }
}
