import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { DateRangeDatepickerOverlayRef } from '@ui/modules/mat-daterange-picker/components/date-range-datepicker-overlay/date-range-datepicker-overlay.ref';
import { MatDaterangePickerComponent } from '@ui/modules/mat-daterange-picker/components/mat-daterange-picker/mat-daterange-picker.component';
import { DateRangeDatepickerContentComponent } from '@ui/modules/mat-daterange-picker/components/date-range-datepicker-content/date-range-datepicker-content.component';
import { MatDaterangePickerModule } from '@ui/modules/mat-daterange-picker/mat-daterange-picker.module';

@Injectable()
export class DateRangeDatepickerOverlayService {
  private lastCreatedDialogRef!: DateRangeDatepickerOverlayRef | null;

  constructor(private overlay: Overlay, private injector: Injector) {}

  private createInjector(dialogRef: DateRangeDatepickerOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(DateRangeDatepickerOverlayRef, dialogRef);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private createOverlay(element: ElementRef): OverlayRef {
    return this.overlay.create({
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition({ autoClose: true, scrollThrottle: 0 }),
      width: '600px',
      height: '305px',
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
      panelClass: 'mat-datepicker-popup',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(element)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ])
        .withDefaultOffsetX(95)
        .withDefaultOffsetY(219)
        .withViewportMargin(8)
        .withLockedPosition()
        .withFlexibleDimensions(false),
    });
  }

  private attachContainer(
    overlayRef: OverlayRef,
    dialogRef: DateRangeDatepickerOverlayRef,
    matDateRangePickerComponent: MatDaterangePickerComponent,
  ) {
    const injector = this.createInjector(dialogRef);

    const containerPortal = new ComponentPortal(DateRangeDatepickerContentComponent, null, injector);
    const containerRef: ComponentRef<DateRangeDatepickerContentComponent> = overlayRef.attach(containerPortal);
    containerRef.instance.datePicker = matDateRangePickerComponent;

    return containerRef.instance;
  }

  public open(
    element: ElementRef,
    matDatRangePickerComponent: MatDaterangePickerComponent,
  ): DateRangeDatepickerOverlayRef {
    if (this.lastCreatedDialogRef) {
      this.lastCreatedDialogRef.close();
      this.lastCreatedDialogRef = null;
    }

    const overlayRef = this.createOverlay(element);
    const dialogRef = new DateRangeDatepickerOverlayRef(overlayRef);

    this.attachContainer(overlayRef, dialogRef, matDatRangePickerComponent);

    this.lastCreatedDialogRef = dialogRef;

    overlayRef.backdropClick().subscribe(() => {
      if (this.lastCreatedDialogRef) {
        this.lastCreatedDialogRef.close();
      }
    });

    return dialogRef;
  }
}
