import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { OrderStatusArrayItem, OrderStatusesArray } from '@core/shared/interfaces/order.interface';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStatusComponent implements OnInit {
  public statuses?: [OrderStatusArrayItem, OrderStatusArrayItem, OrderStatusArrayItem];

  @Input() set statusArray(statuses: OrderStatusesArray) {
    this.statuses = statuses.slice(0, 3) as any;
  }

  ngOnInit() {}
}
