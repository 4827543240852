import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { PopupNotificationsService } from '@core/modules/popups/popup-notifications.service';

const MAT_SNACKBAR_CONFIG: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'top',
};

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(private readonly popupNotificationsService: PopupNotificationsService) {}

  error(text: string): void {
    this.popupNotificationsService.error(text);
  }

  success(text: string): void {
    this.popupNotificationsService.success(text);
  }

  warning(text: string): void {
    this.popupNotificationsService.warning(text);
  }

  info(text: string): void {
    this.popupNotificationsService.info(text);
  }
}
