<div class="select-widget">
  <label class="select-widget__label __product-count-on-page">
    Показывать на странице
  </label>
  <div>
    <app-mat-select
      [formControl]="countFormControl"
      [options]="countsOnPage"
      [selectedOption]="defaultValue"
      [title]="title"
    ></app-mat-select>
  </div>
</div>
