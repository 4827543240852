import { IAppEnvironment } from '@core/shared/interfaces/app-environment';
import { DYNAMIC_ENVIRONMENT } from '@environments/dynamic-environment';

export const environment: IAppEnvironment = {
  production: false,
  WEB_API_URL: 'https://api.dev.liri.winsolutions.ru/api/v1/',
  STS_SERVER: 'https://auth.dev.liri.winsolutions.ru',
  STATOUT: 'https://statout.dev.sveta.winsolutions.ru',
  REQUIRE_HTTPS: false,
  REDIRECT_URL: 'https://front.dev.liri.winsolutions.ru',
  SESSION_CHECKS_ENABLED: true,
  SILENT_RENEW_URL: 'https://front.dev.liri.winsolutions.ru/silent-renew.html',
  CLIENT_ID: 'client',
  RESPONSE_TYPE: 'id_token token',
  SCOPE: 'openid api1',
  CONFIRM_USER_URL: 'https://stage.id.vtbconnect.ru/',
  ASSIGN_USER_TO_COMPANY_URL: 'https://stage.lk.xn--80anehawgc9a.xn--p1ai/Account/RegisterSveta',
  GOOGLE_TAG_MANAGER_CLIENT_ID: DYNAMIC_ENVIRONMENT.GOOGLE_TAG_MANAGER_CLIENT_ID || 'GTM-TK8VXJC',
  version: require('../../package.json').version,
  GUIDE: true,
};
