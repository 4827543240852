<div class="wrapper">
  <div class="list">
    <perfect-scrollbar>
      <div class="item" *ngFor="let notification of notifications$ | async">
        <app-notifications-list-item [notification]="notification" (read)="onNotificationRead(notification)">
        </app-notifications-list-item>
      </div>
    </perfect-scrollbar>
  </div>

  <div class="footer">
    <a class="all-notifications-link" [routerLink]="['/', 'user', 'notifications']">
      Все уведомления <mat-icon svgIcon="arrow-circle-right" class="__primary-blue"></mat-icon>
    </a>
  </div>
</div>
