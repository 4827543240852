import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Self,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { CartService } from '@core/shared/services/cart.service';
import { IOrder } from '@core/shared/interfaces/order.interface';
import { Router } from '@angular/router';
import { ISelectOption } from '@ui/components/mat-form-components/shared/select-option.interface';
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IDeliveryType } from '@core/shared/interfaces/delivery-type.interface';
import { OrdersService } from '@core/shared/services/orders.service';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { map, takeUntil } from 'rxjs/operators';
import { PopupService } from '@ui/shared/services/popup.service';
import { DepartmentsService } from '@core/shared/services/departments.service';
import { IDepartment } from '@app/pages/settings/departments/containers/department-table/shared/interface/department.interface';

@Component({
  selector: 'app-order-view-info',
  templateUrl: './order-view-info.component.html',
  styleUrls: ['./order-view-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ NgOnDestroy ],
})
export class OrderViewInfoComponent implements OnInit {
  @Input() public order: IOrder = null;
  @Input() public viewMode: 'view' | 'edit';
  @Input() public form: UntypedFormGroup;
  public departmentOptions: ISelectOption[];
  public showDeliveryTypeSelector: boolean;

  constructor(
    fb: UntypedFormBuilder,
    private readonly cartService: CartService,
    private readonly ordersService: OrdersService,
    private readonly departmentService: DepartmentsService,
    @Self() private readonly componentDestroyed$: NgOnDestroy,
    private readonly snackbarService: PopupService,
    private readonly cd: ChangeDetectorRef,
    private readonly router: Router,
  ) {
    this.createForm(fb);
  }

  private createForm(fb: UntypedFormBuilder): void {
    this.form = fb.group({
      deliveryTypeId: [ 0, Validators.required],
    });
  }

  private setDepartmentOptions(departmentList: IDepartment[]): void {
    this.departmentOptions = departmentList.map((department: IDepartment) => {
      return { value: department.id, title: department.name };
    });
  }

  private initInfo(): void {
    this.departmentService.getDepartments()
      .pipe(map(data => data.data))
      .subscribe((departments: IDepartment[]) => {
        this.setDepartmentOptions(departments);
        this.cd.detectChanges();
      });
  }

  public ngOnInit(): void {
    this.initInfo();
  }

  public setCartDepartment(id: number) {
    this.cartService.setSelectedDepartmentID(id);
  }

  public navigateToShipment() {
    this.router.navigate(['/', 'shipments', this.order.childrenId]);
  }
}
