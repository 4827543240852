import { IHeader } from '@ui/components/header/shared/interfaces/header.interface';

export const HEADER_ITEMS_ANALYTICS: IHeader[] = [
  {
    label: 'Матрица',
    path: '/analytics/matrix',
  },
  {
    label: 'Склад',
    path: '/analytics/warehouse',
  },
  {
    label: 'Финансы',
    path: '/analytics/finance',
  }
];
