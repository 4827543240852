import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/@core/core.module';
import { SharedModule } from '@app/@core/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { ErrorHandlerService } from '@core/shared/services/error-routing/error-handler.service';
import { ErrorRoutingServiceInitializer } from '@core/shared/services/error-routing/error-routing.initializer';
import { CustomMatIconInitializer } from '@core/shared/services/custom-mat-icons/custom-mat-icons.initializer';
import { FaderComponent } from '@ui/components/fader/fader.component';
import { UiComponentsModule } from '@ui/components/ui-components.module';
import { environment } from '@environments/environment';
import { JoyrideModule } from 'ngx-joyride';
import { WebsocketService } from '@core/shared/services/websocket/websocket.service';
import { HttpClientModule } from '@angular/common/http';
import { MatIconRegistryService } from '@core/shared/services/custom-mat-icons/mat-icon-registry.service';
import { PopupsModule } from '@core/modules/popups/popups.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TIMEPICKER_THEME, timePickerTheme } from '@core/shared/constants/timepicker-theme';
import { LiriDialogModule } from './ui/modules/liri-dialog';
// import { GoogleTagManagerService } from 'angular-google-tag-manager';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

registerLocaleData(localeRu, 'ru');

@NgModule({
  imports: [
    CoreModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    PerfectScrollbarModule,
    MatSnackBarModule,
    MatDialogModule,
    UiComponentsModule,
    HttpClientModule,
    JoyrideModule.forRoot(),
    PopupsModule.forRoot({
      timeout: 5000
    }),
    NgxMaterialTimepickerModule,
    LiriDialogModule,
  ],
  declarations: [AppComponent],
  providers: [
    // GoogleTagManagerService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.GOOGLE_TAG_MANAGER_CLIENT_ID,
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },
    {
      provide: TIMEPICKER_THEME,
      useValue: timePickerTheme
    },
    WebsocketService,
    ErrorHandlerService,
    // MatIconRegistryService,

    // Initializers
    // WebsocketInitializer,
    ErrorRoutingServiceInitializer,
    CustomMatIconInitializer,
  ],
  bootstrap: [AppComponent],
  exports: [
    FaderComponent,
  ],
})
export class AppModule {
  constructor() {}
}
