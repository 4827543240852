import { Injectable } from '@angular/core';
import { IOrderItem} from '@core/shared/interfaces/order.interface';
import { IShipmentPosition } from '@app/pages/shipments-view/shared/interfaces/shipment-position';

@Injectable()
export class OrderShipmentViewTableService {
  public isQuantityNotEqualsLinkedMovementQuantity(item: IOrderItem | IShipmentPosition): boolean {
    return item.quantity !== item.linkedMovementQuantity;
  }

  public getLinkedMovementQuantityDelta(item: IOrderItem | IShipmentPosition) {
    return item.linkedMovementQuantity - item.quantity;
  }

  public getProductTotalSum(item: IOrderItem | IShipmentPosition) {
    return item.price * item.quantity;
  }
}
