import { Directive } from '@angular/core';

@Directive({
  selector: '[dialog-title]'
})
export class DialogTitleDirective {

  constructor() { }

}
