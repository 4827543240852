import { Sort } from '@angular/material/sort';

export class DefaultTableQueryDto {
  page?: number;
  limit?: number;
  filter?: string;
  sort?: string;

  public setSort(matSort: Sort) {
    this.sort = this.buildSortString(matSort);
  }

  private buildSortString(matSort: Sort): string {
    let sortString = matSort.active;

    if (matSort.direction === 'desc') {
      sortString += '|Desc';
    }

    return sortString;
  }
}
