import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { UUIDService } from '@core/shared/services/uuid.service';
import { AppVersionService } from '@core/shared/services/app-version.service';
import { StorageService } from '@app/@core/shared/services/storage.service';
import { LocalStorageKeys } from '@app/@core/shared/enums/local-storage-keys.enum';
import { ITokenResponse } from '@app/@core/shared/interfaces';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private oAuthService: OAuthService,
    private uuidService: UUIDService,
    private readonly storage: StorageService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storage.getItem<ITokenResponse>(LocalStorageKeys.token);

    if (token?.access_token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token.access_token,
        },
      });
    }

    if (this.uuidService.isAnonymousUUIDExists()) {
      request = request.clone({
        setHeaders: {
          ['Anonymous-UUID']: this.uuidService.getAnonymousUUID()
        }
      });
    }

    return next.handle(request);
  }
}
