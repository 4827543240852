import { IOrder, IOrderAction, OrderActionEnum } from '@core/shared/interfaces/order.interface';
import { EventEmitter, Injectable } from '@angular/core';
import { OrderActionsService } from '@core/shared/services/order-actions-services/order-actions.service';
import { OrderActionsHandlerService } from '@core/shared/services/order-actions-services/order-actions-handler.service';

@Injectable()
export class OrderActionsMapperService {
  public onActionDone: EventEmitter<IOrderAction<OrderActionEnum>>;

  constructor(
    private readonly orderActionsService: OrderActionsService,
    public readonly orderActionsHandlerService: OrderActionsHandlerService
  ) {

  }

  mapAction(action: IOrderAction<OrderActionEnum>, entity: IOrder) {
    switch (action.code) {
      case OrderActionEnum.CSV: {
        this.orderActionsService.download(entity).subscribe();
        break;
      }

      case OrderActionEnum.REJECT: {
        this.orderActionsHandlerService.openRejectDialog(action, entity);
        break;
      }

      case OrderActionEnum.CONFIRM: {
        this.orderActionsService.confirm(entity).subscribe(() => this.onActionDone.emit(action));
        break;
      }

      case OrderActionEnum.CLONE: {
        this.orderActionsService.clone(entity).subscribe(() => this.onActionDone.emit(action));
        break;
      }

      case OrderActionEnum.EDIT: {
        this.onActionDone.emit(action);
        break;
      }

      case OrderActionEnum.DELETE: {
        this.orderActionsService.delete(entity).subscribe(() => this.onActionDone.emit(action));
        break;
      }

      case OrderActionEnum.SEND: {
        this.orderActionsService.send(entity).subscribe(() => this.onActionDone.emit(action));
        break;
      }

      case OrderActionEnum.SIMPLE_ORDER_PDF: {
        this.orderActionsService.downloadAsPdf(entity).subscribe();
        break;
      }

      case OrderActionEnum.XLSX: {
        this.orderActionsService.downloadAsXlsx(entity).subscribe();
        break;
      }

      case OrderActionEnum.REVERT_ORDER: {
        this.orderActionsHandlerService.onRevertActionClicked(action, entity);
        break;
      }
    }
  }
}
