import { IOrder, IOrderAction, OrderActionEnum } from '@core/shared/interfaces/order.interface';
import { ISimpleTextAreaDialogData } from '@ui/components/simple-textarea-dialog/shared/simple-text-area-dialog.data';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ISimpleTextAreaDialogOutputData } from '@ui/components/simple-textarea-dialog/shared/simple-text-area-dialog-output.data';
import { NEVER } from 'rxjs';
import { SimpleTextareaDialogComponent } from '@ui/components/simple-textarea-dialog/simple-textarea-dialog.component';
import { EventEmitter, Injectable, Self } from '@angular/core';
import { BaseActionsService } from '@core/shared/services/order-actions-services/base-actions.service';
import { OrderActionsService } from '@core/shared/services/order-actions-services/order-actions.service';
import { ShipmentActionsService } from '@core/shared/services/order-actions-services/shipment-actions.service';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class OrderActionsHandlerService {
  public onActionDone: EventEmitter<IOrderAction>;

  constructor(
    public baseActionsService: BaseActionsService,
    public orderActionsService: OrderActionsService,
    public shipmentActionsService: ShipmentActionsService,
    @Self() public componentDestroyed$: NgOnDestroy,
    public dialog: MatDialog
  ) {

  }

  public openRejectDialog(action: IOrderAction, entity: IOrder) {
    const dialogData: ISimpleTextAreaDialogData = {
      header: 'Отмена заказа',
      textAreaLabel: 'Укажите причину отказа',
    };

    this.baseActionsService
      .openRejectDialog(entity, dialogData)
      .pipe(
        switchMap((result: ISimpleTextAreaDialogOutputData) => {
          if (result && result.result && result.text) {
            return this.orderActionsService.reject(entity, result.text);
          }
          return NEVER;
        }),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(() => this.onActionDone.emit(action));
  }

  public onRevertActionClicked(action: IOrderAction<OrderActionEnum>, entity: IOrder) {
    this.openRevertCommentDialog(action)
      .pipe(
        filter(data => data.result)
      )
      .subscribe((outputData) => this.onRevertConfirmed(outputData, action, entity));
  }

  public openRevertCommentDialog(action: IOrderAction<OrderActionEnum>) {
    const dialogData: ISimpleTextAreaDialogData = {
      header: 'Откат заявки',
      textAreaLabel: 'Введите причину отката заявки'
    };

    return this.dialog.open(SimpleTextareaDialogComponent, {
      width: '600px',
      panelClass: ['common-dialog', 'mat-full-dialog'],
      data: dialogData
    }).afterClosed();
  }

  public onRevertConfirmed(userInput: ISimpleTextAreaDialogOutputData, action: IOrderAction<OrderActionEnum>, entity: IOrder) {
    this.orderActionsService.revert(entity, action, userInput.text).subscribe(() => this.onActionDone.emit(action));
  }
}
