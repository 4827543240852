import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IApiError } from '@core/web-api/shared/interfaces/api.error.interface';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@core/shared/services/error-routing/error-handler.service';
import { AuthService } from '@app/@core/modules/auth/auth.service';

const UNAUTHORIZE = 401;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorRouter: ErrorHandlerService, private auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        const apiError: IApiError = {
          Code: error.error ? error.error.code : null,
          Error: error.error ? error.error.error : null,
          InnerError: error.error ? error.error.innerError : null,
          InnerStackTrace:  error.error ? error.error.innerStackTrace : null,
          StackTrace: error.error ? error.error.stackTrace : null,
          Status: error.status
        };

        if (apiError.Status === UNAUTHORIZE) {
          this.auth.loadDiscoveryDocumentAndInitLogin();
          return;
        }

        this.errorRouter.error.next(apiError);

        return throwError(apiError);
      }),
    );
  }
}
