import {NgModule} from '@angular/core';
import {UserCanDirective} from '@ui/directives/user-can.directive';
import { AdaptiveDirective } from './adaptive.directive';
import { PreventClickPropagationDirective } from './prevent-click-propagation.directive';
import { UserCanInteractDirective } from '@ui/directives/user-can-interact.directive';

const DIRECTIVES = [
  UserCanDirective,
  AdaptiveDirective,
  PreventClickPropagationDirective,
  UserCanInteractDirective
];

@NgModule({
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES],
})
export class UiDirectivesModule {}
