import { Injectable } from '@angular/core';
import { IBaseTableRequest } from '@core/shared/interfaces/base-table-request.interface';
import { ICartItem } from '@core/shared/interfaces/cart-item.interface';
import { ICollectionWithPagination } from '@core/shared/interfaces/collection-with-pagination.interface';
import { IOrder, IOrderHistory, IOrderItem, IOrderMovementStatus } from '@core/shared/interfaces/order.interface';
import { showSuccessMessage } from '@core/shared/rxjs-operators/show-success-message';
import { TypeResponse } from '@core/web-api/shared/enum/response.type';
import { WebApiService } from '@core/web-api/shared/services/web-api.service';
import { PopupService } from '@ui/shared/services/popup.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IOrderCloneDto } from '@core/shared/dto/order-clone.dto';
import { OrdersQueryDto } from '@core/shared/dto/orders-query.dto';
import { IAnonymousCacheResponse } from '@app/pages/anonymous-cart-movement/containers/shared/anonymous-cache.interface';
import { IBaseProduct } from '../interfaces/product-card.interface';
import { DefaultTableQueryDto } from '@core/shared/dto/default-table-query.dto';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(
    private apiService: WebApiService,
    private snackbar: PopupService,
  ) {}

  getOrderDraft(departmentId: number): Observable<IOrder> {
    return this.apiService
      .get<IOrder>(`Orders/Draft`, {
        departmentId,
      })
      .pipe(catchError(() => this.createEmptyOrder(departmentId)));
  }

  createEmptyOrder(departmentId: number): Observable<IOrder> {
    return this.apiService.post<{ departmentId: number }, IOrder>(`Orders`, { departmentId });
  }

  updateOrderItemQuantity(orderId: number, itemId: number, quantity: number, isShowSuccessMessage = true): Observable<number> {
    return this.apiService
      .put(`Orders/${orderId}/Items/${itemId}`, {
        quantity,
      })
      .pipe(
        // Да простит меня господь
        isShowSuccessMessage ? showSuccessMessage(this.snackbar, 'Количество товара изменено') : tap(() => null),
        map(() => quantity),
      );
  }

  addItemToOrder(orderId: number, cartItemId: number, quantity: number): Observable<ICartItem> {
    if (!orderId) {
      return;
    }
    const body = { goodId: cartItemId, quantity };
    return this.apiService.post<{ goodId: number; quantity: number }, ICartItem>(`Orders/${orderId}/Items`, body);
  }

  deleteItemFromOrder(orderId: number, itemId: number): Observable<void> {
    return this.apiService.delete<void, void>(`Orders/${orderId}/Items/${itemId}`);
  }

  getCartItems(orderId: number, params?: IBaseTableRequest): Observable<ICollectionWithPagination<ICartItem>> {
    return this.apiService.get<ICollectionWithPagination<ICartItem>>(`Orders/${orderId}/Items/`, params);
  }

  getOrderItems(orderId: number, dto?: IBaseTableRequest): Observable<ICollectionWithPagination<IOrderItem>> {
    return this.apiService.get(`Orders/${orderId}/Items/`, dto);
  }

  getOrders(dto: OrdersQueryDto): Observable<ICollectionWithPagination<IOrder>> {
    return this.apiService.get<ICollectionWithPagination<IOrder>>('Orders', dto);
  }

  public getOrderById(id: number): Observable<IOrder> {
    return this.apiService.get<IOrder>(`orders/${id}`);
  }

  sendOrder(id: number): Observable<void> {
    return this.apiService
      .post<void, void>(`Orders/${id}/Send`);
  }

  cloneOrder(id: number, dto: IOrderCloneDto): Observable<IOrder> {
    return this.apiService
      .post<IOrderCloneDto, IOrder>(`Orders/${id}/Clone`, dto)
      .pipe(showSuccessMessage(this.snackbar, 'Заказ успешно клонирован'));
  }

  reject(id: number, comment: string) {
    return this.apiService.post(`Orders/${id}/Reject`, { comment });
  }

  deleteOrder(id: number): Observable<void> {
    return this.apiService
      .delete<void, void>(`Orders/${id}`)
      .pipe(showSuccessMessage(this.snackbar, 'Заказ успешно удален'));
  }

  downloadOrderCSV(id: number): Observable<Blob> {
    return this.apiService.get(`Orders/${id}/DownloadOrderToFile`, {}, TypeResponse.blob);
  }

  getOrdersCount(): Observable<number> {
    return this.apiService.get(`Orders/Count`);
  }

  confirmOrder(id: number): Observable<void> {
    return this.apiService
      .post<void, void>(`Orders/${id}/Confirm`)
      .pipe(showSuccessMessage(this.snackbar, 'Заказ отправлен на отработку'));
  }

  downloadOrderAsPDF(id: number): Observable<Blob> {
    return this.apiService.get(`Orders/${id}/Print`, {}, TypeResponse.blob);
  }

  getAnonymousCache(): Observable<IAnonymousCacheResponse> {
    return this.apiService.get('Orders/GetAnonymousCache');
  }

  addAnonymousGoodToOrder(anonymousCacheId: number, goodId: number, departmentId: number) {
    return this.apiService.post(`Orders/${anonymousCacheId}/AddAnonymousGoodToOrder/${goodId}`, {
      departmentId
    });
  }

  clearAnonymousCache(anonymousCacheId: number) {
    return this.apiService.post(`Orders/clearAnonymousCache/${anonymousCacheId}`);
  }

  public downloadOrderAsXlsx(orderId: number): Observable<Blob> {
    return this.apiService.get(`Orders/${orderId}/DownloadOrderToFile?filetype=excel`, {}, TypeResponse.blob);
  }

  public updateDeliveryType(order: IOrder): Observable<any> {
    return this.apiService.patch(`Orders/${order.id}/DeliveryType`, { deliveryTypeId: order.deliveryType.id });
  }

  private validateGoodQuantity(quantity: number, good: IBaseProduct): {
    fixed: boolean,
    value: number
  } {
    if (quantity < good.minQuantity) {
      this.snackbar.info('Указано количество товара меньше минимальной партии. Количество товара изменено.');
      return {
        fixed: true,
        value: Math.max(good.minQuantity, good.pickingQuantum)
      };
    } else if (quantity % good.pickingQuantum !== 0) {
      this.snackbar.info('Указано количество товара некратное шагу. Количество товара изменено.');
      return {
        fixed: true,
        value: Math.ceil(quantity / good.pickingQuantum) * good.pickingQuantum
      };
    }
    return {
      fixed: false,
      value: quantity
    }
  }

  public updateItemQuantity(orderId: number, cartItem: ICartItem, quantity: number): Observable<number> {
    const validateResult = this.validateGoodQuantity(quantity, cartItem.good)
    if(validateResult.value !== quantity) {
      return of(validateResult.value);
    }
    return this.updateOrderItemQuantity(orderId, cartItem.id, validateResult.value, validateResult.fixed)
      .pipe(
        tap(() => this.snackbar.info('Количество товара изменено')),
      );
  }

  public getOrderHistory(orderId: number, dto?: DefaultTableQueryDto): Observable<ICollectionWithPagination<IOrderHistory>> {
    return this.apiService.get(`Orders/${orderId}/History`,dto);
  }

  public getUniqueStatuses(): Observable<IOrderMovementStatus[]> {
    return this.apiService.get(`Orders/UniqMovementStatus`);
  }

  public downloadOrdersAsXLSX(dto: OrdersQueryDto): Observable<Blob> {
    return this.apiService.get('Orders/ExcelOrders', dto, TypeResponse.blob);
  }

  public makeRevert(orderId: number, revertAction: string, comment: string) {
    return this.apiService.post(`Orders/${orderId}/Revert${revertAction}`, {
      comment
    });
  }

  public addComment(orderId: number, comment: string) {
    return this.apiService.post(`Orders/${orderId}/AddComment`, {
      comment
    });
  }
}
