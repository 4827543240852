import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { PagesService } from '@app/pages/pages.service';
import { IHeader } from '@ui/components/header/shared/interfaces/header.interface';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { RoleAccessEnum } from '@core/shared/enums/role-access.enum';
import { AuthService } from '@app/@core/modules/auth/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { ENVIRONMENT_TOKEN } from '@environments/environment.config';
import { IAppEnvironment } from '@app/@core/shared/interfaces/app-environment';
import { GuideTourService } from '@app/ui/shared/services/guid-tour.service';
import { CatalogService } from '@app/pages/catalog/catalog.service';
import { GlobalErrorService } from '@core/shared/services/global-error.service';
import { HeaderTourStepsEnum } from '@app/@core/shared/enums/header-tour-steps.enum';
import { parseEnvironmentFromUrl } from '@core/shared/utils/parse-environment-from-url';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SignInComponent } from '@app/ui/modules/liri-dialog/sign-in/sign-in.component';
import { ILoginInfo, ITokenResponse } from '@app/@core/shared/interfaces';
import { StorageService } from '@app/@core/shared/services/storage.service';
import { LocalStorageKeys } from '@app/@core/shared/enums/local-storage-keys.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroy],
})
export class HeaderComponent implements OnInit {
  headerItems$!: Observable<IHeader[]>;
  headerItemsList!: IHeader[];
  routerLink!: string;

  public isMenuItemActive$ = new Subject();
  RoleAccessEnum = RoleAccessEnum;
  currentRouteItem!: IHeader;
  @ViewChild('navigationEl') navigationEl: ElementRef;

  constructor(
    private pageService: PagesService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    public authService: AuthService,
    private oAuthService: OAuthService,
    @Self() private componentDestroyed$: NgOnDestroy,
    @Inject(ENVIRONMENT_TOKEN) public environment: IAppEnvironment,
    private guidedService: GuideTourService,
    public globalErrorService: GlobalErrorService,
    private renderer2: Renderer2,
    private readonly dialog: MatDialog,
    private readonly storage: StorageService,
  ) {
  }

  ngOnInit(): void {
    this.globalErrorService.globalFatalErrorHappened$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.cdRef.markForCheck();
      });

    this.getHeaderItems();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((event) => {
        this.getHeaderItems((event as RouterEvent).url);
        this.setCurrentRouteName();
      });

    this.authService.startGuid$
      .asObservable()
      .pipe(
        switchMap((_) =>
          this.guidedService.startTour('_headerTour', [ HeaderTourStepsEnum.wallet ]),
        ),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe();
  }

  onDone() {
    this.guidedService.onTourDone();
  }

  getHeaderItems(pageUrl?: string): void {
    const url = pageUrl ? pageUrl : this.router.url;
    this.routerLink = url;
    this.headerItems$ = this.pageService.getHeaderItems(url).pipe(
      tap((items) => {
        this.headerItemsList = items;
        this.setCurrentRouteName();
      }),
    );
    this.cdRef.detectChanges();
  }

  public login(): void {
    const data: MatDialogConfig = {
      width: '500px'
    };

    const dialogRef = this.dialog.open(SignInComponent, data);

    dialogRef.afterClosed()
      .pipe(
        filter(v => !!v),
        switchMap((loginInfo: ILoginInfo) => this.authService.login(loginInfo)),
        switchMap((token: ITokenResponse) => {
          this.storage.setItem(LocalStorageKeys.token, token);

          return this.authService.loadCurrentUser();
        })
      )
      .subscribe(
        () => {},
        () => {},
      );
  }

  public startAuth() {
    this.authService.loadDiscoveryDocumentAndInitLogin();
  }

  public navigateOnLandingPage(): void {
    const currentEnvironment = parseEnvironmentFromUrl();
    const urlSuffix = currentEnvironment === 'prod' ? '' : currentEnvironment + '.';
    const url = `https://${urlSuffix}запокупки.рф`;
    window.location.href = url;
  }

  public navigateToRegistration() {
    window.location.href = this.environment.STS_SERVER + '/Account/Register';
  }

  public navigateToProfile() {
    this.router.navigate(['/', 'user', 'profile']);
  }

  public logout() {
    this.oAuthService.logOut();
  }

  public setScrollToLeft() {
    this.renderer2.setProperty(this.navigationEl.nativeElement, 'scrollLeft', 0);
  }

  public setScrollToRight() {
    this.renderer2.setProperty(
      this.navigationEl.nativeElement,
      'scrollLeft',
      this.navigationEl.nativeElement.scrollWidth,
    );
  }

  private setCurrentRouteName() {
    const routeNames = this.router.url.split('/');
    const parentUrlIndex = 1;
    const firstChildUrlIndex = 2;
    const parentUrl = `/${routeNames[parentUrlIndex]}/${routeNames[firstChildUrlIndex]}`;
    if (this.headerItemsList) {
      this.currentRouteItem = this.headerItemsList.find((item) => item.path === parentUrl);
      this.cdRef.markForCheck();
    }
  }
}
