import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-link',
  template: `
    <div class="input-wrap">
      <span class="input-label">
        {{ field?.templateOptions?.label }}
      </span>
      <div class="input__control" *ngIf="formControl.valueChanges | async as value">
        <div class="mat-input-custom __linkedVal">
          <a class="input__link" [href]="'mailto:' + value">{{ value }}</a>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailtoComponent extends FieldType {}
