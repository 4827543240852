import { Injectable } from '@angular/core';
import { WebApiService } from '@core/web-api/shared/services/web-api.service';
import { WalletHistoryDto } from '@core/shared/dto/wallet-history.dto';
import { ICurrentUserBalance } from '@core/shared/interfaces/balance.interface';
import { Observable } from 'rxjs';
import { TableResponseDto } from '@core/shared/dto/table-response.dto';
import { IUserBalanceEntry } from '@app/pages/user/balance/containers/user-balance-table/shared/user-balance-entry.interface';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  private root = 'Wallet';

  constructor(private api: WebApiService) {}

  public createTransaction(movementId: number) {
    return this.api.post(`${this.root}`, {
      movementId,
    });
  }

  public checkSmsCode(movementId: number, code: string) {
    return this.api.put(`${this.root}/CheckSms`, {
      code,
      movementId,
    });
  }

  public retrySms(movementId: number) {
    return this.api.put(`${this.root}/Retrysms`, {
      movementId,
    });
  }

  public getHistory(dto: WalletHistoryDto): Observable<TableResponseDto<IUserBalanceEntry>> {
    return this.api.get(`${this.root}/History`, dto);
  }

  public getBalance() {
    return this.api.get<ICurrentUserBalance>(`${this.root}/FreeBalance`);
  }
}
