import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ElementRef,
  ContentChild,
  ChangeDetectorRef,
  Self,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPaymentDialogData } from '@ui/components/payment-dialog/shared/payment-dialog.data';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WalletService } from '@core/shared/services/wallet.service';
import { BehaviorSubject, Observable, timer, NEVER, interval } from 'rxjs';
import { map, switchMap, takeUntil, takeWhile, tap, timeout } from 'rxjs/operators';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { IApiError } from '@core/web-api/shared/interfaces/api.error.interface';
import { WalletErrorEnum } from '@core/web-api/shared/enum/errors/wallet-error-enum';

enum PaymentStepEnum {
  SMS_CODE_CONFIRMING,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
}

enum FormControlErrorEnum {
  BAD_SMS_CODE = 'badSmsCode',
  SESSION_EXPIRED = 'sessionExpired',
}

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroy],
})
export class PaymentDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('smsCodeConfirmStep', { static: true }) smsCodeConfirmStepTemplate!: TemplateRef<any>;
  @ViewChild('paymentSuccessStep') paymentSuccessStepTemplate!: TemplateRef<any>;
  @ViewChild('paymentError') paymentErrorTemplate!: TemplateRef<any>;

  public form: UntypedFormGroup;
  public currentTemplate!: TemplateRef<any>;
  public currentStep: PaymentStepEnum = PaymentStepEnum.SMS_CODE_CONFIRMING;
  public lastFatalErrorMessage: string | null = null;

  public get isBadConfirmCode(): boolean {
    return this.form.get('smsCode')!.hasError(FormControlErrorEnum.BAD_SMS_CODE);
  }

  public get isSessionExpired(): boolean {
    return this.form.get('smsCode')!.hasError(FormControlErrorEnum.SESSION_EXPIRED);
  }

  public smsIsSending = true;
  public smsCoolDown$!: Observable<number>;
  private smsCoolDownSeconds = 120;
  private smsCoolDownToggle$ = new BehaviorSubject<boolean>(true);
  private smsCoolDownEstimated = (v: number | unknown) => this.smsCoolDownSeconds - (v as number);

  constructor(
    private dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPaymentDialogData,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    private walletService: WalletService,
    @Self()
    private componentDestroyed$: NgOnDestroy,
  ) {
    this.form = fb.group({
      smsCode: ['', Validators.required],
    });

    this.dialogRef
      .beforeClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        if (this.currentStep === PaymentStepEnum.PAYMENT_SUCCESS) {
          this.dialogRef.close(true);
        }
      });
  }

  ngOnInit() {
    this.createTransaction();
  }

  ngAfterViewInit(): void {
    this.setCurrentStep(PaymentStepEnum.SMS_CODE_CONFIRMING);
  }

  public close() {
    this.dialogRef.close();
  }

  public createTransaction() {
    this.lastFatalErrorMessage = null;

    this.walletService.createTransaction(this.data.movementId).subscribe(
      (res) => {
        this.smsIsSending = false;
        this.updateSmsCoolDown();
      },
      (err: IApiError) => {
        this.lastFatalErrorMessage = err.Error;
        this.setCurrentStep(PaymentStepEnum.PAYMENT_ERROR);
      },
    );
  }

  public confirmCurrentStep() {
    switch (this.currentStep) {
      case PaymentStepEnum.SMS_CODE_CONFIRMING: {
        this.confirmSmsCode();
        break;
      }

      case PaymentStepEnum.PAYMENT_SUCCESS: {
        this.dialogRef.close(true);
        break;
      }

      case PaymentStepEnum.PAYMENT_ERROR: {
        this.dialogRef.close();
        break;
      }
    }
  }

  public resendSms() {
    this.lastFatalErrorMessage = null;
    this.smsIsSending = true;

    this.walletService.retrySms(this.data.movementId).subscribe(
      (_) => {
        this.smsIsSending = false;
        this.updateSmsCoolDown();
      },
      (err: IApiError) => {
        this.lastFatalErrorMessage = err.Error;
        this.setCurrentStep(PaymentStepEnum.PAYMENT_ERROR);
      },
    );
  }

  private confirmSmsCode() {
    const code = this.form.get('smsCode')!.value;

    this.form.disable({
      emitEvent: false
    });

    this.lastFatalErrorMessage = null;

    this.walletService.checkSmsCode(this.data.movementId, code)
      .subscribe(
      (res) => {
        this.setCurrentStep(PaymentStepEnum.PAYMENT_SUCCESS);
        this.toggleForm(true);
      },
      (err: IApiError) => {
        this.toggleForm(true);

        switch (err.Code) {
          case WalletErrorEnum.NEED_TO_RETRY_SMS: {
            this.setCodeFormControlError(FormControlErrorEnum.SESSION_EXPIRED);
            break;
          }

          case WalletErrorEnum.BAD_SMS_CODE: {
            this.setCodeFormControlError(FormControlErrorEnum.BAD_SMS_CODE);
            break;
          }

          default: {
            this.lastFatalErrorMessage = err.Error;
            this.setCurrentStep(PaymentStepEnum.PAYMENT_ERROR);
            break;
          }
        }
      },
    );
  }

  private setCodeFormControlError(error: FormControlErrorEnum) {
    this.form.get('smsCode')!.setErrors({
      [error]: true,
    });

    this.cdRef.detectChanges();
  }

  private updateSmsCoolDown() {
    this.smsCoolDown$ = this.smsCoolDownToggle$.pipe(
      switchMap((running) => (running ? timer(0, 1000) : NEVER)),
      map(this.smsCoolDownEstimated),
      takeWhile((t) => t >= 0),
    );

    this.cdRef.detectChanges();
  }

  private setCurrentStep(step: PaymentStepEnum) {
    this.currentStep = step;
    this.setTemplateForCurrentStep();
    this.cdRef.detectChanges();
  }

  private setTemplateForCurrentStep() {
    switch (this.currentStep) {
      case PaymentStepEnum.SMS_CODE_CONFIRMING: {
        this.currentTemplate = this.smsCodeConfirmStepTemplate;
        break;
      }

      case PaymentStepEnum.PAYMENT_SUCCESS: {
        this.currentTemplate = this.paymentSuccessStepTemplate;
        break;
      }

      case PaymentStepEnum.PAYMENT_ERROR: {
        this.currentTemplate = this.paymentErrorTemplate;
        break;
      }
    }
  }

  private toggleForm(state: boolean): void {
    if (state) {
      this.form.enable({
        emitEvent: false,
        onlySelf: true
      });
    } else {
      this.form.disable({
        emitEvent: false,
        onlySelf: true
      });
    }
  }
}
