<div class="wrapper">
  <div class="body" [class.no-text]="!notification.text">
    <span class="header" *ngIf="notification.title">
      {{notification.title}}
    </span>
    <p class="text" *ngIf="notification.text">
      {{notification.text}}
    </p>
  </div>

  <div class="close">
    x
  </div>
</div>
