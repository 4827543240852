import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { IOrder, IOrderItem } from '@core/shared/interfaces/order.interface';
import { IShipmentPosition } from '@app/pages/shipments-view/shared/interfaces/shipment-position';
import { OrderShipmentViewTableService } from '@core/shared/services/order-shipment-view-table.service';

@Component({
  selector: 'app-order-position-quantity',
  templateUrl: './order-position-quantity.component.html',
  styleUrls: ['./order-position-quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OrderShipmentViewTableService]
})
export class OrderPositionQuantityComponent implements OnInit, OnChanges {

  @Input() orderWasEdited = false;
  @Input() item: IOrderItem | IShipmentPosition;
  @Input() withPostfix = false;
  @Input() bold = false;
  @Input() type: 'order' | 'shipment' = 'order';

  public isQuantityNotEqualsLinkedMovementQuantity = false;
  public quantityDelta = 0;
  public tooltipText = '';

  constructor(private readonly orderShipmentViewTableService: OrderShipmentViewTableService,
              private readonly cdRef: ChangeDetectorRef
              ) { }

  ngOnInit() {
    this.buildView();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildView();
  }

  private buildView() {
    if (this.orderWasEdited) {
      this.isQuantityNotEqualsLinkedMovementQuantity = this.orderShipmentViewTableService.isQuantityNotEqualsLinkedMovementQuantity(this.item);

      if (this.isQuantityNotEqualsLinkedMovementQuantity) {
        this.quantityDelta = this.orderShipmentViewTableService.getLinkedMovementQuantityDelta(this.item);
      }

      if (this.type === 'order') {
        this.tooltipText = 'Количество товара в отгрузке';
      } else {
        this.tooltipText = 'Количество товара в заявке';
      }

      this.cdRef.markForCheck();
    }
  }

}
