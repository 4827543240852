import { APP_INITIALIZER } from '@angular/core';
import { MatIconRegistryService } from './mat-icon-registry.service';

export function iconsFactory(mi: MatIconRegistryService): any {
  return (): any => mi.initCustomMatIcons();
}

export const CustomMatIconInitializer = {
  provide: APP_INITIALIZER,
  useFactory: iconsFactory,
  deps: [MatIconRegistryService],
  multi: true,
};
