import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerHeaderComponent } from '@ui/components/mat-form-components/mat-datepicker-header/mat-datepicker-header.component';
import { MatDatepickerComponent } from '@ui/components/mat-form-components/mat-datepicker/mat-datepicker.component';
import { MatSelectComponent } from '@ui/components/mat-form-components/mat-select/mat-select.component';
import { MatInputComponent } from '@ui/components/mat-form-components/mat-input/mat-input.component';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { MatTextareaComponent } from '@ui/components/mat-form-components/mat-textarea/mat-textarea.component';
import { MatSlideToggleComponent } from '@ui/components/mat-form-components/mat-slide-toggle/mat-slide-toggle.component';
import { MatAutocompleteComponent } from '@ui/components/mat-form-components/mat-autocomplete/mat-autocomplete.component';
import { MatAutocompleteTemplateDirective } from '@ui/components/mat-form-components/mat-autocomplete/mat-autocomplete-template.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

const COMPONENTS = [
  MatSelectComponent,
  MatInputComponent,
  MatDatepickerHeaderComponent,
  MatDatepickerComponent,
  MatTextareaComponent,
  MatSlideToggleComponent,
  MatAutocompleteComponent,
  MatAutocompleteTemplateDirective
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatAutocompleteModule
  ],
  exports: [COMPONENTS],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'ru-RU',
    },
  ],
})
export class MatFormComponentsModule {}
