export interface IPopup {
  id?: number;
  title?: string;
  text: string;
  type: PopupTypeEnum;
  timeout?: number;
}

export const enum PopupTypeEnum {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success'
}

export class PopupModuleConfiguration {
  timeout?: number;
}
