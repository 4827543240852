import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FakeApiService } from '@app/@core/web-api/shared/services/fake-api.service';
import { IWebApiConfigModule } from './shared/interfaces/web.api.config.interface';
import { REQUEST_BUILDER, RequestBuilder } from './shared/models/request-builder.service';
import { WebApiClient } from './shared/services/web-api-client.service';
import { WebApiService } from './shared/services/web-api.service';
import { WEB_API_CONFIG } from './web-api.config';
import { ErrorInterceptor } from '@core/web-api/shared/interceptors/error.interceptor';
import { WEB_API_INTERCEPTORS } from '@core/web-api/shared/services/web-api.handler.service';
import { TokenInterceptor } from '@core/web-api/shared/interceptors/token.interceptor';
import { EncodeHttpParamsInterceptor } from './shared/interceptors/encode-http-params-interceptor';
import { BlobErrorHttpInterceptor } from '@core/web-api/shared/interceptors/blob.interceptor';

@NgModule({
  imports: [HttpClientModule],
})
export class WebApiModule {
  constructor(@Optional() @SkipSelf() parentModule: WebApiModule) {
    if (parentModule) {
      throw new Error('WebApiModule is already loaded. Import it in the CoreModule only');
    }
  }

  public static forRoot(webApiConfig: IWebApiConfigModule): ModuleWithProviders<WebApiModule> {
    return {
      ngModule: WebApiModule,
      providers: [
        ErrorInterceptor,
        {
          provide: WEB_API_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true,
        },
        {
          provide: WEB_API_INTERCEPTORS,
          useClass: BlobErrorHttpInterceptor,
          multi: true,
        },
        {
          provide: WEB_API_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true,
        },
        {
          provide: WEB_API_INTERCEPTORS,
          useClass: EncodeHttpParamsInterceptor,
          multi: true,
        },
        { provide: REQUEST_BUILDER, useClass: RequestBuilder },
        { provide: WEB_API_CONFIG, useValue: webApiConfig },
        WebApiClient,
        FakeApiService,
        WebApiService,
      ],
    };
  }
}
