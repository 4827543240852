import { Component, OnInit, ChangeDetectionStrategy, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-mat-textarea',
  templateUrl: './mat-textarea.component.html',
  styleUrls: ['./mat-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatTextareaComponent),
      multi: true,
    },
  ],
})
export class MatTextareaComponent implements OnInit, ControlValueAccessor {
  @Input() rows = 5;

  public isDisabled = false;
  public value = '';

  onChange = (fn: any): void => {};
  onTouched = (): void => {};

  constructor() {}

  ngOnInit() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  public onInput(event: any) {
    const value = event.target.value;
    this.writeValue(value);
    this.onChange(value);
  }
}
