import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ContentChild,
  TemplateRef,
  forwardRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Self,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteTemplateDirective } from '@ui/components/mat-form-components/mat-autocomplete/mat-autocomplete-template.directive';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-mat-autocomplete',
  templateUrl: './mat-autocomplete.component.html',
  styleUrls: ['./mat-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatAutocompleteComponent),
      multi: true
    },
    NgOnDestroy
  ]
})
export class MatAutocompleteComponent implements OnInit, ControlValueAccessor {
  @ContentChild(MatAutocompleteTemplateDirective, { static: true, read: TemplateRef }) template: TemplateRef<any>;
  @ViewChild(MatAutocompleteTrigger, { static: true, read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

  @Input() options: any[] = [];
  @Input() placeholder = '';
  @Input() withUnderline = false;

  @Output() autocompleteInput = new EventEmitter<string>();
  @Output() optionSelected = new EventEmitter<any>();

  public isDisabled = false;
  public inputValue: any;
  public onInputBlur$ = new EventEmitter<any>();

  private lastSelectedOption: any;
  @Input() displayWith: (value: any) => string | null = (value: any) => value.toString();
  private onChange = (value: any) => {};
  private onTouched = () => {};

  constructor(
    private cdRef: ChangeDetectorRef,
    @Self() private componentDestroyed$: NgOnDestroy
  ) {
    // Реализовано через RX т.к. blur срабатывает раньше (optionSelected), отчего работает не так как требуется
    this.onInputBlur$
      .pipe(
        debounceTime(0),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(event => {
        const eventValue = event.target.value;
        // При потере фокуса на инпуте возвращаем значение на последний выбранный элемент
        if (!this.lastSelectedOption) {
          this.inputValue = ' ';
          this.cdRef.detectChanges();
          this.inputValue = '';
          this.autocompleteInput.emit('');
        }

        if (!eventValue) {
          this.inputValue = '';
          this.lastSelectedOption = null;
          this.onChange(null);
        }

        if (eventValue !== this.inputValue) {
          if (this.lastSelectedOption) {
            this.inputValue = ' ';
            this.cdRef.detectChanges();
            this.inputValue = this.displayWith(this.lastSelectedOption);
            this.cdRef.detectChanges();
            this.autocompleteInput.emit('');
          }
        }
      });
  }

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.inputValue = obj ? this.displayWith(obj) : '';
    this.lastSelectedOption = obj;

    this.cdRef.markForCheck();
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    this.inputValue = this.displayWith(value);
    this.lastSelectedOption = value;
    this.onChange(value);
    this.optionSelected.emit(value);
  }

  public handleInput(event: any) {
    this.autocompleteInput.emit(event.target.value);
  }

  public handleInputClick() {
    this.inputAutoComplete.openPanel();
  }

  public handleBlur(event: any) {
    this.onInputBlur$.emit(event);
  }
}
