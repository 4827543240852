import { DefaultTableQueryDto } from '@core/shared/dto/default-table-query.dto';
import {
  NotificationStatusEnum,
  NotificationsTypeEnum,
} from '@ui/modules/notifications-list-popup/notifications-list-content/notifications-list-item/shared/notifications-list-list-item.interface';

export class UserNotificationsListQueryDto extends DefaultTableQueryDto {
  notificationType?: NotificationsTypeEnum;
  notificationsStatus?: NotificationStatusEnum;
  dateFrom?: string;
  dateTo?: string;
  filter?: string;
}
