import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthService } from '@app/@core/modules/auth/auth.service';
import { RoleAccessEnum } from '@core/shared/enums/role-access.enum';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appUserCan]',
  providers: [NgOnDestroy],
})
export class UserCanDirective implements OnInit {
  @Input() appUserCan!: RoleAccessEnum;
  @Input('appUserCanDebug') debug = false;

  constructor(
    private authService: AuthService,
    @Self() private componentDestroyed$: NgOnDestroy,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.authService
      .userHasAbility(this.appUserCan)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((hasAbility) => {
        this.viewContainer.clear();

        if (hasAbility) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }

        this.cdRef.markForCheck();
      });
  }
}
