import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select/select.component';
import { InputComponent } from '@ui/components/form-components/input/input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioComponent } from './radio/radio.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FormlyTitleWrapperComponent } from '@ui/components/form-components/wrappers/title.wrapper';
import { InputFileComponent } from './input-file/input-file.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyTitleCollapseWrapperComponent } from '@ui/components/form-components/wrappers/title-collapse.wrapper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { upWordsNumbersDash } from '@ui/components/form-components/utils';
import { MatDatepickerComponent } from '@ui/components/mat-form-components/mat-datepicker/mat-datepicker.component';
import { LinkComponent } from './link/link.component';
import { TelComponent } from './tel/tel.component';
import { MailtoComponent } from './mailto/mailto.component';
import { TextareaComponent } from './textarea/textarea.component';
import { JoyrideModule } from 'ngx-joyride';

const FORM_COMPONENTS = [
  SelectComponent,
  InputComponent,
  RadioComponent,
  CheckboxComponent,
  InputFileComponent,
  FormlyTitleWrapperComponent,
  FormlyTitleCollapseWrapperComponent,
  LinkComponent,
  TelComponent,
  MailtoComponent,
  TextareaComponent,
];

@NgModule({
  declarations: [FORM_COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      types: [
        { name: 'input', component: InputComponent },
        { name: 'select', component: SelectComponent },
        { name: 'radio', component: RadioComponent },
        { name: 'checkbox', component: CheckboxComponent },
        { name: 'file', component: InputFileComponent },
        { name: 'datepicker', component: MatDatepickerComponent },
        { name: 'link', component: LinkComponent },
        { name: 'tel', component: TelComponent },
        { name: 'mailto', component: MailtoComponent },
        { name: 'textarea', component: TextareaComponent },
      ],
      validationMessages: [{ name: 'required', message: 'Поле обязательно' }],
      validators: [
        {
          name: 'wordsAndNumbers',
          validation: upWordsNumbersDash as any,
        },
      ],
      wrappers: [
        {
          name: 'form-group-with-title',
          component: FormlyTitleWrapperComponent,
        },
        {
          name: 'form-group-collapse-title',
          component: FormlyTitleCollapseWrapperComponent,
        },
      ],
    }),
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    JoyrideModule,
  ],
  exports: [FORM_COMPONENTS],
})
export class FormComponentsModule {}
