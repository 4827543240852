export class TableResponseDto<T> {
  data: T[] = [];
  pagination!: TableResponseDtoPagination;
}

export class TableResponseDtoPagination {
  totalCount!: number;
  totalFilteredCount!: number;
  limit!: number;
  page!: number;
  lastPage!: number;
  nextPageUrl!: string;
  prevPageUrl!: string;
  from!: number;
  to!: number;
}
