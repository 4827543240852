import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { NotificationsListOverlayService } from '@ui/modules/notifications-list-popup/notifications-list-overlay/notifications-list-overlay.service';
import { NotificationsListOverlayRef } from '@ui/modules/notifications-list-popup/notifications-list-overlay/notifications-list-overlay.ref';
import { falseIfMissing } from 'protractor/built/util';
import { Observable, Subscription } from 'rxjs';
import { NotificationsListFacade } from '@ui/modules/notifications-list-popup/notifications-list.facade';

@Component({
  selector: 'app-notifications-header-button',
  templateUrl: './notifications-header-button.component.html',
  styleUrls: ['./notifications-header-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsHeaderButtonComponent implements OnInit {
  @Output() openState = new EventEmitter<boolean>();
  @ViewChild('wrapper', { static: true }) wrapper!: ElementRef;

  public opened = false;
  public notificationsUnreadCount$: Observable<number>;

  private dialogRef!: NotificationsListOverlayRef;
  private subscribe$!: Subscription;

  constructor(
    private notificationsListOverlayService: NotificationsListOverlayService,
    private cdRef: ChangeDetectorRef,
    private notificationsListFacade: NotificationsListFacade,
  ) {
    this.notificationsUnreadCount$ = notificationsListFacade.getUnreadNotificationsCount();
  }

  ngOnInit() {}

  @HostListener('window:keyup', ['$event'])
  public onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.opened) {
      this.click();
    }
  }

  public click() {
    if (!this.opened) {
      this.opened = true;
      this.openState.next(this.opened);
      this.dialogRef = this.notificationsListOverlayService.open(this.wrapper);

      this.subscribe$ = this.dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
        this.openState.next(this.opened);
        this.cdRef.markForCheck();
        this.subscribe$.unsubscribe();
      });
    } else {
      this.dialogRef.close();
    }
  }
}
