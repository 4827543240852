import { Component, OnInit, ChangeDetectionStrategy, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { DateRangeDatepickerOverlayService } from '@ui/modules/mat-daterange-picker/components/date-range-datepicker-overlay/date-range-datepicker-overlay.service';
import * as moment from 'moment';
import { IDateRangeSelectedInterface } from '@ui/modules/mat-daterange-picker/components/mat-daterange-picker/shared/date-range-selected.interface';

@Component({
  selector: 'app-mat-daterange-picker',
  templateUrl: './mat-daterange-picker.component.html',
  styleUrls: ['./mat-daterange-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatDaterangePickerComponent implements OnInit {
  @Input() input!: ElementRef;
  @Input() dateFrom = moment();
  @Input() dateTo = moment();

  @Output() selectedChange = new EventEmitter<IDateRangeSelectedInterface>();

  constructor(private overlayService: DateRangeDatepickerOverlayService) {}

  ngOnInit() {}

  public open() {
    this.overlayService.open(this.input, this);
  }

  public select(dateRangeSelected: IDateRangeSelectedInterface) {
    this.selectedChange.emit(dateRangeSelected);
  }
}
