import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-checkbox',
  template: `
    <label class="checkbox-widget__label">
      <input
        type="checkbox"
        class="visually-hidden checkbox-widget__input"
        [formControl]="formControl"
        [formlyAttributes]="field"
      />
      <span class="checkbox-widget__pseudo"></span>
      <span class="checkbox-widget__text"> {{ field?.templateOptions?.label }}</span>
    </label>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends FieldType {}
