import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-data-message',
  templateUrl: './no-data-message.component.html',
  styleUrls: ['./no-data-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataMessageComponent {
  @Input() loading = false;
  @Input() entities: unknown[] = [];
  @Input() queryKey = '';
}
