<div class="dialog__header">
  {{ data.header }}
  <button (click)="close()" class="close-button" mat-button>
    <mat-icon class="close-button__icon" svgIcon="close"></mat-icon>
  </button>
</div>

<div class="dialog__wrapper">
  <div class="dialog__content" mat-dialog-content>
    <div class="label">{{ data.textAreaLabel }} <span class="asterisk">&#42;</span></div>

    <app-mat-textarea rows="10" [formControl]="form.get('text')"></app-mat-textarea>
  </div>
</div>

<div class="actions" mat-dialog-actions>
  <app-button type="flat" name="Продолжить" (click)="done()" [disabled]="form.invalid"></app-button>
  <app-button type="stroked-gray" name="Закрыть" (click)="close()"></app-button>
</div>
