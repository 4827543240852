import { ITreeData } from '@ui/components/abstract-tree/shared/tree.interface';
import { BehaviorSubject } from 'rxjs';

export class DynamicFlatNode<T = ITreeData> {
  constructor(
    public item: string,
    public id: number,
    public level = 1,
    public expandable = false,
    public treeData: T | null = null,
    public isLoading = new BehaviorSubject(false),
  ) {}
}
