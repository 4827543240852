import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '@environments/environment';

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.STS_SERVER,
  requireHttps: environment.REQUIRE_HTTPS,

  // URL of the SPA to redirect the user to after login
  redirectUri: environment.REDIRECT_URL,

  sessionChecksEnabled: environment.SESSION_CHECKS_ENABLED,

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: environment.SILENT_RENEW_URL,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.CLIENT_ID,

  responseType: environment.RESPONSE_TYPE,

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: environment.SCOPE,

  silentRefreshTimeout: environment.production ? 30000 : 1000,
  showDebugInformation: true,
};
