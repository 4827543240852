import { Injectable, OnDestroy } from '@angular/core';
import { NO_DEPARTMENT_ID } from '@core/shared/constants/empty-data';
import { ICartItem } from '@core/shared/interfaces/cart-item.interface';
import { IOrder } from '@core/shared/interfaces/order.interface';
import { IBaseProduct, IProductCard } from '@core/shared/interfaces/product-card.interface';
import { OrdersService } from '@core/shared/services/orders.service';
import { PopupService } from '@ui/shared/services/popup.service';
import {BehaviorSubject, combineLatest, EMPTY, merge, Observable} from 'rxjs';
import {catchError, filter, map, skip, switchMap, tap} from 'rxjs/operators';
import { AuthService } from '@app/@core/modules/auth/auth.service';
import { RoleAccessEnum } from '@core/shared/enums/role-access.enum';
import { TagManagerService } from '@core/shared/services/tag-manager.service';

@Injectable({
  providedIn: 'root',
})
export class CartService implements OnDestroy {
  items$: Observable<ICartItem[]>;
  order$: Observable<IOrder>;
  private allItemsQuantitySubject = new BehaviorSubject<number>(0);
  allItemsQuantity$ = this.allItemsQuantitySubject.asObservable();
  private itemsSubject: BehaviorSubject<ICartItem[]>;
  private activeDepartmentId$ = new BehaviorSubject<number>(NO_DEPARTMENT_ID);
  private order: IOrder = {} as IOrder;

  constructor(
    private snackbar: PopupService,
    private ordersService: OrdersService,
    private authService: AuthService,
    private tagManagerService: TagManagerService,
  ) {
    this.itemsSubject = new BehaviorSubject<ICartItem[]>([]);
    this.items$ = this.itemsSubject.asObservable();
    this.order$ = this.getOrderObs();
  }

  get items(): ICartItem[] {
    return this.itemsSubject.value || [];
  }

  get activeDepartmentId(): number {
    return this.activeDepartmentId$.value;
  }

  ngOnDestroy(): void {
    this.itemsSubject.complete();
  }

  setSelectedDepartmentID(id: number) {
    this.activeDepartmentId$.next(id);
    if (id === NO_DEPARTMENT_ID) {
      this.removeAllCartItemsLocally();
      this.allItemsQuantitySubject.next(0);
    }
  }

  setProductQuantity(product: IBaseProduct, quantity: number): Observable<ICartItem | number> {
    const itemIndex = this.findCartItemIndex(product);
    if (itemIndex === -1) {
      return this.addNewItemToCart(product);
    } else {
      const validQuantityResult = this.getValidGoodQuantity(quantity, product);
      return this.updateItemQuantity(product, validQuantityResult.value, validQuantityResult.fixed);
    }
  }

  removeItemFromCart(product: IBaseProduct): Observable<void> {
    const itemIndex = this.findCartItemIndex(product);
    const newItems = [...this.items];
    const [removedItem] = newItems.splice(itemIndex, 1);
    const googleTagItem = {
      ...product,
      price: removedItem.price,
    };

    return this.ordersService.deleteItemFromOrder(this.order.id, removedItem.id).pipe(
      tap(() => {
        this.updateItemsSubject(newItems);
        this.snackbar.info('Товар удален из корзины');

        this.tagManagerService.pushRemoveProductFromCartTag(googleTagItem as IProductCard, removedItem.quantity);
      }),
    );
  }

  public removeItemFromOrder(orderId: number, item: ICartItem): Observable<void> {
    return this.ordersService.deleteItemFromOrder(orderId, item.id).pipe(
      tap(() => {
        this.snackbar.info('Товар удален из заявки');
        this.tagManagerService.pushRemoveProductFromCartTag(
          {
            ...item.good,
            price: item.price,
          } as IProductCard,
          item.quantity,
        );
      }),
    );
  }

  removeAllCartItemsLocally(): void {
    this.itemsSubject.next([]);
    this.order$ = this.getOrderObs();
  }

  findCartItemIndex(item: IBaseProduct): number {
    return this.items.findIndex((cartItem) => cartItem.good.id === item.id);
  }

  setOrderManually(order: IOrder): Observable<IOrder> {
    this.order = order;
    return this.getOrderItemsObsMappedToOrder(order);
  }

  private addNewItemToCart(product: IBaseProduct, quantity?: number, showSuccessMessage = true): Observable<ICartItem> {
    return this.ordersService.addItemToOrder(this.order.id, product.id, quantity || product.minQuantity).pipe(
      tap((cartItem) => {
        this.updateItemsSubject([...this.items, cartItem]);

        if ('price' in product) {
          this.tagManagerService.pushProductAddToCartTag((product as any), product!.minQuantity);
        }

        if (showSuccessMessage) {
          this.snackbar.info('Товар добавлен в корзину');
        }
      }),
      catchError(err => {
        if (err.error.indexOf('не кратно') !== -1) {
          this.addNewItemToCart(product, Math.max(product.pickingQuantum, product.minQuantity), false).subscribe();
        }
        return EMPTY;
      })
    );
  }

  private updateItemQuantity(product: IBaseProduct, quantity: number, valueWasFixed = true): Observable<number> {
    const itemIndex = this.findCartItemIndex(product);
    const cartItem = this.items[itemIndex];
    const cartItemBeforeUpdate: ICartItem = { ...cartItem };
    const googleTagItem = {
      ...product,
      price: cartItem.price,
    };

    return this.ordersService.updateOrderItemQuantity(this.order.id, cartItem.id, quantity, !valueWasFixed).pipe(
      tap((newQuantity: number) => {
        const newItems = [...this.items];
        newItems[itemIndex].quantity = quantity;
        this.updateItemsSubject(newItems);
        const quantityDelta = newQuantity - cartItemBeforeUpdate.quantity;

        if (quantityDelta > 0) {
          this.tagManagerService.pushProductAddToCartTag(googleTagItem as IProductCard, quantityDelta);
        } else {
          this.tagManagerService.pushRemoveProductFromCartTag(googleTagItem as IProductCard, Math.abs(quantityDelta));
        }
      }),
    );
  }

  private updateItemsSubject(items: ICartItem[]) {
    this.itemsSubject.next(items);
  }

  private getOrderObs(): Observable<IOrder> {
    const orderOnDepartmentChange$ = combineLatest([
      this.activeDepartmentId$.pipe(filter((id) => id !== NO_DEPARTMENT_ID)),
      this.authService.userHasAbility(RoleAccessEnum.VIEW_ORDER_DRAFT).pipe(filter((hasAbility) => hasAbility)),
    ]).pipe(
      switchMap(([departmentId]) => this.ordersService.getOrderDraft(departmentId)),
      tap((order) => {
        this.order = order;
      }),
      switchMap((order) => this.getOrderItemsObsMappedToOrder(order)),
    );

    const orderOnItemsChange = combineLatest([
      this.itemsSubject,
      this.authService.userHasAbility(RoleAccessEnum.VIEW_ORDER_DRAFT).pipe(filter((hasAbility) => hasAbility)),
    ]).pipe(
      filter(() => this.activeDepartmentId$.value !== NO_DEPARTMENT_ID),
      skip(1),
      switchMap(() => this.ordersService.getOrderDraft(this.activeDepartmentId)),
      tap((order) => {
        this.order = order;
      }),
    );

    return merge(orderOnDepartmentChange$, orderOnItemsChange).pipe(
      tap((order) => this.allItemsQuantitySubject.next(order.itemsCount)),
    );
  }

  private getValidGoodQuantity(quantity: number, good: IBaseProduct): {
    fixed: boolean,
    value: number
  } {
    if (quantity < good.minQuantity) {
      this.snackbar.info('Указано количество товара меньше минимальной партии. Количество товара изменено.');
      return {
        fixed: true,
        value: Math.max(good.minQuantity, good.pickingQuantum)
      };
    } else if (quantity % good.pickingQuantum !== 0) {
      this.snackbar.info('Указано количество товара некратное шагу. Количество товара изменено.');
      return {
        fixed: true,
        value: Math.ceil(quantity / good.pickingQuantum) * good.pickingQuantum
      };
    }
    return {
      fixed: false,
      value: quantity
    };
  }

  private getOrderItemsObsMappedToOrder(order: IOrder): Observable<IOrder> {
    return this.ordersService
      .getCartItems(order.id, {
        limit: 99999,
      })
      .pipe(
        tap((items) => this.updateItemsSubject(items.data)),
        map(() => order),
      );
  }
}
