import { ChangeDetectionStrategy, Component, Input, OnInit, Self, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { COUNT_ON_PAGE, ICountItem } from './shared/count-on-page.data';
import { NgOnDestroy } from '@app/@core/shared/services/destroy.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-items-count-on-page',
  templateUrl: './items-count-on-page.component.html',
  styleUrls: ['./items-count-on-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ItemsCountOnPageComponent,
      multi: true,
    },
    NgOnDestroy,
  ]
})
export class ItemsCountOnPageComponent implements ControlValueAccessor, OnInit {
  @Input() public countFormControl: UntypedFormControl = null;
  @Input() public title = 'строк';
  @Input() public defaultValue = 20;
  public countsOnPage: Readonly<ICountItem[]> = COUNT_ON_PAGE;
  private onChange = (limit: number) => {};

  constructor(
    @Self() private readonly componentDestroyed$: NgOnDestroy,
    private readonly cd: ChangeDetectorRef,
  ) {}

  public writeValue(limit: number): void {
    this.countFormControl.setValue(limit);
    this.cd.detectChanges();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  private initForm(): void {
    if (this.countFormControl !== null) {
      return;
    }

    this.countFormControl = new UntypedFormControl(this.defaultValue);

    this.countFormControl.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(value => this.onChange(value));
  }

  public ngOnInit(): void {
    this.initForm();
  }
}
