import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ISelectOption } from '@ui/components/mat-form-components/shared/select-option.interface';

@Component({
  selector: 'app-mat-select',
  templateUrl: './mat-select.component.html',
  styleUrls: ['./mat-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MatSelectComponent,
      multi: true,
    },
  ],
})
export class MatSelectComponent implements OnChanges, ControlValueAccessor {
  @Input() options: ISelectOption[] = [{ value: '', title: '' }];
  @Input() selectedOption!: string | number;
  @Input() title = 'товаров';
  @Input() keyTitle = 'title';
  @Input() multiple = false;
  @Input() label = '';
  @Output() selected = new EventEmitter();

  public value: any;
  public isDisabled = false;

  onChange = (fn: any): void => {};
  onTouched = (): void => {};

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const options = changes.options;
    const selectedOption = changes.selectedOption;

    if (options && selectedOption) {
      this.value = selectedOption.currentValue;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.cdRef.markForCheck();
  }

  public handleOptionSelected(option: any) {
    this.value = option;
    this.selected.emit(option);
    this.onChange(option);
  }
}
