<liri-dialog-base
  [showCloseButton]="false"
>
  <ng-container dialog-title>
    <h4>Sign-in</h4>
  </ng-container>

  <ng-container dialog-content>
    <ng-container *ngIf="form">
      <ng-container [formGroup]="form">
        <div class="__auth-content">
          <mat-form-field appearance="fill">
            <mat-label>E-mail</mat-label>
            <input type="text" matInput formControlName="username">
            <mat-error>Required field</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input type="password" matInput formControlName="password">
            <mat-error>Required field</mat-error>
          </mat-form-field>
          <a href="#"><span>*</span>Restore password will come later</a>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container dialog-actions>
    <button
      type="button"
      mat-flat-button
      color="primary"
      (click)="submit()"
    >
      Submit
    </button>
    <button
      type="button"
      mat-flat-button
      color="warn"
      (click)="cancel()"
    >
      Cancel
    </button>
  </ng-container>
</liri-dialog-base>