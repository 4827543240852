import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventClickPropagation]'
})
export class PreventClickPropagationDirective {

  @HostListener('click', ['$event'])
  public onClick($event: Event): void {
    $event.stopPropagation();
  }
}
