export enum RoleAccessEnum {
  VIEW_SHOP = 'viewShop',
  VIEW_MANUFACTURER = 'viewManufacturer',
  VIEW_USER = 'viewUser',
  VIEW_SETTINGS = 'viewSettings',
  VIEW_SUPPORT = 'viewSupport',
  VIEW_ANALYTICS = 'viewAnalytics',
  VIEW_DISTRIBUTOR = 'viewDistributor',
  VIEW_SHOWCASE = 'viewShowcase',
  VIEW_SYSTEM_MANAGEMENT = 'viewSystemManagement',
  VIEW_ORDERS = 'viewOrders',
  VIEW_SHIPMENTS = 'viewShipments',
  VIEW_DEPARTMENTS = 'viewDepartments',
  VIEW_PRICING = 'viewPricing',
  VIEW_RESTS = 'viewRests',
  VIEW_SELECTIONPARAMETERS = 'viewSelectionParameters',
  VIEW_NOTIFICATIONS = 'viewNotifications',
  VIEW_ORDER_DRAFT = 'viewOrderDraft',
  VIEW_ORGANISATION_PAYMENT_ACCOUNT = 'viewOrganisationPaymentAccount',
  VIEW_ADMINISRATION = 'viewAdministration',
  ADD_DEPARTMENT = 'addDepartment',
  EDIT_GOOD_HIERARCHY = 'editGoodHierarchy',
  EDIT_BRANDS = 'editBrands',
  EDIT_NOMENCLATURE = 'editNomenclature',
  EDIT_CONTRACTS = 'editContracts',
  VIEW_ANONYMOUS_CACHE = 'viewAnonymousCache',
  CAN_LOGOUT = 'canLogout'
}
