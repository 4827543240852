import { ITreeData } from '@ui/components/abstract-tree/shared/tree.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicFlatNode } from './dynamic-flat-node.model';

export abstract class BaseDynamicDatabase<T extends ITreeData = ITreeData> {
  initialData(): Observable<DynamicFlatNode[]> {
    return this.getDataMap().pipe(
      map((data: T[]) => data.map((item: T) => new DynamicFlatNode(item.name, item.id, 0, item.expandable, item))),
    );
  }

  abstract getChildren(parentId: number): Observable<T[]>;

  protected abstract getDataMap(): Observable<T[]>;
}
