import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { DialogTitleDirective, DialogContentDirective, DialogActionsDirective } from '../directives';

@Component({
  selector: 'liri-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogBaseComponent {
  @Input() public showCloseButton = true;

  @ContentChild(DialogTitleDirective, { static: false })
  public dialogTitle: DialogTitleDirective;

  @ContentChild(DialogContentDirective, { static: false })
  public dialogContent: DialogTitleDirective;

  @ContentChild(DialogActionsDirective, { static: false })
  public dialogActions: DialogTitleDirective;
}
