import { EventEmitter, Injectable, Self } from '@angular/core';
import { IOrderAction, ShipmentActionEnum } from '@core/shared/interfaces/order.interface';
import { ISimpleTextAreaDialogData } from '@ui/components/simple-textarea-dialog/shared/simple-text-area-dialog.data';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ISimpleTextAreaDialogOutputData } from '@ui/components/simple-textarea-dialog/shared/simple-text-area-dialog-output.data';
import { NEVER, Observable } from 'rxjs';
import { IPaymentDialogData } from '@ui/components/payment-dialog/shared/payment-dialog.data';
import { PaymentDialogComponent } from '@ui/components/payment-dialog/payment-dialog.component';
import { SimpleTextareaDialogComponent } from '@ui/components/simple-textarea-dialog/simple-textarea-dialog.component';
import { BaseActionsService } from '@core/shared/services/order-actions-services/base-actions.service';
import { ShipmentActionsService } from '@core/shared/services/order-actions-services/shipment-actions.service';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { MatDialog } from '@angular/material/dialog';
import { IShipment } from '@core/shared/interfaces/shipment.interface';

@Injectable()
export class ShipmentActionsHandlerService {
  public onActionDone: EventEmitter<IOrderAction<ShipmentActionEnum>>;

  constructor(
    public baseActionsService: BaseActionsService,
    public shipmentActionsService: ShipmentActionsService,
    @Self() public componentDestroyed$: NgOnDestroy,
    public dialog: MatDialog
  ) {

  }

  public openSupplierRejectDialog(action: IOrderAction<any>, entity: IShipment): void {
    const dialogData: ISimpleTextAreaDialogData = {
      header: 'Отмена поставки',
      textAreaLabel: 'Укажите причину отказа',
    };

    this.baseActionsService
      .openRejectDialog(entity, dialogData)
      .pipe(
        switchMap((result?: ISimpleTextAreaDialogOutputData) => {
          if (result && result.result && result.text) {
            return this.shipmentActionsService.rejectBySuppler(entity, result.text);
          }
          return NEVER;
        }),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(() => this.onActionDone.emit(action));
  }

  public shipmentEditRequest(action: IOrderAction<ShipmentActionEnum>, entity: IShipment): void {
    const dialogData: ISimpleTextAreaDialogData = {
      header: 'Редактирование поставки',
      textAreaLabel: 'Укажите причину для редактирования поставки',
    };

    this.baseActionsService
      .openRejectDialog(entity, dialogData)
      .pipe(
        filter((result: ISimpleTextAreaDialogOutputData) => result && result.result && !!result.text),
        switchMap((result: ISimpleTextAreaDialogOutputData) =>
          this.shipmentActionsService.shipmentCorrectionRequest(action.action, result.text)),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(() => this.onActionDone.emit(action));
  }

  public openPaymentDialog(action: IOrderAction<any>, entity: IShipment) {
    const data: IPaymentDialogData = {
      movementId: entity.id,
    };

    this.dialog
      .open(PaymentDialogComponent, {
        width: '565px',
        panelClass: 'common-dialog',
        data,
      })
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((result?: boolean) => {
        if (result) {
          this.onActionDone.emit(action);
        }
      });
  }

  public openCustomerRejectDialog(action: IOrderAction<any>, entity: IShipment) {
    const dialogData: ISimpleTextAreaDialogData = {
      header: 'Отмена поставки',
      textAreaLabel: 'Укажите причину отказа',
    };

    this.baseActionsService
      .openRejectDialog(entity, dialogData)
      .pipe(
        switchMap((result?: ISimpleTextAreaDialogOutputData) => {
          if (result && result.result && result.text) {
            return this.shipmentActionsService.rejectByCustomer(entity, result.text);
          }
          return NEVER;
        }),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(() => this.onActionDone.emit(action));
  }

  public onRevertActionClicked(action: IOrderAction<ShipmentActionEnum>, entity: IShipment) {
    this.openRevertCommentDialog(action)
      .pipe(
        filter(data => data.result)
      )
      .subscribe((outputData) => this.onRevertConfirmed(outputData, action, entity));
  }

  public openRevertCommentDialog(action: IOrderAction<ShipmentActionEnum>) {
    const dialogData: ISimpleTextAreaDialogData = {
      header: 'Откат отгрузки',
      textAreaLabel: 'Введите причину отката отгрузки'
    };

    return this.dialog.open(SimpleTextareaDialogComponent, {
      width: '600px',
      panelClass: ['common-dialog', 'mat-full-dialog'],
      data: dialogData
    }).afterClosed();
  }

  public onRevertConfirmed(userInput: ISimpleTextAreaDialogOutputData, action: IOrderAction<ShipmentActionEnum>, entity: IShipment) {
    this.shipmentActionsService.revert(entity, action, userInput.text).subscribe(() => this.onActionDone.emit(action));
  }

  public onClaimResolveActionClicked(action: IOrderAction<ShipmentActionEnum.ACCEPT_CLAIM | ShipmentActionEnum.DECLINE_CLAIM>, entity: IShipment) {
    this.openClaimResolveCommentDialog(action.code as ShipmentActionEnum.ACCEPT_CLAIM | ShipmentActionEnum.DECLINE_CLAIM)
      .pipe(
        filter((dialogResult) => dialogResult.result),
        map(dialogResult => dialogResult.text),
        switchMap(userDialogText => {
          let observable: Observable<unknown>;

          if (action.code === ShipmentActionEnum.ACCEPT_CLAIM) {
            observable = this.shipmentActionsService.acceptClaim(entity, userDialogText);
          } else {
            observable = this.shipmentActionsService.declineClaim(entity, userDialogText);
          }

          return observable;
        }),
        take(1)
      ).subscribe(() => this.onActionDone.emit(action));
  }

  public openClaimResolveCommentDialog(type: ShipmentActionEnum.ACCEPT_CLAIM | ShipmentActionEnum.DECLINE_CLAIM): Observable<ISimpleTextAreaDialogOutputData> {
    const header = type === ShipmentActionEnum.ACCEPT_CLAIM ? 'Принятие претензии' : 'Отказ в претензии';

    const dialogData: ISimpleTextAreaDialogData = {
      header,
      textAreaLabel: 'Введите причину вашего решения'
    };

    return this.dialog.open(SimpleTextareaDialogComponent, {
      width: '600px',
      panelClass: ['common-dialog', 'mat-full-dialog'],
      data: dialogData
    }).afterClosed();
  }

  public onRejectPaymentActionClicked(action: IOrderAction<ShipmentActionEnum.REJECT_PAYMENT>, entity: IShipment) {
    this.openRejectPaymentCommentDialog()
      .pipe(
        filter((dialogResult: ISimpleTextAreaDialogOutputData) => dialogResult.result),
        map(dialogResult => dialogResult.text),
        switchMap(userDialogText =>
          this.shipmentActionsService.rejectPayment(entity, userDialogText)),
        take(1)
      ).subscribe(() => this.onActionDone.emit(action));
  }

  public openRejectPaymentCommentDialog() {
    const dialogData: ISimpleTextAreaDialogData = {
      header: 'Отказ в оплате',
      textAreaLabel: 'Введите причину вашего решения'
    };

    return this.dialog.open(SimpleTextareaDialogComponent, {
      width: '600px',
      panelClass: ['common-dialog', 'mat-full-dialog'],
      data: dialogData
    }).afterClosed();
  }
}
