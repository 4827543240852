<mat-icon
  svgIcon="payment-done"
  *ngIf="status"
  matTooltip="Оплачено"
  matTooltipClass="default-tooltip"
  matTooltipPosition="above"
></mat-icon>
<mat-icon
  svgIcon="payment-waiting"
  *ngIf="!status"
  matTooltip="Неоплачено"
  matTooltipClass="default-tooltip"
  matTooltipPosition="above"
></mat-icon>
