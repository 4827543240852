import { Injectable } from '@angular/core';
// import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { IProductCard } from '@core/shared/interfaces/product-card.interface';
import { ICartItem } from '@core/shared/interfaces/cart-item.interface';
import { IOrder } from '@core/shared/interfaces/order.interface';

@Injectable({
  providedIn: 'root',
})
export class TagManagerService {
  constructor(
    // private googleTagManagerService: GoogleTagManagerService,
  ) {}

  public pushListingTag(products: IProductCard[], listing: string) {
    // this.googleTagManagerService.pushTag({
    //   event: 'showProducts',
    //   ecommerce: {
    //     currencyCode: 'RUB',
    //     impressions: this.mapProductsToListingTag(products, listing),
    //   },
    // });
  }

  public pushSingleProductClickedTag(product: IProductCard, position: number, listing: string) {
    const mappedProduct = this.mapProductToTagProduct(product);

    // this.googleTagManagerService.pushTag({
    //   event: 'productClick',
    //   ecommerce: {
    //     click: {
    //       actionField: {
    //         list: listing,
    //       },
    //       products: [this.addListingDataToProduct(mappedProduct, position, listing)],
    //     },
    //   },
    // });
  }

  public pushSingleProductViewCardTag(product: IProductCard, listing: string) {
    // this.googleTagManagerService.pushTag({
    //   event: 'showDetailProduct',
    //   ecommerce: {
    //     detail: {
    //       actionField: {
    //         list: listing,
    //       },
    //       products: [this.mapProductToTagProduct(product)],
    //     },
    //   },
    // });
  }

  public pushProductAddToCartTag(product: IProductCard, quantity: number) {
    // this.googleTagManagerService.pushTag({
    //   event: 'addToCart',
    //   ecommerce: {
    //     currencyCode: 'RUB',
    //     add: {
    //       products: [
    //         {
    //           ...this.mapProductToTagProduct(product),
    //           quantity,
    //         },
    //       ],
    //     },
    //   },
    // });
  }

  public pushRemoveProductFromCartTag(product: IProductCard | ICartItem, quantity: number) {
    // this.googleTagManagerService.pushTag({
    //   event: 'removeFromCart',
    //   ecommerce: {
    //     remove: {
    //       products: [
    //         {
    //           ...this.mapProductToTagProduct(product as IProductCard),
    //           quantity,
    //         },
    //       ],
    //     },
    //   },
    // });
  }

  public pushCartOpenedTag(products: ICartItem[]) {
    // this.googleTagManagerService.pushTag({
    //   event: 'checkout',
    //   ecommerce: {
    //     checkout: {
    //       actionField: {
    //         step: 1,
    //         option: 'View Cart',
    //       },
    //       products: this.mapCartItemsToTagItems(products),
    //     },
    //   },
    // });
  }

  public pushCartCheckoutTag(products: ICartItem[]) {
    // this.googleTagManagerService.pushTag({
    //   event: 'checkout',
    //   ecommerce: {
    //     checkout: {
    //       actionField: {
    //         step: 2,
    //         option: 'Cart checkout',
    //       },
    //       products: this.mapCartItemsToTagItems(products),
    //     },
    //   },
    // });
  }

  public pushOrderSentTag(order: IOrder, products: ICartItem[]) {
    // this.googleTagManagerService.pushTag({
    //   event: 'purchase',
    //   ecommerce: {
    //     purchase: {
    //       actionField: {
    //         id: order.id,
    //         affiliation: 'Онлайн-магазин',
    //         shipping: '',
    //         revenue: order.sum,
    //         tax: '',
    //       },
    //       products: this.mapCartItemsToTagItems(products),
    //     },
    //   },
    // });
  }

  private mapProductsToListingTag(products: IProductCard[], listing: string) {
    return products.map((product, index) => {
      index = index + 1;
      let mappedProduct = this.mapProductToTagProduct(product);
      mappedProduct = this.addListingDataToProduct(mappedProduct, index, listing);
      return mappedProduct;
    });
  }

  private mapProductsToTagProduct(products: IProductCard[]) {
    return products.map((product) => this.mapProductToTagProduct(product));
  }

  private mapCartItemsToTagItems(products: ICartItem[]) {
    return products.map((product) => ({
      ...this.mapProductToTagProduct(product.good as IProductCard),
      quantity: product.quantity,
      price: product.price,
    }));
  }

  private mapProductToTagProduct(product: IProductCard) {
    return {
      id: product.id,
      price: product.price,
      name: product.name,
      brand: product.brand ? product.brand.name : '-',
      category: product.category ? product.category.name : '-',
    };
  }

  private addListingDataToProduct(product: any, position: number, listing: string) {
    return {
      ...product,
      position,
      listing,
    };
  }
}
