import { IApplicationTable } from '@ui/components/add-application/shared/application-table.interface';

export const APPLICATION_TABLE_COLUMNS = ['position', 'vendorCode', 'name', 'price', 'count', 'total', 'action'];

export const APPLICATION_TABLE_DATA: IApplicationTable[] = [
  {
    position: 1,
    vendorCode: 12032165,
    name: 'Носки «Аполон»',
    price: 120,
    count: 2,
    total: 7000,
  },
  {
    position: 2,
    vendorCode: 55032148,
    name: 'Трусы «Аполон»',
    price: 55,
    count: 5,
    total: 3500,
  },
  {
    position: 3,
    vendorCode: 32032141,
    name: 'Подтяжки «Альфонс»',
    price: 320,
    count: 4,
    total: 1500,
  },
  {
    position: 4,
    vendorCode: 55032148,
    name: 'Трусы «Аполон»',
    price: 55,
    count: 5,
    total: 3500,
  },
  {
    position: 5,
    vendorCode: 32032141,
    name: 'Подтяжки «Альфонс»',
    price: 320,
    count: 4,
    total: 1500,
  },
  {
    position: 6,
    vendorCode: 32032141,
    name: 'Подтяжки «Альфонс»',
    price: 320,
    count: 4,
    total: 1500,
  },
  {
    position: 7,
    vendorCode: 55032148,
    name: 'Трусы «Аполон»',
    price: 55,
    count: 5,
    total: 3500,
  },
  {
    position: 8,
    vendorCode: 32032141,
    name: 'Подтяжки «Альфонс»',
    price: 320,
    count: 4,
    total: 1500,
  },
  {
    position: 9,
    vendorCode: 12032165,
    name: 'Носки «Аполон»',
    price: 120,
    count: 2,
    total: 7000,
  },
  {
    position: 10,
    vendorCode: 12032165,
    name: 'Носки «Аполон»',
    price: 120,
    count: 2,
    total: 7000,
  },
];
