import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import {WhoAmiLoadedGuard} from '@core/shared/guards/who-ami-loaded-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    canLoad: [WhoAmiLoadedGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error-page/error-page.module').then((m) => m.ErrorPageModule),
  },
  // {
  //   path: '**',
  //   redirectTo: '/not-found',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
