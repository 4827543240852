<mat-button-toggle-group>
  <mat-button-toggle
    (click)="optionSelect(mainButtonOption.value, mainButtonOption.action)"
    [ngClass]="type"
    class="drop-down-button"
    >{{ mainButtonOption.title }}</mat-button-toggle
  >
  <mat-button-toggle [matMenuTriggerFor]="dropdownMenuOne" [ngClass]="type" class="drop-down-button">
    <mat-icon class="__s14 __white" svgIcon="arrow-down"></mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>

<mat-menu #dropdownMenuOne="matMenu">
  <ng-container *ngFor="let option of options">
    <button (click)="optionSelect(option.value, option.action)" mat-menu-item>{{ option.title }}</button>
  </ng-container>
</mat-menu>

<input #uploadFileEl (change)="uploadFile($event)" hidden id="uploadFile" multiple type="file" />
