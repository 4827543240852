import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
  forwardRef,
  ChangeDetectorRef,
} from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-mat-slide-toggle',
  templateUrl: './mat-slide-toggle.component.html',
  styleUrls: ['./mat-slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatSlideToggleComponent),
      multi: true,
    },
  ],
})
export class MatSlideToggleComponent implements ControlValueAccessor {
  @Output() checked = new EventEmitter();
  value = false;
  onChange = (fn: any): void => {};
  onTouched = (): void => {};

  constructor(private cdRef: ChangeDetectorRef) {}

  onSimpleChange(event: MatSlideToggle) {
    this.checked.emit(event.checked);
  }

  writeValue(obj: boolean): void {
    this.value = obj;
    this.cdRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public handleOptionSelected(option: boolean) {
    this.value = option;
    this.onChange(option);
  }
}
