import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ITab } from '@ui/components/tabs/shared/tab.interface';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit {
  @Input() set tabs(tabs: ITab[]) {
    this._tabs = tabs;

    if (tabs.length > 0) {
      this.selectedTabId = tabs[0].id;
    }
  }
  get tabs(): ITab[] {
    return this._tabs;
  }

  @Input() set selectedTabId(tabId: number) {
    this._selectedTabId = tabId;
  }
  get selectedTabId(): number {
    return this._selectedTabId;
  }

  @Output() select = new EventEmitter<ITab>();

  private _selectedTabId!: number;
  private _tabs: ITab[] = [];

  constructor() {}

  ngOnInit() {}

  public selectTab(tab: ITab) {
    this.selectedTabId = tab.id;
    this.select.emit(tab);
  }
}
