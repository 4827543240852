export interface ICountItem {
  title: string;
  value: number;
}

export const COUNT_ON_PAGE: Readonly<ICountItem[]> = [
  { title: '10', value: 10 },
  { title: '20', value: 20 },
  { title: '30', value: 30 },
  { title: '50', value: 50 },
  { title: '100', value: 100 },
];
