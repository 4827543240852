import { Directive, TemplateRef, ViewContainerRef, Input, OnInit, AfterViewInit, Self, ChangeDetectorRef } from '@angular/core';
import {DEVICES} from '@app/@core/shared/enums/devices.enum';
import {WindowSizeService} from '../shared/services/window-size.service';
import {NgOnDestroy} from '@app/@core/shared/services/destroy.service';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[appAdaptive]',
  providers: [NgOnDestroy]
})
export class AdaptiveDirective implements AfterViewInit {
  @Input('appAdaptive') sizes!: DEVICES[];
  @Input('appAdaptiveReverse') reverse = false;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private windowSizeService: WindowSizeService,
    @Self() private componentDestroyed$: NgOnDestroy,
    private cdRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.windowSizeService.size$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(device => {
      if (this.reverse) {
        if (this.sizes && this.sizes.includes(device)) {
          this.viewContainerRef.clear();
        } else {
          this.viewContainerRef.clear();
          this.viewContainerRef.createEmbeddedView(this.templateRef);
          this.cdRef.detectChanges();
        }
      } else {
        if (this.sizes && this.sizes.includes(device)) {
          this.viewContainerRef.clear();
          this.viewContainerRef.createEmbeddedView(this.templateRef);
          this.cdRef.detectChanges();
        } else {
          this.viewContainerRef.clear();
        }
      }
    });
  }
}
