import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CartService } from '@core/shared/services/cart.service';
import { environment } from '@environments/environment';
import { WebApiModule } from '@core/web-api/web-api.module';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { AuthConfig, OAuthModule, ValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from '@app/@core/modules/auth/auth.config';
import { AuthService } from '@app/@core/modules/auth/auth.service';
import { WhoAmiLoadedGuard } from '@core/shared/guards/who-ami-loaded-guard.service';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    WebApiModule.forRoot({
      baseUrl: environment.WEB_API_URL,
    }),
    OAuthModule.forRoot(),
    TimeagoModule.forRoot({
      intl: { provide: TimeagoIntl, useClass: TimeagoIntl },
      formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
    }),
  ],
  providers: [],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: AuthConfig, useValue: authConfig },
        { provide: ValidationHandler, useClass: JwksValidationHandler },
        AuthService,
        WhoAmiLoadedGuard,
        CartService,
      ],
    };
  }
}
