import { Injectable } from '@angular/core';
import { PopupService } from '@ui/shared/services/popup.service';
import { Observable, of } from 'rxjs';
import {
  IGoodsActiveFromCategoryParams,
  IGoodsActiveFromCategoryResponse,
  IProductCard,
} from '@core/shared/interfaces/product-card.interface';
import { map, switchMap, catchError } from 'rxjs/operators';
import { WebApiService } from '@core/web-api/shared/services/web-api.service';
import { IBrand } from './shared/brand.interface';
import { IBrandCreate } from '@app/ui/widgets/system-management-widgets/directory-of-goods-widgets/edit-brand/shared/brand-create.interface';
import { IFormlyField } from '@ui/widgets/settings-widgets/add-good/shared/form-fields.interface';
import { IFieldsKinds } from '@app/pages/settings/nomenclature/shared/kinds.interface';
import { ICategories } from '@ui/widgets/settings-widgets/add-good/shared/categories.interface';
import { IBrands } from '@ui/widgets/settings-widgets/add-good/shared/brands.interface';
import { IBrandParams } from '../system-management/directory-of-goods/brands-hierarchy/shared/brand-params.interface';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { TypeResponse } from '@core/web-api/shared/enum/response.type';
import {
  IGoodForSettings,
  IGoodSettings,
  IGoodSettingsParams,
} from '@app/@core/shared/interfaces/good-settings.interface';
import { IBaseTableRequest } from '@core/shared/interfaces/base-table-request.interface';
import { ICollectionWithPagination } from '@core/shared/interfaces/collection-with-pagination.interface';
import {
  IPriceTrends,
  IPriceTrendSource,
  IPriceTrendsPrice,
  IPriceUpdateReq,
} from '@core/shared/interfaces/price-trends.interface';
import { TableResponseDto } from '@app/@core/shared/dto/table-response.dto';
import { PriceTrendsDTO } from '@app/@core/shared/dto/price-trends.dto';
import { IDepartmentList } from '@app/ui/shared/interfaces/department.interface';
import { NO_DEPARTMENT_ID } from '@core/shared/constants/empty-data';
import { showSuccessMessage } from '@core/shared/rxjs-operators/show-success-message';
import { dateConvertToIso8601wTz } from '@app/@core/shared/utils/date-iso-8601-tz.util';
import { ApiParamsUtil } from '@app/@core/shared/utils/api-params.util';
import { ILabelItem } from '../system-management/directory-of-goods/label-management/shared/interfaces/label-item.interface';
import { GoodsSettingsPutQueryDto } from '@core/shared/dto/goods-settings-put-query.dto';

@Injectable({
  providedIn: 'root',
})
export class GoodsSettingsService {
  constructor(private api: WebApiService, private snackbar: PopupService) {}

  getGoodsActiveFromCategory(
    categoryId = 0,
    queryParams: IGoodsActiveFromCategoryParams,
  ): Observable<IGoodsActiveFromCategoryResponse> {
    return this.api.get<IGoodsActiveFromCategoryResponse>(`Goods/Active/FromCategory/${categoryId}`, queryParams);
  }

  getGoodsFromCategoryCount(categoryId = 0, filter = ''): Observable<{ count: number }> {
    return this.api.get<{ count: number }>(`Goods/FromCategory/${categoryId}/Count?filter=${filter}`);
  }

  getGoods(page: number, limit: number): Observable<IProductCard[]> {
    return this.api.get<IProductCard[]>(`Goods/GetGoods?page=${page}&limit=${limit}`);
  }

  searchGoods(name: string): Observable<IProductCard[] | any> {
    return this.api
      .get<IProductCard[]>(`Goods/SearchGoods?&name=${name}`)
      .pipe(map((result) => (result ? result : [])));
  }

  getGoodsByCategory(page: number, limit: number, categoryId: number): Observable<IProductCard[]> {
    return this.api.get<IProductCard[]>(
      `Goods/GetGoodsByCategory?page=${page}&limit=${limit}&categoryId=${categoryId}`,
    );
  }

  getBrandsAll(): Observable<IBrand[]> {
    return this.api.get<IBrand[]>(`Brands?limit=999&parentId=0`);
  }

  getBrandsCount(name: string, parentId: number): Observable<any> {
    // TODO: fix <any>
    return this.api
      .get<any>(`Brands/Count?filter=${name}&parentId=${parentId}`)
      .pipe(map((result: any) => (result && result.count ? result.count : 0)));
  }

  getBrands(queryParams: IBrandParams): Observable<IBrand[] | any> {
    return this.api.get<IBrand[]>(`Brands`, queryParams);
  }

  getBrand(id: number): Observable<IBrand> {
    return this.api.get<IBrand>(`Brands/${id}`);
  }

  getChildBrands(parentId: number): Observable<IBrand[]> {
    return this.api.get<IBrand[]>(`Brands?parentId=${parentId}`);
  }

  findBrandsByName(name: string): Observable<IBrand[]> {
    return this.api.get<IBrand[]>(`Brands?filter=${name}`).pipe(map((result) => (result ? result : [])));
  }

  createBrand(body: IBrandCreate): Observable<IBrand | {}> {
    return this.api.post(`Brands`, body);
  }

  updateBrand(id: number, body: IBrandCreate): Observable<IBrand | {}> {
    return this.api.put(`Brands/${id}`, body);
  }

  deleteBrand(id: number): Observable<IBrand | {}> {
    return this.api.delete(`Brands/${id}`);
  }

  uploadGoodImage(file: FormData, goodId: number) {
    return this.api.post(`Goods/${goodId}/Image`, file, true);
  }

  removeGoodImage(goodId: number, imageId: number) {
    return this.api.delete(`Goods/${goodId}/Image/${imageId}`);
  }

  getCreateGood(formValue: IFormlyField): Observable<IProductCard> {
    return this.api.post('Goods', formValue);
  }

  getUpdateGood(id: number | string, formValue: IFormlyField): Observable<IProductCard> {
    return this.api.put(`Goods/${id}`, formValue);
  }

  getFormFieldsData(action: string): Observable<IFieldsKinds[]> {
    return this.api.get<IFieldsKinds[]>(action).pipe(
      map((items: IFieldsKinds[] | any) => {
        if (items.data) {
          return items.data;
        } else {
          return items.map((item: IFieldsKinds) => ({
            id: item.id,
            name: item.name ? item.name : item.value,
          }));
        }
      }),
    );
  }

  public getCategoryList(): Observable<Array<ICategories>> {
    return this.api.get<Array<ICategories>>(`Categories?parentId=0&page=1&limit=999`);
  }

  getCategoryById(id: number): Observable<ICategories> {
    return this.api.get(`Categories/${id}`);
  }

  getFindCategoryByCode(code: string): Observable<ICategories[]> {
    return this.api.get(`categories/FindCategoryByCode?code=${code}`);
  }

  getChildBrandsByParentId(parentId: number): Observable<IBrands[]> {
    return this.api.get<IBrands[]>(`Brands?parentId=${parentId}`);
  }

  getGoodsById(id: number | string): Observable<IProductCard> {
    return this.api.get<IProductCard>(`Goods/${id}`);
  }

  getMinLotsTemplateFile(): Observable<HttpEvent<File>> {
    return this.api.get('Upload/GetRestsTemplate', {}, TypeResponse.blob, true);
  }

  getRevaluationTemplateFile(): Observable<HttpEvent<File>> {
    return this.api.get('PriceTrends/UploadFromFile/Template', {}, TypeResponse.blob, true);
  }

  getGoodSettings(queryParams: IGoodSettingsParams): Observable<TableResponseDto<IGoodSettings>> {
    return this.api.get<TableResponseDto<IGoodSettings>>(`GoodSettings`, queryParams);
  }

  getGoodSettingsFromSourse(
    departmentId: number,
    queryParams: IGoodSettingsParams,
  ): Observable<TableResponseDto<IGoodForSettings>> {
    return this.api.get<TableResponseDto<IGoodForSettings>>(`GoodSettings/${departmentId}/SourceGoods`, queryParams);
  }

  deleteGoodSettings(id: number) {
    return this.api.delete(`GoodSettings/${id}`).pipe();
  }

  deleteGoodSettingsByGoodId(goodId: number, departmentId: number) {
    return this.api.delete(`GoodSettings/${departmentId}/Good/${goodId}`);
  }

  addGoodSettings(departmentId: number, product: IGoodForSettings) {
    const payload = {
      departmentId,
      goodId: product.id,
      vendorCode: product.vendorCode,
    }
    return this.api.post(`GoodSettings`, payload).pipe();
  }

  addGoodSettingsLabel(goodId: number, labelId: number) {
    return this.api.put(`GoodSettings/Labels/${goodId}/Bind`, labelId );
  }

  removeGoodSettingsLabel(goodId: number, labelId: number) {
    return this.api.put(`GoodSettings/Labels/${goodId}/UnBind`, labelId );
  }

  getGoodSettingsTemplateFile(): Observable<HttpEvent<File>> {
    return this.api.get('Upload/GoodSettingsTemplate', {}, TypeResponse.blob, true);
  }

  getGoodSettingsExport(departmentId: number): Observable<HttpEvent<File>> {
    return this.api.get(`GoodSettings/Export/${departmentId}`, {}, TypeResponse.blob, true);
  }

  public getUploadGoodsErrorReport(url: string): Observable<HttpEvent<File>> {
    return this.api.get(url, {}, TypeResponse.blob, true);
  }

  getWarehouseList() {
    return this.api.get<IDepartmentList[]>('Departments/Warehouse').pipe(
      map((list) => list.map((dep) => ({ value: dep.id, title: dep.name, guid: dep.guid, ...dep }))),
      map((options) => [{ value: NO_DEPARTMENT_ID, title: 'Выберите подразделение', disabled: true }, ...options]),
    );
  }

  getPriceTrends(params: IBaseTableRequest): Observable<ICollectionWithPagination<IPriceTrends>> {
    return this.api.get<ICollectionWithPagination<IPriceTrends>>('PriceTrends', params);
  }

  createPriceTrend(body: { departmentId: number; beginDate?: string }): Observable<IPriceTrends> {
    if (!body.beginDate) {
      body.beginDate = dateConvertToIso8601wTz(new Date());
    }

    return this.api.post('PriceTrends', body);
  }

  updatePriceTrend(id: number, body: { departmentId: number; beginDate?: string }): Observable<IPriceTrends> {
    if (!body.beginDate) {
      body.beginDate = dateConvertToIso8601wTz(new Date());
    }

    return this.api.put(`PriceTrends/${id}`, body);
  }

  getPriceTrendPrices(id: number, params: PriceTrendsDTO): Observable<IPriceTrendsPrice> {
    return this.api.get<IPriceTrendsPrice>(`PriceTrends/${id}`, params);
  }

  getPriceTrendSourceGoods(id: number, params: PriceTrendsDTO): Observable<TableResponseDto<IPriceTrendSource>> {
    return this.api.get<TableResponseDto<IPriceTrendSource>>(`PriceTrends/${id}/SourceGoods`, params);
  }

  addPriceTrendItem(trendDetailId: number, body: { goodId: number }) {
    return this.api.post(`PriceTrends/${trendDetailId}/Items`, body);
  }

  deletePriceTrendItem(id: number) {
    return this.api.delete(`PriceTrends/Items/${id}`);
  }

  updatePrice(itemId: number, params: Partial<IPriceUpdateReq>): Observable<IPriceUpdateReq> {
    return this.api
      .put<Partial<IPriceUpdateReq>, IPriceUpdateReq>(`PriceTrends/Items/${itemId}`, params)
      .pipe(showSuccessMessage(this.snackbar, 'Запись обновлена'));
  }

  updateGood(itemId: number, params: IGoodSettings): Observable<IGoodSettings> {
    return this.api
      .put<IGoodSettings, IGoodSettings>(`GoodSettings/${itemId}`, params);
  }

  updateVisibility(dto: GoodsSettingsPutQueryDto) {
    return this.api.put(`GoodSettings/Visibility`, dto);
  }
}
