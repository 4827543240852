import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IApiError } from '@core/web-api/shared/interfaces/api.error.interface';
import { PopupService } from '@ui/shared/services/popup.service';
import { MatDialog } from '@angular/material/dialog';
import { isArray } from 'lodash';

@Injectable()
export class ErrorHandlerService {
  public error: BehaviorSubject<IApiError | null> = new BehaviorSubject<IApiError | null>(null);
  private subscriptionList: Subscription[] = [];

  constructor(public readonly injector: Injector, private popupService: PopupService, private dialogRef: MatDialog) {}

  private get router(): Router {
    return this.injector.get(Router);
  }

  public initErrorSubscription(): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      if (this.subscriptionList.length > 0) {
        resolve();
      }

      this.error
        .pipe(filter((error: IApiError | null) => error !== null))
        .subscribe((error: IApiError | null) => this.resolveError(error as IApiError));

      resolve();
    });
  }

  private resolveError(error: IApiError): void {
    if (!error.Code || error.Code === 0) {
      switch (error.Status) {
        case 502:
          this.error_0_502();
          break;
        case 503:
          this.error_503();
          break;
        case 400:
          this.error_400(error);
          break;
        case 403:
          this.error_403();
          break;
        case 404:
          this.error_404();
          break;
        case 500:
          this.error_500(error);
          break;
        default:
          this.defaultError(error);
          break;
      }

      return;
    }

    if (error.Code > 0) {
      switch (error.Code) {
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
          this.error_10_16();
          break;
        case 17:
          this.error_17(error);
          break;
        default:
          this.defaultError(error);
          break;
      }
    }
  }

  private error_0_502(): void {
    this.dialogRef.closeAll();
    this.router.navigate(['error']);
  }

  private error_503(): void {
    this.router.navigate(['error', 'server-error']);
  }

  private error_500(error: IApiError): void {
    this.popupService.error(
      error.InnerError ? (error.InnerError.error ? error.InnerError.error : error.Error) : error.Error,
    );
  }

  private error_400(error: IApiError): void {
    const innerError = error.InnerError;

    if (innerError && innerError.hasOwnProperty('error') && innerError.error) {
      this.popupService.error(innerError.error);
    } else if (innerError && innerError.hasOwnProperty('Error') && innerError.Error) {
      const code = innerError.Code;

      if (code && code > 9 && code < 16) {
        this.error_10_16();
        return;
      }

      this.popupService.error(innerError.Error);
    } else if (innerError && innerError.hasOwnProperty('errors') && innerError.errors) {
      let errorsMessage = '';
      const errors = innerError.errors;
      for (const errorMessage of Object.values(errors)) {
        errorsMessage += `${isArray(errorMessage) ? errorMessage.join('; ') : errorMessage}`;
      }
      this.popupService.error(errorsMessage);
    } else {
      this.popupService.error(error.Error);
    }
  }

  private error_403(): void {
    this.popupService.error('Доступ запрещён');
  }

  private error_404(): void {
    // TODO: Временно отключено из-за багов в системе Google Tag Manager.
    // this.router.navigate(['not-found']);
  }

  private error_10_16(): void {
    this.dialogRef.closeAll();
    this.router.navigate(['error', 'service-error']);
  }

  private error_17(error: IApiError): void {
    this.popupService.warning(error.Error);
  }

  private defaultError(error: IApiError): void {
    this.popupService.error(error.Error);
  }
}
