import { IHeader } from '@ui/components/header/shared/interfaces/header.interface';
import { RoleAccessEnum } from '@app/@core/shared/enums/role-access.enum';

export const HEADER_ITEMS_SETTINGS: IHeader[] = [
  {
    label: 'Подразделения',
    path: '/settings/departments',
    action: RoleAccessEnum.VIEW_DEPARTMENTS,
  },
  {
    label: 'Ценообразование',
    path: '/settings/pricing',
    action: RoleAccessEnum.VIEW_PRICING,
  },
  {
    label: 'Товарный запас',
    path: '/settings/rests',
    action: RoleAccessEnum.VIEW_RESTS,
  },
  {
    label: 'Параметры товаров',
    path: '/settings/selection-parameters',
    action: RoleAccessEnum.VIEW_SELECTIONPARAMETERS,
  },
  //   {
  //     label: 'Редактирование справочников',
  //     path: '/settings/directory-editing'
  //   }
  // TODO:  скрыть блоки, временно, до реализации на бэке_SVT-212
];
