<div class="date-range-picker-content">
  <mat-calendar
    class="date-range-picker-calendar"
    #pickerFrom
    cdkTrapFocus
    [selected]="datePicker.dateFrom"
    (selectedChange)="onDateFromSelected($event)"
    [dateClass]="getDateClass('from')"
    [headerComponent]="header"
  >
  </mat-calendar>

  <mat-calendar
    class="date-range-picker-calendar"
    [minDate]="datePicker.dateFrom"
    [selected]="datePicker.dateTo"
    (selectedChange)="onDateToSelected($event)"
    [headerComponent]="header"
    [dateClass]="getDateClass('to')"
    #pickerTo
  >
  </mat-calendar>
</div>
