<div class="body" [class.error]="isMinimalSumToOrderError">
  <div class="left">
    <span class="title">Сумма с НДС</span>
    <span class="sum-with-nds">
      {{order.sum | number: '1.0-2'}} ₽
    </span>

    <div class="sum-without-nds-block">
      <span class="title">Без НДС:</span>
      <span class="sum">{{order.sumWithoutVat | number: '1.0-2'}} ₽</span>
    </div>
  </div>

  <div class="right">
    <div class="min-sum-error" *ngIf="isMinimalSumToOrderError">
      <mat-icon svgIcon="info" class="__promo"></mat-icon>
      <span class="error-title">
        Минимальная сумма заказа — {{order.minOrderSum | number: '1.0-2'}} ₽
      </span>
    </div>

    <div class="action-button-wrapper" *ngIf="defaultAction">
      <div class="action-button">
        <app-button type="flat"
                    (click)="onActionBtnClick()">
          {{defaultAction.caption}}
        </app-button>
      </div>
    </div>
  </div>
</div>
