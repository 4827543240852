export enum DateRangePeriodType {
  ALL_TIME = 'all_time',
  WEEK = 'week',
  TWO_WEEKS = 'two_weeks',
  MONTH = 'month',
  SPECIFIED_DAY = 'specified_day',
  SPECIFIED_PERIOD = 'specified_period',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
}
