import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-title-collapse-wrapper',
  template: ` <div class="title-collapse-wrapper">
    <mat-icon
      *ngIf="showContent; else hideCollapse"
      (click)="changeCollapse()"
      class="title-collapse-wrapper__control"
      svgIcon="plus"
    ></mat-icon>
    <ng-template #hideCollapse>
      <mat-icon (click)="changeCollapse()" class="title-collapse-wrapper__control" svgIcon="minus"></mat-icon>
    </ng-template>
    <label class="title-collapse-wrapper__text" (click)="changeCollapse()">{{ to.label }}</label>
    <div [hidden]="showContent">
      <ng-template #fieldComponent></ng-template>
    </div>
  </div>`,
})
export class FormlyTitleCollapseWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { static: true, read: ViewContainerRef }) fieldComponent!: ViewContainerRef;
  showContent = false;

  changeCollapse() {
    this.showContent = !this.showContent;
  }
}
