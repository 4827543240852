import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-input',
  template: `
    <div class="input-wrap">
      <label class="input-label">
        {{ field?.templateOptions?.label }}
        <span class="required-postfix" *ngIf="field.templateOptions?.required">*</span>
      </label>
      <div class="input__control">
        <mat-form-field
          class="mat-input-custom mat-textarea"
          [class.--border-transparent]="field?.templateOptions?.borderTransparent"
        >
          <textarea
            class="input"
            rows="4"
            #input
            [ngClass]="field?.templateOptions?.classInner"
            matInput
            [value]="field?.templateOptions?.defaultValue || ''"
            [formControl]="formControl"
            [placeholder]="field?.templateOptions?.placeholder"
            [readonly]="field?.templateOptions?.readonly"
            >{{ field?.templateOptions?.defaultValue || '' }}</textarea
          >
          <mat-error *ngIf="formControl.hasError('required')">обязательное поле для заполнения</mat-error>
          <ng-content></ng-content>
        </mat-form-field>
      </div>
    </div>
  `,
})
export class TextareaComponent extends FieldType {}
