import { IHeader } from '@ui/components/header/shared/interfaces/header.interface';
import { RoleAccessEnum } from '@core/shared/enums/role-access.enum';
import { HeaderTourStepsEnum } from '@app/@core/shared/enums/header-tour-steps.enum';

export const HEADER_ITEMS_USER: IHeader[] = [
  {
    label: 'Профиль пользователя',
    path: '/user/profile',
  },
  {
    label: 'Профиль организации',
    path: '/user/organization-profile',
  },
  {
    label: 'Состояние счёта',
    path: '/user/balance',
    text: 'Управление счетом ЗАПОКУПКИ.РФ',
    step: HeaderTourStepsEnum.wallet,
    action: RoleAccessEnum.VIEW_ORGANISATION_PAYMENT_ACCOUNT,
  },
  {
    label: 'Уведомления',
    path: '/user/notifications',
  },
];
