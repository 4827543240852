import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-input-file',
  template: ` <input type="file" [formControl]="formControl" [formlyAttributes]="field" /> `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFileComponent extends FieldType {}
