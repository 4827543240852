import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-input',
  template: `
    <div
      class="input-wrap"
      [class.joyride-placeholder-wrap]="
        field?.templateOptions?.attributes && field?.templateOptions?.attributes?.joyrideStep
      "
    >
      <div
        *ngIf="field?.templateOptions?.attributes && field?.templateOptions?.attributes?.joyrideStep"
        class="joyride-placeholder __p10"
        [joyrideStep]="field?.templateOptions?.attributes && field?.templateOptions?.attributes?.joyrideStep"
        [title]="field?.templateOptions?.attributes && field?.templateOptions?.attributes?.joyrideTitle"
      ></div>
      <label class="input-label">
        {{ field?.templateOptions?.label }}
        <span class="required-postfix" *ngIf="field.templateOptions?.required">*</span>
      </label>
      <div class="input__control">
        <mat-form-field
          class="mat-input-custom"
          [class.--border-transparent]="field?.templateOptions?.borderTransparent"
        >
          <input
            class="input"
            #input
            [ngClass]="field?.templateOptions?.classInner"
            matInput
            [value]="field?.templateOptions?.defaultValue || ''"
            [formControl]="formControl"
            [formlyAttributes]="field"
            [type]="field?.templateOptions?.type || 'text'"
            [placeholder]="field?.templateOptions?.placeholder"
            [readonly]="field?.templateOptions?.readonly"
          />
          <mat-error *ngIf="formControl.hasError('required')">обязательное поле для заполнения</mat-error>
          <ng-content></ng-content>
        </mat-form-field>
        <span class="filters__price-count" *ngIf="field.templateOptions?.priceCount" [hidden]="input.value">
          {{ field.templateOptions?.priceCount }}
        </span>
      </div>
    </div>
  `,
})
export class InputComponent extends FieldType {
  type = 'text';
}
