import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { OrderIncidentStatus } from '@core/shared/interfaces/order.interface';

@Component({
  selector: 'app-shipment-incident-status',
  templateUrl: './shipment-incident-status.component.html',
  styleUrls: ['./shipment-incident-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentIncidentStatusComponent implements OnInit {
  @Input() status!: OrderIncidentStatus;

  OrderIncidentStatus = OrderIncidentStatus;

  constructor() {}

  ngOnInit() {}
}
