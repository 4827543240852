import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { ITreeData } from '@ui/components/abstract-tree/shared/tree.interface';
import { BehaviorSubject, EMPTY, merge, Observable } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import { BaseDynamicDatabase } from './base-dynamic-db';
import { DynamicFlatNode } from './dynamic-flat-node.model';

@Injectable({ providedIn: 'root' })
export class DynamicDataSource<T extends ITreeData> implements DataSource<DynamicFlatNode> {
  private dataChange$ = new BehaviorSubject<DynamicFlatNode[]>([]);
  dataChanged$ = this.dataChange$.asObservable();

  constructor(private treeControl: FlatTreeControl<DynamicFlatNode>, private database: BaseDynamicDatabase<T>) {}

  get data(): DynamicFlatNode[] {
    return this.dataChange$.value;
  }

  set data(value: DynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange$.next(value);
  }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if ((change as SelectionChange<DynamicFlatNode>).added || (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange$).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {}

  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  toggleNode(node: DynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);
    expand ? this.getChildrenNodes(node, index) : this.removeChildNodes(node, index);
  }

  private getChildrenNodes(node: DynamicFlatNode, nodeIndex: number) {
    node.isLoading.next(true);
    this.database
      .getChildren(node.id)
      .pipe(
        finalize(() => node.isLoading.next(false)),
        filter((children) => !!children && nodeIndex > -1),
        catchError(() => {
          this.treeControl.toggle(node);
          return EMPTY;
        }),
      )
      .subscribe((children) => this.addChildNodes(children, node, nodeIndex));
  }

  private addChildNodes(children: T[], node: DynamicFlatNode, nodeIndex: number) {
    const nodes = children.map(
      (item) => new DynamicFlatNode(item.name, item.id, node.level + 1, item.expandable, item),
    );
    this.data.splice(nodeIndex + 1, 0, ...nodes);
    this.dataChange$.next(this.data);
  }

  private removeChildNodes(node: DynamicFlatNode, nodeIndex: number) {
    let count = 0;
    for (let i = nodeIndex + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
    this.data.splice(nodeIndex + 1, count);
    this.dataChange$.next(this.data);
  }
}
