<div [class]="'quantity __' + theme" [class.disabled]="disabled">
  <button class="quantity__btn __reduce" (click)="onDecrease()" [disabled]="disabled" (click)="onQuantityBtnClick()">-</button>
  <input
    class="quantity__input"
    [class.__invalid]="inputControl.invalid"
    type="number"
    [formControl]="inputControl"
    (blur)="onQuantityInputBlur()"
  />
  <button class="quantity__btn __increase" (click)="onIncrease()" [disabled]="disabled" (click)="onQuantityBtnClick()">+</button>
</div>
