<label *ngIf="field.templateOptions" class="input-label">
  {{ field?.templateOptions?.label }}
  <span *ngIf="field.templateOptions?.required" class="required-postfix">*</span>
</label>
<mat-form-field (click)="showDatepicker()" class="datepicker" floatLabel="never">
  <input
    [formControl]="formControl"
    [formlyAttributes]="field"
    [matDatepicker]="datepicker"
    disabled
    matInput
    placeholder=""
    required
  />
  <mat-datepicker-toggle [disabled]="field?.templateOptions?.disabled" [for]="datepicker" matSuffix>
    <mat-icon matDatepickerToggleIcon svgIcon="arrow-down"></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #datepicker [calendarHeaderComponent]="header" [disabled]="field?.templateOptions?.disabled">
  </mat-datepicker>
</mat-form-field>
