<ul class="pagination">
  <li (click)="selectPage(1, $event)" [class.active]="pageIndex === 1" class="pagination__item">
    1
  </li>
  <li *ngIf="pageIndex - range > 1" class="pagination__item __disabled">...</li>
  <ng-container *ngFor="let page of pages$ | async; trackBy: identify">
    <li
      (click)="selectPage(page, $event)"
      *ngIf="page > 1 && page < totalPages - 1"
      [class.active]="page === pageIndex"
      class="pagination__item"
    >
      {{ page }}
    </li>
  </ng-container>

  <li *ngIf="pageIndex + range < totalPages" class="pagination__item __disabled">...</li>

  <ng-container *ngIf="totalPages > 1">
    <li
      *ngIf="totalPages != 2"
      (click)="selectPage(totalPages - 1, $event)"
      [class.active]="pageIndex == totalPages - 1"
      class="pagination__item"
    >
      {{ totalPages - 1 }}
    </li>

    <li (click)="selectPage(totalPages, $event)" [class.active]="pageIndex == totalPages" class="pagination__item">
      {{ totalPages }}
    </li>
  </ng-container>

  <li
    (click)="selectPage(pageIndex + 1, $event)"
    *ngIf="pageIndex !== totalPages"
    [class.disabled]="pageIndex == totalPages"
    class="pagination__item"
  >
    <mat-icon class="__c5 __s12 __mt14" svgIcon="arrow-right"></mat-icon>
  </li>
</ul>
