import { Injectable } from '@angular/core';
import { WebApiService } from '@app/@core/web-api/shared/services/web-api.service';
import { showSuccessMessage } from '@core/shared/rxjs-operators/show-success-message';
import { PopupService } from './popup.service';
import { Observable } from 'rxjs';
import { ICategoryCountParams } from '../interfaces/category-count-params.interface';
import { ICategoryCreate } from '../interfaces/category-create.interface';
import { ICategoryParams } from '../interfaces/category-params.interface';
import { ICategory } from '../interfaces/category.interface';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private readonly page = 1;
  private readonly limit = 100;

  constructor(private api: WebApiService, private snackbar: PopupService) {}

  getCategories(params: ICategoryParams = {}): Observable<ICategory[]> {
    params = {
      ...params,
      page: params.page || this.page,
      limit: params.limit || this.limit,
    };
    return this.api.get<ICategory[]>('Categories', params);
  }

  getDepartmentCategories(departmentId: number, params: ICategoryParams = {}): Observable<ICategory[]> {
    params = {
      ...params,
      page: params.page || this.page,
      limit: params.limit || this.limit,
    };
    return this.api.get<ICategory[]>(`Departments/${departmentId}/CategoryRatios`, params);
  }

  getCategory(id: number): Observable<ICategory> {
    return this.api.get<ICategory>(`Categories/${id}`);
  }

  createCategory(body: ICategoryCreate): Observable<ICategory> {
    return this.api
      .post<ICategoryCreate, ICategory>('Categories', body)
      .pipe(showSuccessMessage(this.snackbar, 'Категория добавлена'));
  }

  updateCategory(id: number, body: ICategoryCreate): Observable<ICategory> {
    return this.api
      .put<ICategoryCreate, ICategory>(`Categories/${id}`, body)
      .pipe(showSuccessMessage(this.snackbar, 'Категория обновлена'));
  }

  deleteCategory(id: number): Observable<void> {
    return this.api.delete<void, void>(`Categories/${id}`).pipe(showSuccessMessage(this.snackbar, 'Категория удалена'));
  }

  getCategoriesCount(params: ICategoryCountParams): Observable<{ count: number }> {
    return this.api.get<{ count: number }>(`Categories/Count`, params);
  }
}
