import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ViewChild,
  ElementRef,
  Self,
  ChangeDetectorRef,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFileUploadDialogData } from '@ui/modules/file-upload-dialog/shared/file-upload-dialog-data';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FileDownloadUploadService } from '@core/web-api/shared/services/file-download-upload.service';
import { UploadFileDto } from '@core/web-api/shared/dto/upload-file.dto';
import { IUploadFileRes } from '@app/pages/shared/commodity-nomenclature/shared/upload-file-res.interface';
import { IFileUploadProgress } from '@core/web-api/shared/interfaces/file-job-progress.interface';
import {
  IFileUploadDialogResult,
  IFileUploadIncidentResult
} from '@ui/modules/file-upload-dialog/shared/file-upload-dialog-result';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroy],
})
export class FileUploadDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('uploadFileHiddenInput') uploadFileHiddenInput!: ElementRef;
  @ViewChild('selectFile') selectFileTemplate!: TemplateRef<any>;
  @ViewChild('uploadingProgress') uploadingProgressTemplate!: TemplateRef<any>;
  @ViewChild('uploadSuccess') uploadSuccessTemplate!: TemplateRef<any>;
  @ViewChild('uploadError') uploadErrorTemplate!: TemplateRef<any>;

  public form: UntypedFormGroup;
  public uploadProgress: IFileUploadProgress<undefined | IUploadFileRes>;
  public currentTemplate: TemplateRef<any>;
  public uploadErrorText = '';

  private uploadResult: IFileUploadDialogResult;

  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFileUploadDialogData,
    private fb: UntypedFormBuilder,
    @Self() private componentDestroyed$: NgOnDestroy,
    private fileDownloadUploadService: FileDownloadUploadService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.form = fb.group({
      file: [null],
    });

    this.dialogRef
      .beforeClosed()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((_) => {
        this.dialogRef.close(this.uploadResult);
      });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.setCurrentTemplate(this.selectFileTemplate);
  }

  public close() {
    this.dialogRef.close();
  }

  public onFileSelected(event: any) {
    this.setCurrentTemplate(this.uploadingProgressTemplate);

    const file: File = (event.target as HTMLFormElement).files[0];
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const dto: UploadFileDto = {
      file: formData,
      url: this.data.url,
    };

    this.fileDownloadUploadService
      .upload(dto)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (res) => {
          this.uploadProgress = res;
          this.cdRef.markForCheck();

          if (res.response && res.response.succeed) {
            this.setCurrentTemplate(this.uploadSuccessTemplate);
            this.uploadResult = {
              succeed: res.response.succeed,
              data: res.response
            };

            setTimeout(() => {
              this.dialogRef.close();
            }, 2000);
          }
        },
        (err) => {
          this.uploadErrorText = err;
          this.setCurrentTemplate(this.uploadErrorTemplate);
        },
      );
  }

  public openFileChooseDialog() {
    this.uploadFileHiddenInput.nativeElement.click();
  }

  private setCurrentTemplate(template: TemplateRef<any>) {
    this.currentTemplate = template;
    this.cdRef.detectChanges();
  }
}
