import {Injectable} from '@angular/core';
import {Observable, Subject, BehaviorSubject} from 'rxjs';
import {DEVICES} from '@app/@core/shared/enums/devices.enum';
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WindowSizeService {
  get size$(): Observable<DEVICES> {
    return this.sizeSub.asObservable().pipe(distinctUntilChanged());
  }

  private sizeSub: BehaviorSubject<DEVICES>;

  constructor() {
    this.sizeSub = new BehaviorSubject<DEVICES>(DEVICES.DESKTOP);
  }

  updateSize(size: DEVICES): void {
    this.sizeSub.next(size);
  }
}
