import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, LOCALE_ID } from '@angular/core';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductPriceComponent implements OnInit {

  private _price: number;
  @Input() set price(value: number) {
    this._price = value;

    if (value) {
      let formattedNumber: string | string[] = formatNumber(value, 'RU-ru', '1.2-4');

      if (formattedNumber) {
        formattedNumber = formattedNumber.split(',');
        this.rouble = formattedNumber[0];
        this.penny = formattedNumber[1];
      }
    }
  }
  get price() {
    return this._price;
  }

  public penny = '00';
  public rouble = '0';

  constructor(@Inject(LOCALE_ID) locale: string) { }

  ngOnInit() {
  }

}
