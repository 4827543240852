import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl } from '@angular/forms';
import {
  APPLICATION_TABLE_COLUMNS,
  APPLICATION_TABLE_DATA,
} from '@ui/components/add-application/shared/application-table.data';

@Component({
  selector: 'app-add-application',
  templateUrl: './add-application.component.html',
  styleUrls: ['./add-application.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddApplicationComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns: string[] = APPLICATION_TABLE_COLUMNS;
  dataSource = new MatTableDataSource(APPLICATION_TABLE_DATA);
  goodsOnPageControl = new UntypedFormControl();
  maxGoodsCount = 200;
  pageOffset = 1;
  goodsOnPageLimit = 20;

  constructor(public dialogRef: MatDialogRef<AddApplicationComponent>) {}

  ngOnInit() {
    this.dataSource.sort = this.sort;
  }

  onPageChange(offset: number): void {
    this.pageOffset = offset;
  }

  closeModalWindow(): void {
    this.dialogRef.close();
  }
}
