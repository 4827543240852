import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '@core/shared/interfaces/user.interface';
import { WebApiService } from '@core/web-api/shared/services/web-api.service';
import { OAuthService, OAuthErrorEvent, AuthConfig } from 'angular-oauth2-oidc';
import {BehaviorSubject, EMPTY, Observable, of, ReplaySubject, Subject, throwError} from 'rxjs';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import { RoleAccessEnum } from '@core/shared/enums/role-access.enum';
import { ErrorHandlerService } from '@core/shared/services/error-routing/error-handler.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { IDepartmentsCount } from '@core/shared/interfaces/departments-interface';
import { TagManagerService } from '@core/shared/services/tag-manager.service';
import { UserRoleEnum } from '@core/shared/enums/user-role.enum';
import {GlobalErrorService} from '@core/shared/services/global-error.service';
import { UUIDService } from '@core/shared/services/uuid.service';
import { FiltersStorageService } from '@core/shared/services/filters-storage.service';
import { FiltersStorageEnum } from '@core/shared/enums/filters-storage.enum';
import { ENVIRONMENT_TOKEN } from '@environments/environment.config';
import { IAppEnvironment } from '@core/shared/interfaces/app-environment';
import { ITokenResponse, ILoginInfo, } from '@app/@core/shared/interfaces';
import { StorageService } from '@app/@core/shared/services/storage.service';
import { LocalStorageKeys } from '@app/@core/shared/enums/local-storage-keys.enum';

@Injectable()
export class AuthService {
  public startGuid$ = new Subject();
  public currentUserSubject$ = new BehaviorSubject<IUser | null>(null);
  public whoamiLoaded = new BehaviorSubject<boolean>(false);
  public currentUser!: IUser;
  public isCurrentUserIsAnonymous$ = new BehaviorSubject<boolean>(false);
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();

  constructor(
    private oAuthService: OAuthService,
    private router: Router,
    private api: WebApiService,
    private readonly http: HttpClient,
    private tagManagerService: TagManagerService,
    private readonly errorService: ErrorHandlerService,
    private readonly globalErrorService: GlobalErrorService,
    private readonly uuidService: UUIDService,
    private readonly filtersStorageService: FiltersStorageService,
    @Inject(ENVIRONMENT_TOKEN) public environment: IAppEnvironment,
    private readonly storage: StorageService,
  ) {
    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.

    // TODO: Отключено временно из-за багов GTM
    // window.addEventListener('storage', (event) => {
    //   // The `key` is `null` if the event was caused by `.clear()`
    //   if (event.key !== 'access_token' && event.key !== null) {
    //     return;
    //   }

    //   this.isAuthenticatedSubject$.next(this.oAuthService.hasValidAccessToken());

    //   if (!this.oAuthService.hasValidAccessToken()) {
    //     this.login();
    //   }
    // });

    // this.oAuthService.events.subscribe((_) => {
    //   this.isAuthenticatedSubject$.next(this.oAuthService.hasValidAccessToken());
    // });

    // this.oAuthService.events
    //   .pipe(filter(e => ['token_received'].includes(e.type)))
    //   .subscribe();

    // this.oAuthService.events.pipe(filter((e) => ['session_terminated'].includes(e.type))).subscribe((e) => {
    //   this.login();
    // });

    // this.oAuthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(forceLogin = false): Promise<void> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    this.isDoneLoadingSubject$.next(true);
    return new Promise((resolve) => resolve());
  }
  //   return this.isDoneLoadingSubject$.toPromise();
  // //   return (
  //     this.oAuthService
  //       .loadDiscoveryDocument()

  //       // 1. HASH LOGIN:
  //       // Try to log in via hash fragment after redirect back
  //       // from IdServer from initImplicitFlow:
  //       .then(() => this.oAuthService.tryLogin())

  //       .then(() => {
  //         if (this.oAuthService.hasValidAccessToken()) {
  //           return Promise.resolve();
  //         }

  //         // 2. SILENT LOGIN:
  //         // Try to log in via silent refresh because the IdServer
  //         // might have a cookie to remember the user, so we can
  //         // prevent doing a redirect:
  //         return this.oAuthService
  //           .silentRefresh()
  //           .then(() => Promise.resolve())
  //           .catch((result) => {
  //             // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
  //             // Only the ones where it's reasonably sure that sending the
  //             // user to the IdServer will help.
  //             const errorResponsesRequiringUserInteraction = [
  //               'interaction_required',
  //               'login_required',
  //               'account_selection_required',
  //               'consent_required',
  //             ];

  //             if (
  //               result &&
  //               result.reason &&
  //               errorResponsesRequiringUserInteraction.indexOf(result.reason.params.error) >= 0
  //             ) {
  //               // 3. ASK FOR LOGIN:
  //               // At this point we know for sure that we have to ask the
  //               // user to log in, so we redirect them to the IdServer to
  //               // enter credentials.
  //               //
  //               // Enable this to ALWAYS force a user to login.
  //               if (forceLogin) {
  //                 this.clearSavedTableFilters();
  //                 this.oAuthService.initImplicitFlow(window.location.pathname);
  //               } else {
  //                 return Promise.resolve();
  //               }
  //             }

  //             // We can't handle the truth, just pass on the problem to the
  //             // next handler.
  //             return Promise.reject(result);
  //           });
  //       })

  //       .then(() => {
  //         this.isDoneLoadingSubject$.next(true);

  //         // Check for the strings 'undefined' and 'null' just to be sure. Our current
  //         // login(...) should never have this, but in case someone ever calls
  //         // initImplicitFlow(undefined | null) this could happen.

  //       })
  //       .catch((err: HttpErrorResponse) => this.authEndpointUnAvailable(err))
  //   );
  // }

  public login(info: ILoginInfo): Observable<ITokenResponse> {
    this.clearSavedTableFilters();
    const payload = {
      ...info,
      client_id: 'liri',
      grant_type: 'password',
    };
    const params = new HttpParams({
      fromObject: payload,
    });

    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

    return this.http.post<ITokenResponse>('https://auth.dev.liri.winsolutions.ru/connect/token', params, { headers });
  }

  userHasAbility(action: RoleAccessEnum): Observable<boolean> {
    return this.currentUserSubject$.pipe(
      filter((user) => !!user),
      switchMap((user) => {
        if (!action) {
          return of(true);
        }

        if (user) {
          return of(user.can.some((item) => item.toLowerCase() === action.toLowerCase()));
        } else {
          return EMPTY;
        }
      }),
    );
  }

  userHasRole(role: UserRoleEnum) {
    return this.currentUser.roles.some((r) => r.indexOf(role) !== -1);
  }

  public loadCurrentUser(): Observable<IUser> {
    this.isCurrentUserIsAnonymous$.next(false);

    return this.api.get<IUser>(`Users/WhoAmI`).pipe(
      tap((user) => {
        if (user.roles.indexOf('dd_anonym') !== -1) {
          if (!this.uuidService.isAnonymousUUIDExists()) {
            this.uuidService.createAnonymousUUID();
          }

          this.isCurrentUserIsAnonymous$.next(true);
        }

        this.currentUser = user;
        this.currentUserSubject$.next(user);
        this.whoamiLoaded.next(true);
      }),
      catchError(err => {
        this.whoamiLoaded.next(true);
        this.globalErrorService.globalFatalErrorHappened = true;
        this.globalErrorService.globalFatalErrorHappened$.next(true);
        return throwError(err);
      })
    );
  }

  checkUserCADepartments(): Observable<IDepartmentsCount> {
    return this.currentUserSubject$.pipe(
      filter((user) => !!user),
      switchMap((user) => this.api.get<IDepartmentsCount>(`Departments/Count`, { contragentId: user!.contragent.id })),
    );
  }

  public loadDiscoveryDocumentAndInitLogin() {
    this.oAuthService
      .loadDiscoveryDocument()
      .then(() => {
        this.oAuthService.initImplicitFlow(window.location.pathname);
      })
      .catch((err: HttpErrorResponse) => this.authEndpointUnAvailable(err));
  }

  private authEndpointUnAvailable(err: HttpErrorResponse | OAuthErrorEvent): void {
    if (err instanceof HttpErrorResponse) {
      this.errorService.error.next({
        Status: 503,
        Code: 0,
        Error: err.url as string,
        InnerError: err.error,
      });
    } else if (err instanceof OAuthErrorEvent) {
      this.isDoneLoadingSubject$.next(true);
    }
  }

  private clearSavedTableFilters() {
    this.filtersStorageService.clearFilters(FiltersStorageEnum.SHIPMENTS);
    this.filtersStorageService.clearFilters(FiltersStorageEnum.ORDERS);
  }

  public logout() {
    this.clearSavedTableFilters();
    this.storage.removeItem(LocalStorageKeys.token);
    this.currentUser = undefined;
    this.currentUserSubject$.next(undefined);
    this.isCurrentUserIsAnonymous$.next(true);
    this.router.navigate(['/']);
  }
}
