import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Self } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { takeUntil } from 'rxjs/operators';
import { NgOnDestroy } from '@core/shared/services/destroy.service';

@Component({
  selector: 'app-mat-datepicker-header',
  templateUrl: './mat-datepicker-header.component.html',
  styleUrls: ['./mat-datepicker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroy],
})
export class MatDatepickerHeaderComponent<D> {
  constructor(
    @Self() private componentDestroyed$: NgOnDestroy,
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    private cdRef: ChangeDetectorRef,
  ) {
    calendar.stateChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => cdRef.markForCheck());
  }

  get periodLabel() {
    return this.dateAdapter
      .format(this.calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked() {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
  }

  nextClicked() {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
  }
}
