import { IOrder, IOrderAction } from '@core/shared/interfaces/order.interface';
import { Observable } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { ISimpleTextAreaDialogOutputData } from '@ui/components/simple-textarea-dialog/shared/simple-text-area-dialog-output.data';
import { ISimpleTextAreaDialogData } from '@ui/components/simple-textarea-dialog/shared/simple-text-area-dialog.data';
import { SimpleTextareaDialogComponent } from '@ui/components/simple-textarea-dialog/simple-textarea-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  IActionsMenuListItem,
  IActionsMenuListItemIcon,
} from '@ui/modules/actions-menu/actions-menu-list-item.interface';
import { IShipment } from '@core/shared/interfaces/shipment.interface';

@Injectable()
export class BaseActionsService {
  constructor(protected dialog: MatDialog) {}

  public openRejectDialog(
    item: IOrder | IShipment,
    dialogData: ISimpleTextAreaDialogData,
  ): Observable<ISimpleTextAreaDialogOutputData> {
    return this.dialog
      .open(SimpleTextareaDialogComponent, {
        width: '565px',
        panelClass: 'common-dialog',
        data: dialogData,
      })
      .afterClosed();
  }

  public mapActionsToListItems(
    actions: IOrderAction<any>[],
    icons: IActionsMenuListItemIcon<any>[],
  ): IActionsMenuListItem[] {
    return actions.map((action) => {
      const foundIconForAction = icons.find((icon) => icon.code.toLowerCase() === action.code.toLowerCase());

      const listItem: IActionsMenuListItem = {
        ...action,
        icon: foundIconForAction ? foundIconForAction.icon : '',
      };

      return listItem;
    });
  }
}
