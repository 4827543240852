import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IProductLabelComponentItem } from '@ui/components/product-labels/shared/product-label.interface';

@Component({
  selector: 'app-product-label',
  templateUrl: './product-label.component.html',
  styleUrls: ['./product-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductLabelComponent implements OnInit {

  @Input() label: IProductLabelComponentItem;

  @Input() labelStyle: 'small' | 'big' = 'small';

  constructor() { }

  ngOnInit() {
  }

}
