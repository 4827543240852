import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-fader',
  templateUrl: './fader.component.html',
  styleUrls: ['./fader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaderComponent implements OnInit {
  @Input() public text: string;
  constructor() {}

  public ngOnInit(): void {}
}
