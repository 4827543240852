export function dateConvertToIso8601wTz(date: Date): string {
  const tz = getTimeZone(date);
  const formattedDate = getDateTime(date);

  return `${formattedDate}${tz}`;
}

function getTimeZone(date: Date): string {
  var timezone_offset_min = date.getTimezoneOffset();
  let offset_hrs = Math.abs(timezone_offset_min/60).toString();
  let offset_min = Math.abs(timezone_offset_min%60).toString();
  let timezone_standard: string;

  if (+offset_hrs < 10)
    offset_hrs = `0${offset_hrs}`;

  if (+offset_min < 10)
    offset_min = `0${offset_min}`;

  // Add an opposite sign to the offset
  // If offset is 0, it means timezone is UTC
  if (timezone_offset_min < 0)
    timezone_standard = `+${offset_hrs}:${offset_min}`;
  else if (timezone_offset_min > 0)
    timezone_standard = `-${offset_hrs}:${offset_min}`;
  else if (timezone_offset_min == 0)
    timezone_standard = 'Z';

  return timezone_standard;
}

function getDateTime(date: Date): string {
  let current_date = date.getDate().toString();
  let current_month = (date.getMonth() + 1).toString();
  let current_year = date.getFullYear().toString();
  let current_hrs = date.getHours().toString();
  let current_mins = date.getMinutes().toString();
  let current_secs = date.getSeconds().toString();

  // Add 0 before date, month, hrs, mins or secs if they are less than 0
  const day: string = +current_date < 10 ? current_date.padStart(2, '0') : current_date;
  const month: string = +current_month < 10 ? current_month.padStart(2, '0') : current_month;
  const hrs: string = +current_hrs < 10 ? current_hrs.padStart(2, '0') : current_hrs;
  const mins: string = +current_mins < 10 ? current_mins.padStart(2, '0') : current_mins;
  const secs: string = +current_secs < 10 ? current_secs.padStart(2, '0') : current_secs;

  // Current dateTime
  // String such as 2016-07-16T19:20:30
  return `${current_year}-${month}-${day}T${hrs}:${mins}:${secs}`;
}
