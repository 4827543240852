import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-title-wrapper',
  template: ` <div class="title-wrapper">
    <label class="title-wrapper__text">{{ to.label }}</label>
    <div class="title-wrapper__divider"></div>
    <ng-template #fieldComponent></ng-template>
  </div>`,
})
export class FormlyTitleWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { static: true, read: ViewContainerRef }) fieldComponent!: ViewContainerRef;
}
