<div class="dialog__header">
  <h2 mat-dialog-title>Подтверждение оплаты</h2>
  <mat-icon (click)="close()" class="dialog__icon" svgIcon="x-mark"></mat-icon>
</div>

<div class="dialog__wrapper">
  <div class="dialog__content" mat-dialog-content>
    <ng-content *ngTemplateOutlet="currentTemplate"></ng-content>
  </div>
</div>

<div class="actions" mat-dialog-actions>
  <app-button type="flat" name="Подтвердить" (click)="confirmCurrentStep()" [disabled]="form.invalid || form.disabled"></app-button>
  <app-button type="stroked-gray" name="Закрыть" (click)="close()"></app-button>
</div>

<ng-template #smsCodeConfirmStep>
  <div class="sms-confirm-step-body">
    <span class="label" *ngIf="!isBadConfirmCode">Введите код из SMS</span>
    <span class="label error" *ngIf="isBadConfirmCode">Неверный код</span>
    <span class="label error" *ngIf="isSessionExpired">Сессия истекла. Повторите отправку SMS</span>

    <div class="sms-input">
      <app-mat-input [control]="form.get('smsCode')" autocomplete="off"></app-mat-input>
      <mat-icon svgIcon="cancel-red" class="bad-code-icon" *ngIf="isBadConfirmCode"></mat-icon>
    </div>

    <ng-container *ngIf="smsCoolDown$ | async as cooldown; else smsIsSending ? smsIsSendingNow : resendSmsNotCoolDown">
      <ng-template *ngTemplateOutlet="resendSmsCoolDown; context: { $implicit: cooldown }"></ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #paymentSuccessStep>
  <div class="payment-success-body">
    <h1>Оплата подтверждена</h1>
    <mat-icon svgIcon="payment-dialog-success"></mat-icon>
  </div>
</ng-template>

<ng-template #paymentError>
  <div class="payment-error-body">
    <h1>Ошибка оплаты</h1>

    <p *ngIf="lastFatalErrorMessage">{{ lastFatalErrorMessage }}</p>
  </div>
</ng-template>

<ng-template #smsIsSendingNow>
  <span class="resend-sms disabled" matTooltip="Еще недоступно">Отправить код повторно</span>
</ng-template>

<ng-template #resendSmsNotCoolDown>
  <span class="resend-sms" (click)="resendSms()">Отправить код повторно</span>
</ng-template>

<ng-template #resendSmsCoolDown let-cooldown>
  <span class="resend-sms disabled">Отправить код повторно ({{ cooldown }} сек.)</span>
</ng-template>
