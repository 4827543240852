import { EventEmitter, Injectable } from '@angular/core';
import { IActionsMenuListItem } from '@ui/modules/actions-menu/actions-menu-list-item.interface';
import { IOrderAction, ShipmentActionEnum } from '@core/shared/interfaces/order.interface';
import { IFileUploadDialogResult } from '@ui/modules/file-upload-dialog/shared/file-upload-dialog-result';
import { ShipmentActionsHandlerService } from '@core/shared/services/order-actions-services/shipment-actions-handler.service';
import { IShipment } from '@core/shared/interfaces/shipment.interface';
import { ShipmentActionsService } from '@core/shared/services/order-actions-services/shipment-actions.service';
import { PopupService } from '@ui/shared/services/popup.service';
import { Router } from '@angular/router';

@Injectable()
export class ShipmentActionsMapperService {
  public onActionDone: EventEmitter<IOrderAction<ShipmentActionEnum>>;

  constructor(
    public readonly shipmentActionsHandlerService: ShipmentActionsHandlerService,
    private readonly shipmentActionsService: ShipmentActionsService,
    private readonly popupService: PopupService,
    private readonly router: Router
  ) {
    this.shipmentActionsHandlerService.onActionDone = this.onActionDone;
  }

  // tslint:disable-next-line:cyclomatic-complexity
  public mapAction(action: IOrderAction<ShipmentActionEnum>, entity?: IShipment) {
    switch (action.code) {
      case ShipmentActionEnum.REJECT_BY_CUSTOMER: {
        this.shipmentActionsHandlerService.openCustomerRejectDialog(action, entity);
        break;
      }

      case ShipmentActionEnum.REJECT_BY_SUPPLIER: {
        this.shipmentActionsHandlerService.openSupplierRejectDialog(action, entity);
        break;
      }

      case ShipmentActionEnum.ACCEPT: {
        this.shipmentActionsHandlerService.shipmentActionsService.accept(entity).subscribe(() => this.onActionDone.emit(action));
        break;
      }

      case ShipmentActionEnum.ACCEPT_CLAIM: {
        this.shipmentActionsHandlerService.onClaimResolveActionClicked(action as IOrderAction<ShipmentActionEnum.ACCEPT_CLAIM>, entity);
        break;
      }

      case ShipmentActionEnum.DECLINE_CLAIM: {
        this.shipmentActionsHandlerService.onClaimResolveActionClicked(action as IOrderAction<ShipmentActionEnum.DECLINE_CLAIM>, entity);
        break;
      }

      case ShipmentActionEnum.CSV: {
        this.shipmentActionsHandlerService.shipmentActionsService.downloadAsCSV(entity).subscribe();
        break;
      }

      case ShipmentActionEnum.EDIT: {
        this.shipmentActionsHandlerService.shipmentEditRequest(action, entity);
        break;
      }

      case ShipmentActionEnum.PAYMENT: {
        this.shipmentActionsHandlerService.openPaymentDialog(action, entity);
        break;
      }

      case ShipmentActionEnum.PAYMENT_RESERVE: {
        this.shipmentActionsService.paymentReserve(entity).subscribe(() => this.onActionDone.emit(action));
        break;
      }

      case ShipmentActionEnum.PDF: {
        this.shipmentActionsService.downloadAsPDF(entity).subscribe();
        break;
      }

      case ShipmentActionEnum.XLSX: {
        this.shipmentActionsService.downloadAsXLSX(entity).subscribe();
        break;
      }

      case ShipmentActionEnum.SIMPLE_SHIPMENT_PDF: {
        this.shipmentActionsService.downloadAsSimplePDF(entity).subscribe();
        break;
      }

      case ShipmentActionEnum.READY_TO_SHIP: {
        this.shipmentActionsService.readyToShip(entity).subscribe(() => this.onActionDone.emit(action));
        break;
      }

      case ShipmentActionEnum.SHIP: {
        this.shipmentActionsService.ship(entity).subscribe(() => this.onActionDone.emit(action));
        break;
      }

      case ShipmentActionEnum.GET_ON_BASE: {
        this.onActionDone.emit(action);
        this.shipmentActionsService.navigateToParentOrder(entity.parentId);
        break;
      }

      case ShipmentActionEnum.SET_ATTACHMENT: {
        this.shipmentActionsService.openAttachmentDialog(entity.id).subscribe((result: IFileUploadDialogResult) => {
          if (!result.succeed) {
            return;
          }

          this.popupService.success('Вложение добавлено к отгрузке');
          this.onActionDone.emit(action);
        });
        break;
      }

      case ShipmentActionEnum.DELETE_ATTACHMENT: {
        this.shipmentActionsService.deleteAttachment(action).subscribe();
        break;
      }

      case ShipmentActionEnum.DELIVER: {
        this.shipmentActionsService.deliver(entity).subscribe(() => this.onActionDone.emit(action));
        break;
      }

      case ShipmentActionEnum.ZIP: {
        this.shipmentActionsService.downloadDocumentsAsZIP(entity).subscribe();
        break;
      }

      case ShipmentActionEnum.PRINT_TORG: {
        this.shipmentActionsService.downloadShipmentTorg12(entity).subscribe();
        break;
      }

      case ShipmentActionEnum.PRINT_SF: {
        this.shipmentActionsService.downloadShipmentSf(entity).subscribe();
        break;
      }

      case ShipmentActionEnum.PRINT_TN: {
        this.shipmentActionsService.downloadShipmentTn(entity).subscribe();
        break;
      }

      case ShipmentActionEnum.NAVIGATE_TO_ATTACHMENTS_PAGE: {
        this.router.navigate(['/', 'shipments', entity.id, 'attachments']);
        break;
      }

      case ShipmentActionEnum.REVERT_READY_TO_SHIP: {
        this.shipmentActionsHandlerService.onRevertActionClicked(action, entity);
        break;
      }

      case ShipmentActionEnum.REVERT_SHIP: {
        this.shipmentActionsHandlerService.onRevertActionClicked(action, entity);
        break;
      }

      case ShipmentActionEnum.REVERT_DELIVERY: {
        this.shipmentActionsHandlerService.onRevertActionClicked(action, entity);
        break;
      }

      case ShipmentActionEnum.REVERT_PAYMENT: {
        this.shipmentActionsHandlerService.onRevertActionClicked(action, entity);
        break;
      }

      case ShipmentActionEnum.REVERT_CLAIM: {
        this.shipmentActionsHandlerService.onRevertActionClicked(action, entity);
        break;
      }

      case ShipmentActionEnum.REVERT_SHIPMENT: {
        this.shipmentActionsHandlerService.onRevertActionClicked(action, entity);
        break;
      }

      case ShipmentActionEnum.REJECT_PAYMENT: {
        this.shipmentActionsHandlerService.onRejectPaymentActionClicked(action as IOrderAction<ShipmentActionEnum.REJECT_PAYMENT>, entity);
        break;
      }
    }
  }
}
