export enum NotificationsTypeEnum {
  SYSTEM = 1,
  NEWS,
}

// tslint:disable-next-line:no-magic-numbers
export enum NotificationStatusEnum {
  UNKNOWN = 1,
  CREATED = 2,
  SENT = 3,
  READ = 4,
  DEACTIVATED = 5,
}

export interface INotificationsItemStatus {
  id?: number;
  name?: string;
}

export interface INotificationsListItem {
  notificationId: number;
  senderId: number;
  userId: number;
  clusterId: number;
  subject: string;
  body: string;
  notificationsType: NotificationsTypeEnum;
  notificationsStatus: NotificationStatusEnum;
  creationDateTime?: string;
}
