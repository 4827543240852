import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select',
  template: ` <div
    class="select-widget"
    [class.joyride-placeholder-wrap]="
      field?.templateOptions?.attributes && field?.templateOptions?.attributes?.joyrideStep
    "
  >
    <div
      *ngIf="field?.templateOptions?.attributes && field?.templateOptions?.attributes?.joyrideStep"
      class="joyride-placeholder __p10"
      [joyrideStep]="field?.templateOptions?.attributes && field?.templateOptions?.attributes?.joyrideStep"
      [title]="field?.templateOptions?.attributes && field?.templateOptions?.attributes?.joyrideTitle"
    ></div>
    <label class="select-widget__label">
      {{ field.templateOptions?.label }}
      <span class="required-postfix" *ngIf="field.templateOptions?.required">*</span>
    </label>
    <div class="select-wrap select-widget__control">
      <mat-form-field class="mat-select-custom">
        <mat-select
          [formControl]="formControl"
          [formlyAttributes]="field"
          [multiple]="field?.templateOptions?.multiple"
        >
          <ng-container *ngIf="field?.templateOptions?.keyTitle; else defaultTemplate">
            <mat-option *ngFor="let item of to.options" [value]="item.id">
              {{ item[field?.templateOptions?.keyTitle] }}
            </mat-option>
          </ng-container>
          <ng-template #defaultTemplate>
            <mat-option *ngFor="let item of to.options" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </ng-template>
        </mat-select>
      </mat-form-field>
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends FieldType implements AfterViewInit {

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    this.field.hooks = {
      doCheck: _ => {
        this.cdRef.markForCheck();
      }
    }
  }
}
