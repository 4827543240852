import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { PopupListComponent } from '@core/modules/popups/popup-list/popup-list.component';
import { PopupItemBodyComponent } from '@core/modules/popups/popup-item-body/popup-item-body.component';
import { PopupItemComponent } from '@core/modules/popups/popup-item/popup-item.component';
import { CommonModule } from '@angular/common';
import { PopupModuleConfiguration } from '@core/modules/popups/popup.interface';
import { PopupNotificationsService } from '@core/modules/popups/popup-notifications.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [PopupListComponent, PopupItemComponent, PopupItemBodyComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatIconModule,
  ],
  exports: [PopupListComponent],
  providers: [PopupNotificationsService]
})
export class PopupsModule {
  public static forRoot(options: PopupModuleConfiguration): ModuleWithProviders<PopupsModule> {
    return {
      ngModule: PopupsModule,
      providers: [PopupNotificationsService, {
        provide: PopupModuleConfiguration,
        useValue: options,
        deps: [FOR_ROOT_OPTIONS_TOKEN]
      }]
    } as ModuleWithProviders<any>;
  }
}

export const FOR_ROOT_OPTIONS_TOKEN = new InjectionToken<PopupModuleConfiguration>( 'forRoot() MyService configuration.' );
