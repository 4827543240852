import { IFormlyField } from '@ui/widgets/settings-widgets/add-good/shared/form-fields.interface';
import { MAX_EXPIRATION_DAYS } from '@ui/widgets/settings-widgets/add-good/shared/max-expiration-days';

export const ADD_GOOD_FORM_DATA: IFormlyField[] = [
  {
    type: 'input',
    key: 'name',
    templateOptions: {
      label: 'Наименование',
      required: true,
    },
  },
  {
    type: 'select',
    key: 'brandId',
    action: 'Brands?limit=999&parentId=0',
    className: 'fb-63',
    templateOptions: {
      label: 'Бренды',
      required: true,
      options: [],
    },
  },
  {
    type: 'select',
    key: 'subBrandId',
    className: 'fb-63',
    templateOptions: {
      label: 'Суббренд',
      required: false,
      options: [],
    },
  },
  {
    type: 'radio',
    key: 'isActive',
    className: 'good_activity',
    defaultValue: false,
    templateOptions: {
      label: 'Активность товара',
      required: true,
      options: [
        { value: true, key: 'Активен' },
        { value: false, key: 'Неактивен' },
      ],
    },
  },
  {
    fieldGroupClassName: 'horizontal-group',
    fieldGroup: [
      {
        className: 'flex-1 mr-10',
        type: 'select',
        key: 'categoryId',
        action: 'Categories?limit=999',
        templateOptions: {
          label: 'Родительская категория',
          required: true,
          options: [],
        },
      },
      {
        className: 'flex-bas-33 mr-0',
        type: 'input',
        key: 'categoryName',
        templateOptions: {
          label: 'Код род. категории',
          required: true,
        },
      },
    ],
  },
  {
    fieldGroupClassName: 'horizontal-group',
    fieldGroup: [
      {
        className: 'flex-1 mr-10',
        type: 'select',
        action: 'Countries',
        key: 'countryId',
        templateOptions: {
          label: 'Страна производства',
          required: true,
          options: [],
        },
      },
      {
        className: 'flex-1',
        type: 'input',
        key: 'conformityCertNumber',
        templateOptions: {
          required: false,
          label: '№ сертиф. соотв.',
        },
      },
    ],
  },
  {
    fieldGroupClassName: 'horizontal-group',
    fieldGroup: [
      {
        className: 'flex-1 mr-10',
        type: 'input',
        key: 'barcode',
        templateOptions: {
          required: true,
          label: 'ШК товара',
        },
      },
      {
        className: 'flex-1 mr-0',
        type: 'input',
        key: 'additionalBarcode',
        templateOptions: {
          required: false,
          label: 'Дополнительные ШК',
        },
      },
    ],
  },
  {
    fieldGroupClassName: 'horizontal-group',
    fieldGroup: [
      {
        className: 'flex-1 mr-10',
        type: 'input',
        key: 'uniqueCode',
        templateOptions: {
          label: 'Уникальный код товара',
          readonly: true,
          placeholder: 'Генерируется автоматически'
        },
      },
      {
        className: 'flex-1 mr-0',
        type: 'select',
        key: 'unitKindId',
        action: 'Directories/UnitKinds',
        templateOptions: {
          required: true,
          label: 'Единица измерения (ОКЕИ)',
        },
      },
    ],
  },
  {
    fieldGroupClassName: 'horizontal-group',
    fieldGroup: [
      {
        className: 'flex-1 mr-10',
        type: 'select',
        key: 'vat',
        action: 'Directories/VatKinds',
        templateOptions: {
          required: true,
          label: 'Ставка НДС',
        },
      },
      {
        className: 'flex-1 mr-0',
        type: 'input',
        key: 'expirationDays',
        templateOptions: {
          label: 'Срок годности',
          type: 'number',
          min: 1,
          max: MAX_EXPIRATION_DAYS,
        },
      }
    ],
  },
  {
    key: '',
    wrappers: ['form-group-with-title'],
    templateOptions: { label: 'Индивидуальная упаковка' },
    fieldGroupClassName: 'horizontal-group',
    fieldGroup: [
      {
        className: 'flex-bas-25 mr-10',
        type: 'input',
        key: 'weight',
        templateOptions: {
          required: true,
          label: 'Вес, кг',
          type: 'number',
          min: 0,
        },
      },
      {
        className: 'flex-bas-25 mr-10',
        type: 'input',
        key: 'width',
        templateOptions: {
          required: true,
          label: 'Ширина, см',
          type: 'number',
          min: 1,
        },
      },
      {
        className: 'flex-bas-25 mr-10',
        type: 'input',
        key: 'height',
        templateOptions: {
          required: true,
          label: 'Высота, см',
          type: 'number',
          min: 1,
        },
      },
      {
        className: 'flex-bas-25 mr-10',
        type: 'input',
        key: 'thickness',
        templateOptions: {
          required: true,
          label: 'Толщина, см',
          type: 'number',
          min: 1,
        },
      },
    ],
  },
  {
    key: '',
    wrappers: ['form-group-with-title'],
    templateOptions: { label: 'Групповая упаковка' },
    fieldGroupClassName: 'horizontal-group',
    fieldGroup: [
      {
        className: 'flex-bas-25 mr-10',
        type: 'input',
        key: 'groupPackNesting',
        templateOptions: {
          required: true,
          label: 'Вложенность',
          type: 'number',
          min: 1,
        },
      },
      {
        className: 'flex-bas-25 mr-10',
        type: 'input',
        key: 'groupPackWidth',
        templateOptions: {
          required: true,
          label: 'Ширина, см',
          type: 'number',
          min: 1,
        },
      },
      {
        className: 'flex-bas-25 mr-10',
        type: 'input',
        key: 'groupPackHeight',
        templateOptions: {
          required: true,
          label: 'Высота, см',
          type: 'number',
          min: 1,
        },
      },
      {
        className: 'flex-bas-25 mr-10',
        type: 'input',
        key: 'groupPackThickness',
        templateOptions: {
          required: true,
          label: 'Толщина, см',
          type: 'number',
          min: 1,
        },
      },
    ],
  },
];
