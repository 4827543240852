import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-demo-menu',
  templateUrl: './demo-menu.component.html',
  styleUrls: ['./demo-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
