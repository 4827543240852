import { Injectable } from '@angular/core';
import { WebApiService } from '@core/web-api/shared/services/web-api.service';
import { ICollectionWithPagination } from '@core/shared/interfaces/collection-with-pagination.interface';
import { ISelectOption } from '@ui/components/mat-form-components/shared/select-option.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router, UrlSerializer } from '@angular/router';
import { IFieldsKinds } from '@app/pages/settings/nomenclature/shared/kinds.interface';
import { IContragent } from '@app/pages/system-management/contracts/shared/contract.interface';
import { map } from 'rxjs/operators';
import { ICount } from '@ui/shared/interfaces/count.interface';
import { IPartnersDepartmentsRequest } from '@core/shared/interfaces/partners-departments.interface';
import { IDepartmentKind } from '@core/shared/interfaces/department-kind.interface';
import {
  IDepartment, IDepartmentPost,
  IDepartmentPostalAddress,
  IDepartmentRequest, IDepartmentShort,
  IPartnerDepartment, IStatus,
} from '@app/pages/settings/departments/containers/department-table/shared/interface/department.interface';
import { DepartmentsQueryDto } from '@core/shared/dto/departments-query.dto';
import { OrderFilterDto } from '@core/shared/dto/order-filter.dto';
import { DefaultTableQueryDto } from '@core/shared/dto/default-table-query.dto';
import { IDepartmentAllowedUser } from '@ui/widgets/system-management-widgets/department-card/shared/department-user.model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  pageChangedSubject: Subject<number> = new Subject<number>();
  pageChangedObs$: Observable<number> = this.pageChangedSubject.asObservable();

  contragentChangedSubject: Subject<number | undefined> = new Subject<number | undefined>(); // TODO: Исправить
  contragentChangedObs$: Observable<number | undefined> = this.contragentChangedSubject.asObservable(); // TODO: Исправить
  departmentsTableSortSubj = new BehaviorSubject('kindName');

  constructor(
    private api: WebApiService,
    private serializer: UrlSerializer,
    private router: Router,
  ) {}

  getById(departmentId: number): Observable<IDepartment> {
    return this.api.get(`Departments/${departmentId}`);
  }

  getDepartments(params: Partial<IDepartmentRequest> = {}): Observable<ICollectionWithPagination<IDepartment>> {
    return this.api
      .get<ICollectionWithPagination<IDepartment>>('Departments', params);
  }

  getPartnersDepartments(params: Partial<IPartnersDepartmentsRequest> = {}): Observable<IPartnerDepartment[]> {
    return this.api.get<IPartnerDepartment[]>('Departments/PartnersDepartments', params);
  }

  deleteDepartment(departmentId: number): Observable<null> {
    return this.api.delete(`Departments/${departmentId}`);
  }

  getDepartmentsCount(params: { filter?: string; contragentId?: number | undefined }): Observable<number> {
    // TODO: Исправить
    const tree = this.router.createUrlTree(['Departments/Count'], { queryParams: params });
    const paramsString = this.serializer.serialize(tree).substr(1);
    return this.api.get<ICount>(paramsString).pipe(map(({ count }) => count));
  }

  getFormFieldsData(action: string): Observable<IFieldsKinds[]> {
    return this.api
      .get<IFieldsKinds[]>(action)
      .pipe(map((items: IFieldsKinds[] | any) => (items.data ? items.data : items)));
  }

  postDepartments(department: IDepartment): Observable<IDepartment> {
    return this.api.post<IDepartment, IDepartment>(`Departments`, department);
  }

  putDepartments(department: IDepartment): Observable<IDepartmentPost> {
    return this.api.put<IDepartment, IDepartmentPost>(`Departments/${department.id}`, department);
  }

  getContragents(dto?: DepartmentsQueryDto): Observable<ICollectionWithPagination<IContragent>> {
    return this.api.get('Contragents', dto);
  }

  bindUsers(departmentId: number, users: { usersId: number[] }): Observable<any> {
    return this.api.put(`Departments/${departmentId}/Bind`, users);
  }

  unbindUsers(departmentId: number, users: { usersId: number[] }): Observable<any> {
    return this.api.put(`Departments/${departmentId}/Unbind`, users);
  }

  getAddress(addressId: number): Observable<IDepartmentPostalAddress> {
    return this.api.get<IDepartmentPostalAddress>(`Departments/Address?id=${addressId}`).pipe(
      map((response: IDepartmentPostalAddress) => {
        const { id, fullAddress, ...address } = response;
        return address;
      }),
    );
  }

  getCurrentUserShops(): Observable<IDepartment[]> {
    return this.api.get<IDepartment[]>('Departments/Shop');
  }

  getCurrentUserDepartments(): Observable<IDepartment[]> {
    return this.api.get<IDepartment[]>('Departments/List');
  }

  getCurrentUserWarehouse(): Observable<IDepartment[]> {
    return this.api.get<IDepartment[]>('Departments/Warehouse');
  }

  getOptionFromDepartment(department: IDepartment): ISelectOption {
    return {
      value: department.id,
      title: department.name,
    };
  }

  getStatuses(): Observable<IStatus[]> {
    return this.api.get<IStatus[]>('Departments/Status');
  }

  public getAvailableWarehouse(): Observable<IDepartment[]> {
    return this.api.get<IDepartment[]>('Departments/AvailableWarehouse');
  }

  public getDepartmentKinds(contragentId?: number): Observable<IDepartmentKind[]> {
    return this.api.get('Departments/Kind', {
      contragentId
    });
  }

  public listByMovementWarehouse(dto?: OrderFilterDto): Observable<ICollectionWithPagination<IDepartmentShort>> {
    return this.api.get(`Departments/ListByMovementWarehouse`, dto);
  }

  public listByMovementShop(dto?: OrderFilterDto): Observable<ICollectionWithPagination<IDepartmentShort>> {
    return this.api.get(`Departments/ListByMovementShop`, dto);
  }

  public mapDepartmentsToSelectOptions(departments: IDepartment[] | any): ISelectOption<IDepartment>[] {
    const options: ISelectOption<IDepartment>[] = departments.map((department: any) => ({
      title: department.name,
      value: department.id,
    }));

    options.unshift({
      value: null,
      title: 'Все'
    });

    return options;
  }

  public getAllowedUsers(contragentId: number, dto?: DefaultTableQueryDto): Observable<ICollectionWithPagination<IDepartmentAllowedUser>> {
    return this.api.get(`Departments/AllowedUsers`, {
      contragentId,
      ...dto
    });
  }
}
