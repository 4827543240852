import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-mat-input',
  templateUrl: './mat-input.component.html',
  styleUrls: ['./mat-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MatInputComponent implements OnChanges {
  @Input() inputType: 'text' | 'password' | 'search' | 'number' = 'text';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }

    this._disabled = isDisabled;
  }
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() value = '';
  @Input() hint = '';
  @Input() autocomplete = '';

  private _disabled = false;

  ngOnChanges(): void {
    if (this._disabled) {
      this.control.disable();
    }
  }
}
