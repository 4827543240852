import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-progress-fader',
  templateUrl: './progress-fader.component.html',
  styleUrls: ['./progress-fader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressFaderComponent implements OnInit {
  @Input() label = 'Подождите...';
  @Input() progress: number | null = null;

  constructor() {}

  ngOnInit() {}
}
