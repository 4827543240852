import {ChangeDetectionStrategy, Component, OnInit, Optional} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IPopup, PopupModuleConfiguration} from '../popup.interface';
import {animate, keyframes, query, stagger, style, transition, trigger} from '@angular/animations';
import {PopupNotificationsService} from '../popup-notifications.service';

@Component({
  selector: 'app-popups',
  templateUrl: './popup-list.component.html',
  styleUrls: ['./popup-list.component.scss'],
  animations: [
    trigger('notificationListAnimation', [
      transition('* => *', [
        query(':enter', style({opacity: 0}), {optional: true}),

        query(':enter', stagger('500ms', [
          animate('500ms cubic-bezier(0, 0, .2, 1)', keyframes([
            style({height: 0, opacity: 0, transform: 'translateX({{fromX}})', offset: 0}),
            style({height: '*', opacity: 0, transform: 'translateX({{fromX}})', offset: 0.75}),
            style({height: '*', opacity: 1, transform: 'translateX({{toX}})', offset: 1.0})
          ]))
        ]), {optional: true}),

        query(':leave', stagger('500ms', [
          animate('500ms cubic-bezier(0, 0, .2, 1)', keyframes([
            style({height: '*', opacity: 1, transform: 'translateX({{toX}})', offset: 0}),
            style({height: '*', opacity: 0, transform: 'translateX({{fromX}})', offset: 0.9}),
            style({height: 0, opacity: 0, transform: 'translateX({{fromX}})', offset: 1.0})
          ]))
        ]), {optional: true})
      ], {params: {fromX: '50%', toX: '0'}})
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupListComponent {

  notifications$: BehaviorSubject<IPopup[]>;

  constructor(private readonly notificationService: PopupNotificationsService,
              @Optional() public options: PopupModuleConfiguration) {
    this.notifications$ = this.notificationService.notifications;
  }

  trackById = (index: number, item: IPopup) => {
    return item.id;
  }

  onNotificationDestroy(notification: IPopup) {
    this.notificationService.delete(notification);
  }
}
