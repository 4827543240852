import { IHeader } from '@ui/components/header/shared/interfaces/header.interface';

export const HEADER_ITEMS_CATALOG: IHeader[] = [
  //   {
  //     label: 'Промо',
  //     path: '/showcase/promo',
  //     isDisabled: true
  //   }
  // TODO:  скрыть блоки, временно, до реализации на бэке_SVT-207
];
