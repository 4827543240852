import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '@core/modules/auth/auth.service';
import {filter, map, mapTo, take, tap} from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class WhoAmiLoadedGuard implements CanLoad {
  private isAuthenticated!: boolean;

  constructor(private oauthService: OAuthService, private authService: AuthService) {
    this.authService.isAuthenticated$.subscribe((i) => (this.isAuthenticated = i));
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.authService.whoamiLoaded
      .pipe(filter(Boolean))
      .pipe(take(1))
      .pipe(mapTo(true));
  }
}
