import { Component, OnInit, ChangeDetectionStrategy, Self } from '@angular/core';
import { INotificationsListItem } from '@ui/modules/notifications-list-popup/notifications-list-content/notifications-list-item/shared/notifications-list-list-item.interface';
import { Observable } from 'rxjs';
import { NotificationsListFacade } from '@ui/modules/notifications-list-popup/notifications-list.facade';
import { AuthService } from '@app/@core/modules/auth/auth.service';
import { NgOnDestroy } from '@app/@core/shared/services/destroy.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notifications-list-content',
  templateUrl: './notifications-list-content.component.html',
  styleUrls: ['./notifications-list-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroy],
})
export class NotificationsListContentComponent implements OnInit {
  public notifications$: Observable<INotificationsListItem[]>;

  constructor(
    private notificationsListFacade: NotificationsListFacade,
    private authService: AuthService,
    @Self() private readonly componentDestroyed$: NgOnDestroy,
  ) {}

  public onNotificationRead(notification: INotificationsListItem) {
    const notificationReadStatus = 4;
    notification.notificationsStatus = notificationReadStatus;
    this.notificationsListFacade.updateUserNotificationStatus(
      this.authService.currentUser.id,
      notification.notificationId,
      notificationReadStatus,
    );
  }

  private initSubscriptions(): void {
    this.notifications$ = this.notificationsListFacade.getLastNotifications().pipe(takeUntil(this.componentDestroyed$));
  }

  ngOnInit() {
    this.initSubscriptions();
  }
}
