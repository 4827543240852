import { IHeader } from '@ui/components/header/shared/interfaces/header.interface';

export const HEADER_ITEMS_SHOP: IHeader[] = [
  {
    label: 'Оперативные заявки',
    path: '/shop/operational-applications',
  },
  //   {
  //     label: 'Промо заявки',
  //     path: '/shop/promo-applications',
  //     isDisabled: true
  //   },
  // TODO:  скрыть блоки, временно, до реализации на бэке_SVT-207
  {
    label: 'Поставки',
    path: '/shop/delivery',
  },
  //   {
  //     label: 'Приходы',
  //     path: '/shop/arrivals'
  //   }
  // TODO:  скрыть блоки, временно, до реализации на бэке_SVT-207
];
