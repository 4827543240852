import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import * as ws from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { OAuthService, OAuthEvent } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { INotificationsListItem } from '@app/ui/modules/notifications-list-popup/notifications-list-content/notifications-list-item/shared/notifications-list-list-item.interface';
import { environment } from '@environments/environment';

@Injectable()
export class WebsocketService {
  public notificationReceived$: BehaviorSubject<INotificationsListItem> = new BehaviorSubject<any>(null);

  private connection: HubConnection;
  private timer: any;

  constructor(private readonly authService: OAuthService) {}

  public initConnection(): void {
    const token = this.authService.getAccessToken();
    if (!token) {
      return;
    }

    if (this.connection) {
      this.connection.stop();
      this.connection = null;
    }

    this.connection = new ws.HubConnectionBuilder()
      .withUrl(this.getApiHost(), {
        accessTokenFactory: () => this.authService.getAccessToken(),
        skipNegotiation: true,
        transport: ws.HttpTransportType.WebSockets,
      })
      .configureLogging(ws.LogLevel.Debug)
      .build();

    this.initConnectionEvents();

    this.connect();
  }

  private connect(): void {
    if (this.connection && this.connection.state === ws.HubConnectionState.Disconnected) {
      this.startConnection();
    } else {
      this.connection.stop().then(() => this.startConnection());
    }
  }

  private startConnection(): void {
    if (this.authService.getAccessToken() && this.connection !== null) {
      this.connection
        .start()
        .then(() => (this.timer = null))
        .catch((err) => {
          if (err.message !== 'There was an error with the transport.') {
            this.restartConnection();
          }
        });
    }
  }

  private initConnectionEvents(): void {
    this.connection.on('notificationReceived', (notification: string) => this.getNotification(notification));
    this.connection.onclose(() => this.restartConnection());
  }

  private getNotification(notification: string): void {
    const notify = JSON.parse(notification) as INotificationsListItem;
    this.notificationReceived$.next(notify);
  }

  private restartConnection(): void {
    this.timer = setTimeout(() => this.startConnection(), 1000);
  }

  private getApiHost(): string {
    return `${environment.WEB_API_URL.substr(0, environment.WEB_API_URL.length - 3)}hubs/notification`;
  }

  public initWebsocket(): Promise<any> {
    return new Promise<any>((resolve: any) => {
      this.authService.events
        .pipe(filter((event: OAuthEvent) => event.type === 'token_received'))
        .subscribe(() => this.initConnection());

      this.authService.events
        .pipe(filter((event: OAuthEvent) => event.type === 'token_error'))
        .subscribe(() => {
          if (this.connection) {
            this.connection.stop();
          }
        });

      this.initConnection();
      resolve();
    });
  }
}
