import { IHeader } from '@ui/components/header/shared/interfaces/header.interface';

export const HEADER_ITEMS_SYSTEM_MANAGEMENT: IHeader[] = [
  {
    label: 'Журнал событий',
    path: '/system-management/events',
  },
  {
    label: 'Контракты',
    path: '/system-management/contracts',
  },
  {
    label: 'Справочник товаров',
    path: '/system-management/directory-of-goods',
  },
  {
    label: 'Кластеры',
    path: '/system-management/clusters',
  },
  {
    label: 'Коэффициенты',
    path: '/system-management/odds',
  },
  {
    label: 'Администрирование',
    path: '/system-management/administration',
  },
  //   {
  //     label: 'Управление пользователями',
  //     path: '/system-management/accounts'
  //   }
  // TODO:  скрыть блоки, временно, до реализации на бэке_SVT-207
];
