export const parseEnvironmentFromUrl = () => {
  const url = window.location.href;
  const match = url.match(/\/\/(.+)\.shop/);

  if (match === null) {
    return 'prod';
  } else {
    return match[1];
  }
}
