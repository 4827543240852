import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsListOverlayService } from '@ui/modules/notifications-list-popup/notifications-list-overlay/notifications-list-overlay.service';
import { NotificationsListContentComponent } from '@ui/modules/notifications-list-popup/notifications-list-content/notifications-list-content.component';
import { NotificationsListItemComponent } from './notifications-list-content/notifications-list-item/notifications-list-item.component';
import { MatIconModule } from '@angular/material/icon';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { SafeHtmlModule } from '@app/ui/pipes/safe-html/safe-html.module';

@NgModule({
  declarations: [NotificationsListContentComponent, NotificationsListItemComponent],
  imports: [CommonModule, MatIconModule, PerfectScrollbarModule, RouterModule, SafeHtmlModule],
  providers: [NotificationsListOverlayService],
})
export class NotificationsListPopupModule {}
