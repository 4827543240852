import { Injectable } from '@angular/core';
import { StorageService } from '@core/shared/services/storage.service';
import { FiltersStorageEnum } from '@core/shared/enums/filters-storage.enum';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FiltersStorageService {
  constructor(private readonly storageService: StorageService) {
  }

  public saveFilter(filter: FiltersStorageEnum, data: UntypedFormGroup) {
    this.storageService.setItem(filter, JSON.parse(JSON.stringify(data.getRawValue())));
  }

  public getFilter(filter: FiltersStorageEnum) {
    return this.storageService.getItem(filter);
  }

  public applyFilterToForm(filter: FiltersStorageEnum, form: UntypedFormGroup) {
    const lastSavedFilter = this.getFilter(filter);

    if (lastSavedFilter) {
      form.patchValue(lastSavedFilter);
    }
  }

  public clearFilters(key: string) {
    this.storageService.removeItem(key);
  }
}
