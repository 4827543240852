import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { INotificationsListItem } from '@ui/modules/notifications-list-popup/notifications-list-content/notifications-list-item/shared/notifications-list-list-item.interface';

@Component({
  selector: 'app-notifications-list-item',
  templateUrl: './notifications-list-item.component.html',
  styleUrls: ['./notifications-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsListItemComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('body') body!: ElementRef;
  @ViewChild('bodyContent') bodyContent!: ElementRef;

  @Input() notification!: INotificationsListItem;

  @Output() read = new EventEmitter<void>();

  public notificationStatus: 'read' | 'not_read' = 'read';
  public isNotificationHasOverflow = false;
  public isExpanded = false;
  public contentBodyMaxHeightToNotOverflow = 70;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('notification')) {
      const notificationNotReadStatus = 3;
      this.notificationStatus =
        this.notification.notificationsStatus === notificationNotReadStatus ? 'not_read' : 'read';
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setIsNotificationHasOverflow();
    });
  }

  @HostListener('click')
  expand(): void {
    if (this.isNotificationHasOverflow) {
      this.isExpanded = !this.isExpanded;
    }

    if (this.notificationStatus === 'not_read') {
      this.notificationStatus = 'read';
      this.read.emit();
    }
  }

  private setIsNotificationHasOverflow() {
    if (this.bodyContent.nativeElement.clientHeight > this.contentBodyMaxHeightToNotOverflow) {
      this.isNotificationHasOverflow = true;
      this.cdRef.detectChanges();
    }
  }
}
