<mat-form-field class="mat-input-custom">
  <input
    [formControl]="control"
    [placeholder]="placeholder"
    [type]="inputType"
    [value]="value"
    [autocomplete]="autocomplete"
    matInput
  />
  <mat-hint *ngIf="hint" class="mat-input-custom__hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="control.hasError('required')">обязательное поле для заполнения</mat-error>
  <ng-content></ng-content>
</mat-form-field>
