<div class="order-summary">
  <div class="column">
    <ul class="list">
      <li>
        <div class="block-header">Поставщик</div>
        <div>{{ order?.supplier?.shortName }}</div>
      </li>
      <li >
        <div>ИНН</div>
        <div>{{ order?.supplier?.inn }}</div>
      </li>
      <li >
        <div>Сокр. наименование</div>
        <div>{{ order?.supplier?.shortName }}</div>
      </li>
      <li >
        <div>Подразделение</div>
        <div>{{ order?.sender?.name }}</div>
      </li>
      <li >
        <div>Фактический адрес</div>
        <div class="__w150">{{ order?.sender?.address }}</div>
      </li>
    </ul>
  </div>
  <div class="column">
    <ul class="list">
      <li>
        <div class="block-header">Заказчик</div>
        <div >{{ order?.customer?.shortName }}</div>
      </li>
      <li >
        <div>ИНН</div>
        <div>{{ order?.customer?.inn }}</div>
      </li>
      <li >
        <div>Сокр. наименование</div>
        <div>{{ order?.customer?.shortName }}</div>
      </li>
      <li >
        <div>Подразделение</div>
        <div>
          {{ order?.receiver.name }}
        </div>
      </li>
      <li >
        <div>Фактический адрес</div>
        <div class="__w150">{{ order?.receiver?.address }}</div>
      </li>
    </ul>
  </div>
</div>
<div class="order-summary">
  <div class="column">
    <ul class="list">
      <li>
        <div>Дата создания</div>
        <div>{{ order?.creationDateTime | date: 'dd.MM.yyyy' }}</div>
      </li>
      <li >
        <div>Дата подтверждения</div>
        <div>{{ order?.supplierTransferDate | date: 'dd.MM.yyyy' }}</div>
      </li>
      <li >
        <div>Количество товаров</div>
        <div>{{ order?.itemsCount }} шт</div>
      </li>
      <li>
        <div>Сумма предоплаты</div>
        <div>{{ order?.prepaimentSum | number: '1.0-2' }}  ₽</div>
      </li>
      <li >
        <div>Размер предоплаты</div>
        <div>{{ order?.prepaimentPercent | number: '1.0-2' }} %</div>
      </li>
      <li >
        <div>Сумма без НДС</div>
        <div>{{ order?.sumWithoutVat | number: '1.0-2' }} ₽</div>
      </li>
      <li >
        <div>Сумма с НДС</div>
        <div>{{ order?.sum | number: '1.0-2' }} ₽</div>
      </li>
    </ul>
  </div>
  <div class="column">
    <ul class="list delivery">
      <li>
        <div class="delivery-wrapper">
          <div class="block-header custom-width">Тип получения</div>
          <br/>
          <div
            *ngIf="order.deliveryType; else noDelivery"
          >
            {{ this.order.deliveryType.name }}
          </div>
          <ng-template #noDelivery>
            <div>Не указано</div>
          </ng-template>
        </div>
      </li>
      <!-- <li>
        <span class="delivery-info">Доставка</span>
      </li>
      <li>
        <div class="block-header">Адрес доставки</div>
      </li>
      <li>
        <span class="delivery-info">350032, Краснодарский край, г Краснодар, Индустриальный,
          ул Восточная, 1, кор Б</span>
      </li>
      <li>
        <span class="price-info">
          <span>
          Стоимость — 2000 ₽
          </span>
          <mat-icon svgIcon="info" class="info-icon"></mat-icon>
        </span>
      </li> -->
    </ul>
  </div>
</div>
