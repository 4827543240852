import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IMatIcon } from '@app/ui/shared/interfaces/mat-icon.interface';
import { MATERIAL_ICONS_LIST } from '@app/ui/shared/material-icons-list';
import { MatIconsEnum } from '../../enums';

const extension = '.svg';

@Injectable({
  providedIn: 'root'
})
export class MatIconRegistryService {

  private readonly icons = MatIconsEnum;

  constructor(
    private readonly matIconReg: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
  ) {
  }

  public initCustomMatIcons(): Promise<any> {
    return new Promise((resolve: any) => {
      this.registerIcons();
      resolve();
    });
  }

  private registerIcons(): void {
    this.loadIcons(Object.values(this.icons), '../assets/img/icons/liri');
  }

  private loadIcons(iconNames: string[], iconUrl: string): void {
    iconNames.forEach((name: string) => {
      this.matIconReg.addSvgIcon(
        name,
        this.sanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${name}.svg`),
      );
    });

    MATERIAL_ICONS_LIST.forEach((icon: IMatIcon) => {
      const url = this.sanitizer.bypassSecurityTrustResourceUrl(icon.src + icon.name + extension);
      this.matIconReg.addSvgIcon(icon.name, url);
    });
  }

}
