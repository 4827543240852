import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@core/shared.module';
import { FormlyModule } from '@ngx-formly/core';
import { BlockWrapComponent } from '@ui/components/block-wrap/block-wrap.component';
import { ButtonComponent } from '@ui/components/button/button.component';
import { ColoredTextInclusionComponent } from '@ui/components/colored-text-inclusion/colored-text-inclusion.component';
import { DateRangeSelectComponent } from '@ui/components/date-range-select/date-range-select.component';
import { EditTableColumnsComponent } from '@ui/components/edit-table-columns/edit-table-columns.component';
import { FormComponentsModule } from '@ui/components/form-components/form-components.module';
import { HeaderComponent } from '@ui/components/header/header.component';
import { NotificationsHeaderButtonComponent } from '@ui/components/header/notifications-header-button/notifications-header-button.component';
import { IconButtonComponent } from '@ui/components/icon-button/icon-button.component';
import { MatFormComponentsModule } from '@ui/components/mat-form-components/mat-form-components.module';
import { NoDataMessageComponent } from '@ui/components/no-data-message/no-data-message.component';
import { PaginationComponent } from '@ui/components/pagination/pagination.component';
import { TabsComponent } from '@ui/components/tabs/tabs.component';
import { UiDirectivesModule } from '@ui/directives/ui-directives.module';
import { MatDaterangePickerModule } from '@ui/modules/mat-daterange-picker/mat-daterange-picker.module';
import { NotificationsListPopupModule } from '@ui/modules/notifications-list-popup/notifications-list-popup.module';
import { AddGoodComponent } from '@ui/widgets/settings-widgets/add-good/add-good.component';
import { AddApplicationComponent } from './add-application/add-application.component';
import { ClickedFieldComponent } from './clicked-field/clicked-field.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { ItemsCountOnPageComponent } from './items-count-on-page/items-count-on-page.component';
import { QuantityComponent } from './quantity/quantity.component';
import { OrderStatusComponent } from './status/order-status.component';
import { TreeComponent } from './tree/tree.component';
import { DropdownButtonComponent } from '@ui/components/dropdown-button/dropdown-button.component';
import { SnackbarComponent } from '@ui/components/snackbar/snackbar.component';
import { SimpleTextareaDialogComponent } from '@ui/components/simple-textarea-dialog/simple-textarea-dialog.component';
import { DropdownFileUploaderComponent } from '@ui/components/dropdown-file-uploader/dropdown-file-uploader.component';
import { PaymentDialogComponent } from '@ui/components/payment-dialog/payment-dialog.component';
import { PaymentStatusComponent } from '@ui/components/payment-status/payment-status.component';
import { GlobalProgressBarComponent } from '@ui/components/global-progress-bar/global-progress-bar.component';
import { ShipmentIncidentStatusComponent } from '@ui/components/shipment-incident-status/shipment-incident-status.component';
import { MatRippleModule } from '@angular/material/core';
import { FaderComponent } from '@ui/components/fader/fader.component';
import { ENVIRONMENT_TOKEN } from '@environments/environment.config';
import { environment } from '@environments/environment';
import { DemoMenuComponent } from './demo-menu/demo-menu.component';
import { FooterComponent } from '@ui/components/footer/footer.component';
import { JoyrideModule } from 'ngx-joyride';
import { GuideTourService } from '../shared/services/guid-tour.service';
import { ProgressFaderComponent } from '@ui/components/progress-fader/progress-fader.component';
import { TableUpdateButtonComponent } from '@ui/components/table-update-button/table-update-button.component';
import { GoBackButtonComponent } from '@ui/components/go-back-button/go-back-button.component';
import { BlockWrapHeaderDirective } from '@ui/components/block-wrap/directives/block-wrap-header.directive';
import { ConfirmDialogComponent } from '@ui/components/confirm-dialog/confirm-dialog.component';
import { OrderDefaultActionComponent } from '@ui/components/order-default-action/order-default-action.component';
import { OrderViewInfoComponent } from '@ui/components/order-view-info/order-view-info.component';
import { OrderViewCommentsComponent } from '@ui/components/order-view-comments/order-view-comments.component';
import { ProductLabelsComponent } from '@ui/components/product-labels/product-labels.component';
import { ProductLabelComponent } from '@ui/components/product-labels/product-label/product-label.component';
import { OrderPositionQuantityComponent } from '@ui/components/order-position-quantity/order-position-quantity.component';
import { AlertMessageComponent } from '@ui/components/alert-message/alert-message.component';
import { ProductPriceComponent } from '@ui/components/product-price/product-price.component';

const UI_COMPONENTS = [
  HeaderComponent,
  ButtonComponent,
  PaginationComponent,
  BlockWrapComponent,
  AddApplicationComponent,
  TreeComponent,
  ItemsCountOnPageComponent,
  EditTableColumnsComponent,
  DeleteDialogComponent,
  IconButtonComponent,
  DateRangeSelectComponent,
  NotificationsHeaderButtonComponent,
  OrderStatusComponent,
  NotificationsHeaderButtonComponent,
  QuantityComponent,
  ClickedFieldComponent,
  QuantityComponent,
  TabsComponent,
  NoDataMessageComponent,
  DropdownButtonComponent,
  SnackbarComponent,
  ColoredTextInclusionComponent,
  DropdownFileUploaderComponent,
  SimpleTextareaDialogComponent,
  PaymentDialogComponent,
  PaymentStatusComponent,
  GlobalProgressBarComponent,
  ShipmentIncidentStatusComponent,
  AddGoodComponent,
  FaderComponent,
  DemoMenuComponent,
  FooterComponent,
  ProgressFaderComponent,
  TableUpdateButtonComponent,
  GoBackButtonComponent,
  BlockWrapHeaderDirective,
  ConfirmDialogComponent,
  OrderDefaultActionComponent,
  OrderViewInfoComponent,
  OrderViewCommentsComponent,
  ProductLabelsComponent,
  ProductLabelComponent,
  OrderPositionQuantityComponent,
  AlertMessageComponent,
  ProductPriceComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FormComponentsModule,
    FormlyModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatFormComponentsModule,
    MatIconModule,
    MatTableModule,
    MatTreeModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDialogModule,
    MatSortModule,
    MatDaterangePickerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTooltipModule,
    NotificationsListPopupModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    UiDirectivesModule,
    MatRippleModule,
    JoyrideModule.forRoot(),
  ],
  providers: [
    GuideTourService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 5000, verticalPosition: 'top', panelClass: 'snackbar' },
    },
    {
      provide: ENVIRONMENT_TOKEN,
      useValue: environment,
    },
  ],
  declarations: UI_COMPONENTS,
  exports: [...UI_COMPONENTS, FormComponentsModule, MatFormComponentsModule, JoyrideModule],
})
export class UiComponentsModule { }
