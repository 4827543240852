import { Injectable } from '@angular/core';
import { ICategory } from '@ui/shared/interfaces/category.interface';
import { CategoryService } from '@ui/shared/services/category.service';
import { ITreeData } from '@ui/components/abstract-tree/shared/tree.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDynamicDatabase } from '@app/pages/system-management/trading-odds/trading-odds-categories/shared/base-dynamic-db';

@Injectable({ providedIn: 'root' })
export class CategoryDynamicDatabase extends BaseDynamicDatabase<ITreeData> {
  constructor(private categoryService: CategoryService) {
    super();
  }

  getChildren(parentId: number): Observable<ITreeData[]> {
    return this.categoryService
      .getCategories({ parentId })
      .pipe(map((categories) => categories.map((category) => this.getTreeDataFromCategory(category))));
  }

  protected getDataMap(): Observable<ITreeData[]> {
    return this.categoryService
      .getCategories({ parentId: 0 })
      .pipe(map((categories) => categories.map((category) => this.getTreeDataFromCategory(category))));
  }

  private getTreeDataFromCategory(category: ICategory): ITreeData {
    return {
      name: category.name,
      id: category.id,
      expandable: category.expandable,
    };
  }
}
