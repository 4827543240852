<div class="__dialog-wrapper">
  <div class="__dialog-head">
    <ng-content select="[dialog-title]"></ng-content>

    <ng-container *ngIf="showCloseButton">
      <button
        type="button"
        mat-button
        mat-dialog-close
      >
        <mat-icon svgIcon="cancel"></mat-icon>
      </button>
    </ng-container>
  </div>

  <div class="__dialog-content">
    <ng-content select="[dialog-content]"></ng-content>
  </div>

  <div class="__dialog-footer">
    <ng-container *ngIf="dialogActions">
      <ng-content select="[dialog-actions]"></ng-content>
    </ng-container>
  </div>
</div>