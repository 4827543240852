import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { MatDaterangePickerComponent } from '@ui/modules/mat-daterange-picker/components/mat-daterange-picker/mat-daterange-picker.component';
import { DateRangePeriodType } from '@ui/components/date-range-select/shared/date-range-period-type.enum';
import { IDateRangeSelectedInterface } from '@ui/modules/mat-daterange-picker/components/mat-daterange-picker/shared/date-range-selected.interface';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';
import * as moment from 'moment';
import { MatDatepickerHeaderComponent } from '@ui/components/mat-form-components/mat-datepicker-header/mat-datepicker-header.component';
import { IDateRangeSelectEventInterface } from '@ui/components/date-range-select/shared/date-range-select-event.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// TODO: get some 3rd party datepicker
@Component({
  selector: 'app-date-range-select',
  templateUrl: './date-range-select.component.html',
  styleUrls: ['./date-range-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    NgOnDestroy,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeSelectComponent),
      multi: true,
    },
  ],
})
export class DateRangeSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild('picker', { static: true }) dateRangePicker!: MatDaterangePickerComponent;
  @ViewChild('singleDatePicker', { static: true }) singleDatePicker!: MatDatepicker<moment.Moment>;

  @Input() merged = false;
  @Input() set defaultPeriod(period: DateRangePeriodType | undefined) {
    if (period) {
      this.selectedDateRangePeriodType = period;
    }
  }

  @Output() selectionChange = new EventEmitter<IDateRangeSelectEventInterface>();

  public rangeOptionLabelDefault = 'Период...';
  public rangeOptionLabel = this.rangeOptionLabelDefault;

  public singleDayOptionLabelDefault = 'Выбрать день...';
  public singleDayOptionLabel = this.singleDayOptionLabelDefault;

  public selectedDateRangePeriodType: DateRangePeriodType;
  public selectedDateRangePeriod: IDateRangeSelectedInterface;

  public DateRangePeriodType = DateRangePeriodType;
  public header = MatDatepickerHeaderComponent;

  private dateFormatString = 'DD.MM.YY';

  constructor(@Self() private componentDestroyed$: NgOnDestroy, private cdRef: ChangeDetectorRef) {
    this.setDefaultState();
  }

  onChange = (fn: any): void => {};

  onTouched = (): void => {};

  ngOnInit() {}

  ngAfterViewInit() {
    // this.updateSelectedRangeByType(this.selectedDateRangePeriodType);
    this.updateRangeOptionLabel();
    this.updateSingleDayOptionLabel();
    this.emitSelection();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: IDateRangeSelectEventInterface | null): void {
    if (value) {
      this.selectedDateRangePeriodType = value.type;

      if (value.period && typeof value.period.from === 'string') {
        value.period.from = moment(value.period.from);
        value.period.to = moment(value.period.to);
      }

      this.selectedDateRangePeriod = value.period;

      if (this.selectedDateRangePeriodType === DateRangePeriodType.SPECIFIED_PERIOD) {
        this.updateRangeOptionLabel();
      }
    } else {
      this.setDefaultState();
    }

    this.cdRef.markForCheck();
  }

  public openSingleDatePicker() {
    this.singleDatePicker.open();
  }

  public openRangeDatePicker() {
    this.dateRangePicker.open();
  }

  public onOpenedChange(isOpened: boolean) {
    if (isOpened) {
      this.rangeOptionLabel = this.rangeOptionLabelDefault;
      this.singleDayOptionLabel = this.singleDayOptionLabelDefault;
    } else {
      if (this.selectedDateRangePeriodType === DateRangePeriodType.SPECIFIED_PERIOD && this.selectedDateRangePeriod) {
        this.updateRangeOptionLabel();
      }

      if (this.selectedDateRangePeriodType === DateRangePeriodType.SPECIFIED_DAY && this.selectedDateRangePeriod) {
        this.updateSingleDayOptionLabel();
      }
    }
  }

  public onDatePeriodSelected(period: IDateRangeSelectedInterface) {
    const writeValue = {
      type: this.selectedDateRangePeriodType,
      period,
    };

    this.writeValue(writeValue);

    this.emitSelection();
  }

  public onSingleDateSelected(event: MatDatepickerInputEvent<moment.Moment>) {
    const date = event.value;

    this.selectedDateRangePeriod = {
      from: date,
      to: date,
    };

    this.updateSingleDayOptionLabel();

    this.emitSelection();
  }

  public onSelectSelectionChange(event: MatSelectChange) {
    this.updateSelectedRangeByType(event.value);
  }

  private updateSelectedRangeByType(type: DateRangePeriodType) {
    switch (type) {
      case DateRangePeriodType.ALL_TIME: {
        this.selectedDateRangePeriod = {
          from: moment().subtract(10, 'years'),
          to: moment().add(10, 'years'),
        };

        this.emitSelection();

        break;
      }

      case DateRangePeriodType.WEEK: {
        this.selectedDateRangePeriod = {
          from: moment().subtract(1, 'weeks'),
          to: moment(),
        };

        this.emitSelection();

        break;
      }

      case DateRangePeriodType.TWO_WEEKS: {
        this.selectedDateRangePeriod = {
          from: moment().subtract(2, 'weeks'),
          to: moment(),
        };

        this.emitSelection();

        break;
      }

      case DateRangePeriodType.MONTH: {
        this.selectedDateRangePeriod = {
          from: moment().subtract(1, 'month'),
          to: moment(),
        };

        this.emitSelection();

        break;
      }

      case DateRangePeriodType.SPECIFIED_PERIOD: {
        this.selectedDateRangePeriod = {
          from: null,
          to: null,
        };

        break;
      }

      case DateRangePeriodType.SPECIFIED_DAY: {
        this.selectedDateRangePeriod = {
          from: null,
          to: null,
        };

        break;
      }

      case DateRangePeriodType.TODAY: {
        this.selectedDateRangePeriod = {
          from: moment(),
          to: moment(),
        };

        this.emitSelection();

        break;
      }

      case DateRangePeriodType.YESTERDAY: {
        this.selectedDateRangePeriod = {
          from: moment().subtract(1, 'day'),
          to: moment().subtract(1, 'day'),
        };

        this.emitSelection();

        break;
      }
    }
  }

  private updateRangeOptionLabel() {
    this.rangeOptionLabel = this.getSelectedRangePeriodLabel();
  }

  private updateSingleDayOptionLabel() {
    this.singleDayOptionLabel = this.getSingleDayOptionLabel();
  }

  private getSingleDayOptionLabel() {
    if (this.selectedDateRangePeriod.from === null) {
      return '';
    }

    return this.selectedDateRangePeriod.from.format(this.dateFormatString);
  }

  private getSelectedRangePeriodLabel(): string {
    if (this.selectedDateRangePeriod.from === null || this.selectedDateRangePeriod.to === null) {
      return '';
    }

    return (
      this.selectedDateRangePeriod.from.format(this.dateFormatString) +
      ' - ' +
      this.selectedDateRangePeriod.to.format(this.dateFormatString)
    );
  }

  private emitSelection() {
    const data: IDateRangeSelectEventInterface = {
      period: this.selectedDateRangePeriod,
      type: this.selectedDateRangePeriodType,
    };

    this.selectionChange.emit(data);
    this.handleChange();
  }

  private handleChange() {
    this.onChange({
      type: this.selectedDateRangePeriodType,
      period: this.selectedDateRangePeriod,
    });
  }

  private setDefaultState() {
    this.selectedDateRangePeriodType = DateRangePeriodType.ALL_TIME;
    this.selectedDateRangePeriod = {
        from: moment().subtract(10, 'years'),
        to: moment().add(10, 'years'),
      };
    }
}
