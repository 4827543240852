<ng-content *ngTemplateOutlet="notificationStatus === 'not_read' ? notReadNotification : readNotification">
</ng-content>

<ng-template #readNotification>
  <div class="item read" [class.expanded]="isExpanded">
    <ng-content *ngTemplateOutlet="notificationBody"></ng-content>
    <ng-content *ngTemplateOutlet="notificationExpand"></ng-content>
  </div>
</ng-template>

<ng-template #notReadNotification>
  <div class="item not-read" [class.expanded]="isExpanded">
    <ng-content *ngTemplateOutlet="bellIcon"></ng-content>
    <ng-content *ngTemplateOutlet="notificationBody"></ng-content>
    <ng-content *ngTemplateOutlet="notificationExpand"></ng-content>
  </div>
</ng-template>

<ng-template #bellIcon>
  <div class="icon">
    <mat-icon svgIcon="bell-blue" class="bell-icon --primary-blue"></mat-icon>
  </div>
</ng-template>

<ng-template #notificationBody>
  <div class="body" #body>
    <div class="body-content" #bodyContent>
      <span class="notification-date">{{ notification.creationDateTime | date: 'dd.MM.yyyy HH:mm' }}</span>
      <span class="notification-subject">{{ notification.subject }}</span>
      <span class="notification-body" [innerHTML]="notification.body | safeHtml"></span>
    </div>
  </div>
</ng-template>

<ng-template #notificationExpand>
  <div class="expand" *ngIf="isNotificationHasOverflow">
    <mat-icon svgIcon="arrow-down-blue" class="expand-icon __primary-blue"></mat-icon>
  </div>
</ng-template>
