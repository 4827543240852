import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IFooterLink } from '@ui/components/footer/shared/footer-link.interface';
import { ConfigurationDataService } from '@app/pages/system-management/administration/components/configuration/shared/services/configuration-data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public links: IFooterLink[] = [];

  constructor(
    private httpClient: HttpClient,
    private cdRef: ChangeDetectorRef,
    private configurationDataService: ConfigurationDataService
  ) {
    this.loadLinks();
  }


  public getLinkByCode(code: string): string {
    const obj = this.links.find(link => link.key === code);

    return '/';
  }

  private loadLinks() {
    // this.configurationDataService.getFooterLinks()
    //   .subscribe(links => {
    //     this.links = links;
    //     this.cdRef.markForCheck();
    //   });
  }
}
