<div class="application">
  <div class="application__header">
    Создать заявку
    <mat-icon (click)="closeModalWindow()" class="application__close" svgIcon="x-mark"></mat-icon>
  </div>

  <div class="application__main-info">
    <div class="application__main-info-left">
      <div class="application__inline">
        <div class="application__label">Статус</div>
        <div class="application__value">Черновик</div>
      </div>
      <div class="application__inline">
        <div class="application__label">Дата создания</div>
        <div class="application__value">12.01.2020</div>
      </div>
      <div class="application__inline">
        <div class="application__label">Дата подтверждения</div>
        <div class="application__value"></div>
      </div>
      <div class="application__inline">
        <div class="application__label">Количество товаров, шт</div>
        <div class="application__value">50</div>
      </div>
      <div class="application__inline">
        <div class="application__label">Сумма предоплаты, ₽</div>
        <div class="application__value">4000</div>
      </div>
      <div class="application__inline">
        <div class="application__label">Размер предоплаты, %</div>
        <div class="application__value">20</div>
      </div>
      <div class="application__inline">
        <div class="application__label">Сумма без НДС, ₽</div>
        <div class="application__value">122 500</div>
      </div>
      <div class="application__inline">
        <div class="application__label">Сумма с НДС, ₽</div>
        <div class="application__value">144 460</div>
      </div>
    </div>

    <div class="application__main-info-right">
      <div class="application__customer">
        <div class="application__inline">
          <div class="application__label">Заказчик</div>
          <div class="application__value"></div>
        </div>
        <div class="application__inline">
          <div class="application__label">ИНН</div>
          <div class="application__value">7712345506</div>
        </div>
        <div class="application__inline">
          <div class="application__label">Сокр. наименование</div>
          <div class="application__value">Торговая сеть «Сказка»</div>
        </div>
        <div class="application__inline select-widget">
          <div class="application__label select-widget__label">Подразделение</div>
          <div class="application__value">
            <select class="select select-wrap">
              <option selected value="1">ТТ «Лукоморье»</option>
              <option value="2">ТТ «Лукоморье» 2</option>
              <option value="3">ТТ «Лукоморье» 3</option>
            </select>
          </div>
        </div>
      </div>

      <div class="application__supplier">
        <div class="application__inline">
          <div class="application__label">Поставщик</div>
          <div class="application__value"></div>
        </div>
        <div class="application__inline">
          <div class="application__label">ИНН</div>
          <div class="application__value">7712345506</div>
        </div>
        <div class="application__inline">
          <div class="application__label">Сокр. наименование</div>
          <div class="application__value">М-ОПТ</div>
        </div>
        <div class="application__inline">
          <div class="application__label">Подразделение</div>
          <div class="application__value">М-ОПТ Склад 1</div>
        </div>
      </div>
    </div>
  </div>

  <div class="application__search-control">
    <div class="application__distributor">
      <div class="application__distributor-label">Дистрибьютор</div>
      <div class="application__distributor-control">
        <input class="application__distributor-input" placeholder="Дистрибьютор номер один" type="text" />
      </div>
    </div>
    <div class="application__catalog">
      <div class="application__search">
        <div class="application__search-wrap-input">
          <input class="application__search-input" placeholder="Найти по названию" type="text" />
          <mat-icon class="application__search-icon" svgIcon="search-mini"></mat-icon>
        </div>
      </div>
      <button class="application__catalog-button">Перейти в каталог</button>
    </div>
  </div>

  <div class="application__table">
    <table [dataSource]="dataSource" class="table" mat-table matSort>
      <ng-container matColumnDef="position">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>#</th>
        <td *matCellDef="let element" class="table__positions" mat-cell>{{ element.position }}</td>
      </ng-container>

      <ng-container matColumnDef="vendorCode">
        <th *matHeaderCellDef mat-header-cell>Артикул</th>
        <td *matCellDef="let element" mat-cell>{{ element.vendorCode }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>Наименование товара</th>
        <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th *matHeaderCellDef mat-header-cell>Цена, ₽</th>
        <td *matCellDef="let element" mat-cell>{{ element.price }}</td>
      </ng-container>

      <ng-container matColumnDef="count">
        <th *matHeaderCellDef mat-header-cell>Кол-во</th>
        <td *matCellDef="let element" class="table__count" mat-cell>
          <div class="table__count-control table__count-remove">-</div>
          <div class="table__count-value">{{ element.count }}</div>
          <div class="table__count-control table__count-insert">+</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th *matHeaderCellDef class="table__total" mat-header-cell>Итого с НДС, ₽</th>
        <td *matCellDef="let element" mat-cell>{{ element.total }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="table__action" mat-header-cell>
          <mat-icon class="application__table-icon" svgIcon="settings-mini"></mat-icon>
        </th>
        <td *matCellDef="let element" mat-cell></td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
    </table>
  </div>

  <div class="application__pagination">
    <app-items-count-on-page [countFormControl]="goodsOnPageControl"></app-items-count-on-page>
    <app-pagination
      (pageChange)="onPageChange($event)"
      [length]="maxGoodsCount"
      [pageIndex]="pageOffset"
      [pageSize]="goodsOnPageLimit"
    ></app-pagination>
  </div>

  <div class="application__actions">
    <app-button class="application__actions-save" name="Сохранить" type="stroked"></app-button>
    <app-button class="application__actions-send" name="Отправить заявку" type="flat"></app-button>
  </div>
</div>
