<div class="mat-menu-panel mat-elevation-z4">
  <div class="mat-menu-content">
    <button class="mat-menu-item">
      <mat-icon svgIcon="payment" class="default-table__edit-icon"></mat-icon>
      Оплатить
    </button>
    <button class="mat-menu-item">
      <mat-icon svgIcon="download" class="default-table__edit-icon"></mat-icon>
      Скачать CSV
    </button>
    <button class="mat-menu-item">
      <mat-icon svgIcon="basket" class="default-table__edit-icon"></mat-icon>
      Отказать
    </button>
    <button class="mat-menu-item">
      <mat-icon svgIcon="download" class="default-table__edit-icon"></mat-icon>
      Скачать PDF
    </button>
    <button class="mat-menu-item">
      <mat-icon svgIcon="download" class="default-table__edit-icon"></mat-icon>
      Перейти к заявке-оснаванию
    </button>
  </div>
</div>
