<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">
  <mat-tree-node *matTreeNodeDef="let node" class="tree__node __empty" matTreeNodePadding>
    <div (click)="dispatchNodeClickEvent(node.id)" class="tree__node-inner">
      <span class="tree__node-name">{{ node.item }}</span>
    </div>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" class="tree__node" matTreeNodePadding>
    <button class="tree__node-button" mat-icon-button matTreeNodeToggle>
      <span [class.__expanded]="treeControl.isExpanded(node)" class="tree__expand-icon"></span>
    </button>
    <div (click)="dispatchNodeClickEvent(node.id)" class="tree__node-inner">
      <span class="tree__node-name">{{ node.item }}</span>
      <mat-progress-spinner *ngIf="node.isLoading | async" class="example-margin" diameter="24" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </mat-tree-node>
</mat-tree>
