import { FlatTreeControl } from '@angular/cdk/tree';
import { EventEmitter, Input, OnInit, Output, Self, Directive } from '@angular/core';
import { NgOnDestroy } from '@core/shared/services/destroy.service';
import { ITreeData } from '@ui/components/abstract-tree/shared/tree.interface';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseDynamicDatabase } from '@app/pages/system-management/trading-odds/trading-odds-categories/shared/base-dynamic-db';
import { DynamicFlatNode } from '@app/pages/system-management/trading-odds/trading-odds-categories/shared/dynamic-flat-node.model';
import { DynamicDataSource } from '@app/pages/system-management/trading-odds/trading-odds-categories/shared/dynamic-ds';

@Directive()
export class AbstractTree<T extends ITreeData> implements OnInit {
  @Input() collapseAll$ = new BehaviorSubject(false);
  @Input() updateTree$ = new BehaviorSubject(false);
  @Output() nodeClick = new EventEmitter<number>();
  treeControl: FlatTreeControl<DynamicFlatNode>;
  dataSource: DynamicDataSource<T>;

  constructor(protected database: BaseDynamicDatabase<T>, @Self() protected componentDestroyed$: NgOnDestroy) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource<T>(this.treeControl, database);
  }

  ngOnInit() {
    this.collapseAll$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => this.treeControl.collapseAll());

    this.updateTree$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => this.updateTree());
  }

  updateTree() {
    this.database
      .initialData()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res) => (this.dataSource.data = res));
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, nodeData: DynamicFlatNode) => nodeData.expandable;

  dispatchNodeClickEvent(id: number) {
    this.nodeClick.emit(id);
  }
}
