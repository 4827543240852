import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { IProductCard } from '@core/shared/interfaces/product-card.interface';
import { IProductLabelComponentItem } from '@ui/components/product-labels/shared/product-label.interface';
import { IOrderItem } from '@core/shared/interfaces/order.interface';

@Component({
  selector: 'app-product-labels',
  templateUrl: './product-labels.component.html',
  styleUrls: ['./product-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductLabelsComponent implements OnInit {

  @Input() product: IProductCard | IOrderItem;

  public discountLabel: IProductLabelComponentItem;
  public promoLabel: IProductLabelComponentItem;
  public discountLabelStyle: 'small' | 'big' = 'small';

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.product.discount) {
      this.discountLabel = {
        text: `-${this.product.discount.toString()}%`,
        backgroundColor: this.product.labelColor,
        textColor: this.getTextColor()
      };
    }

    if ('labels' in this.product) {
      let labels = this.product.labels;

      if (labels && labels.length) {
        labels = labels.sort((a, b) => {
          const aPriority = a.priority;
          const bPriority = b.priority;

          if (aPriority > bPriority) {
            return -1;
          }

          if (aPriority === bPriority) {
            return 0;
          }

          if (aPriority < bPriority) {
            return 1;
          }
        });

        const firstLabelByPriority = labels[0];

        this.promoLabel = {
          textColor: firstLabelByPriority.textColor,
          backgroundColor: firstLabelByPriority.labelColor,
          text: firstLabelByPriority.name
        };
      } else {
        this.discountLabelStyle = 'big';
      }
    }
    this.cdRef.markForCheck();
  }

  private getTextColor() {
    if (!('textColor' in this.product)) {
      return '#fff';
    } else {
      return this.product.textColor;
    }
  }

}
