import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, range } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PaginationComponent,
      multi: true,
    },
  ]
})
export class PaginationComponent implements OnChanges, OnInit, ControlValueAccessor {
  @Input() range = 2;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  readonly rangeMultiplier = 1;
  readonly rangeFactor = 2;
  pages$!: Observable<number[]>;
  totalPages!: number;
  @Input() reset = new Observable<void>();
  private onChange = (page: number) => {};

  constructor(private cdRef: ChangeDetectorRef) {
    this.getPages();
  }
  writeValue(page: number): void {
    this._pageIndex = page;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}

  private _pageSize = 0;

  @Input()
  get pageSize(): number {
    return this._pageSize;
  }

  set pageSize(value: number) {
    this._pageSize = Math.max(coerceNumberProperty(value), 1);
    this.onPageSizeChange();
    this.getPages();
  }

  private _pageIndex = 1;

  @Input()
  get pageIndex(): number {
    return this._pageIndex;
  }

  set pageIndex(value: number) {
    this._pageIndex = Math.max(coerceNumberProperty(value), 1);
    this.getPages();
    this.cdRef.markForCheck();
  }

  private _length = 0;

  @Input()
  get length(): number {
    return this._length;
  }

  set length(value: number) {
    this.getPages();
    this._length = coerceNumberProperty(value);
    this.cdRef.markForCheck();
  }

  ngOnInit() {
    this.reset.subscribe(() => {
      this.pageChange.emit(1);
      this.pageIndex = 1;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.totalPages = this.getTotalPages(this.pageSize, this.length);
  }

  getPages(): void {
    this.pages$ = range(-this.range, this.range * this.rangeFactor + this.rangeMultiplier).pipe(
      map((pageOffset) => this.pageIndex + pageOffset),
      filter((page) => this.isValidPageNumber(page, this.totalPages)),
      toArray(),
    );
  }

  isValidPageNumber(page: number, totalPages: number): boolean {
    return page > 0 && page <= totalPages;
  }

  getTotalPages(pageSize: number, length: number): number {
    return Math.ceil(Math.max(length, 1) / Math.max(pageSize, 1));
  }

  selectPage(page: number, event: Event): void {
    this.cancelEvent(event);
    if (this.isValidPageNumber(page, this.totalPages)) {
      this.onChange(page);
      this.pageChange.emit(page);
      this.pageIndex = page;
      this.getPages();
    }
  }

  cancelEvent(event: Event): void {
    event.preventDefault();
  }

  identify(index: number, item: number): number {
    return item;
  }

  private onPageSizeChange() {
    if (this.pageIndex > this.getTotalPages(this.pageSize, this.length)) {
      this.pageIndex = 1;
    }
    this.pageChange.emit(this.pageIndex);
  }
}
