import { Injectable } from '@angular/core';
import { WebApiService } from '@core/web-api/shared/services/web-api.service';
import { UserNotificationsListQueryDto } from '@core/shared/dto/user-notifications-list-query.dto';
import { INotificationsListItem } from '@ui/modules/notifications-list-popup/notifications-list-content/notifications-list-item/shared/notifications-list-list-item.interface';
import { Observable } from 'rxjs';
import { TableResponseDto } from '@core/shared/dto/table-response.dto';
import { NotificationStatusUpdateDto } from '@core/shared/dto/notification-status-update.dto';
import { NotificationsCountQueryDto } from '@core/shared/dto/notifications-count-query.dto';
import { UserNotificationsUnreadCountResponseDto } from '@core/shared/dto/user-notifications-unread-count-response.dto';

@Injectable({
  providedIn: 'root',
})
export class NotificationsListService {
  private root = 'Notifications';

  constructor(private api: WebApiService) {}

  public getNotifications(dto: UserNotificationsListQueryDto): Observable<TableResponseDto<INotificationsListItem>> {
    return this.api.get(this.root, dto);
  }

  public getUserNotifications(
    userId: number,
    dto: UserNotificationsListQueryDto,
  ): Observable<TableResponseDto<INotificationsListItem>> {
    return this.api.get(`${this.root}/User/${userId}`, dto);
  }

  public updateNotificationStatus(notificationId: number, dto: NotificationStatusUpdateDto) {
    return this.api.patch(`${this.root}/${notificationId}`, dto);
  }

  public getUserNotificationsCount(
    userId: number,
    dto: NotificationsCountQueryDto,
  ): Observable<UserNotificationsUnreadCountResponseDto> {
    return this.api.get(`${this.root}/User/${userId}/Count`, dto);
  }

  public updateUserNotificationStatus(userId: number, notificationId: number, dto: NotificationStatusUpdateDto) {
    return this.api.patch(`${this.root}/${notificationId}/User/${userId}`, dto);
  }

  public readAllNotifications(userId: number) {
    return this.api.post(`${this.root}/User/${userId}/ReadAll`);
  }
}
